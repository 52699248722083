import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Grid, Box, Typography, Container, List } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Contact from "../components/Contact";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import usePathParameter from "../utils/usePathParameter";
import BackToStart from "../components/BackToStart";
import { inspireThane } from "../data/ThaneIndividualSiteData";
import { inspireMain, pageSettingsMain } from "../data/MainSiteData";
import { inspireCbgPalava } from "../data/CbgPalavaIndividualSiteData";
import { inspireLsgPalava } from "../data/LsgPalavaIndividualSiteData";
import { inspireTaloja } from "../data/TalojaIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "150vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  root: {
    alignItems: "center",
  },
  textBox: {
    position: "relative",
    left: "-12rem",
    padding: "2rem 1rem",
    display: "flex",
    alignItems: "center",
    height: "230px",
    boxShadow: "0px 4px 31px rgba(37, 54, 87, 0.5)",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("md")]: {
      position: "initial",
      height: "auto",
    },
  },
  nextBtn: {
    position: "relative",
    bottom: "3rem",
    left: "5rem",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    borderRadius: "50%",
    width: "90px",
    height: "90px",
    fontSize: "13px",
    border: "1px solid #263657",
    backgroundColor: "#263657",
    color: "#fff",
    "&:hover": {
      background: "#263657",
      border: "1px solid #263657",
      color: "#fff",
    },
  },
  containerMain: {
    height: "100vh",
    marginLeft: "7rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      marginLeft: 0,
    },
  },
  inspireSection: {
    width: "50vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      padding: "1rem",
    },
  },
  inspireSection1: {
    width: "50vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      padding: "3rem 1rem 1rem 1rem",
    },
  },
  areasSection: {
    width: "35vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      padding: "1rem",
    },
  },
  subText: {
    width: "77vh",
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      fontSize: "initial",
    },
  },
  subTextMain: {
    display: "flex",
    alignItems: "center",
    padding: "4rem 0",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  inspirePointers: {
    width: "100vw",
    display: "flex",
    alignItems: "center",
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      display: "block",
      height: "auto",
      paddingTop: "2rem",
    },
  },
  h1Text: {
    fontFamily: "Lato",
    fontWeight: "bold",
    color: "#898EAC",
    fontSize: "55px",
    marginBottom: "10px",
    marginTop: "0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  },
}));

export default function Cbsetennistournament() {
  const classes = useStyles();
  const leftMenuRef = useRef(null);
  const rightSectionRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const [inspire, setInspiren] = useState([]);

  useEffect(() => {
    if (isThane) {
      setInspiren(inspireThane);
    } else if (isCbgPalava) {
      setInspiren(inspireCbgPalava);
    } else if (isLsgPalava) {
      setInspiren(inspireLsgPalava);
    } else if (isTaloja) {
      setInspiren(inspireTaloja);
    } else if (!isIndividual) {
      setInspiren(inspireMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  const scrollToBackTarget = () => {
    leftMenuRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const position = rightSectionRef.current?.getBoundingClientRect().x;
    setScrollPosition(position);
  };

  useEffect(() => {
    const interval = setInterval(() => setCurrentSlide((prv) => prv + 1), 4000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (inspire.length === 0) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{pageSettingsMain.cbsetennistournament.title}</title>
        {pageSettingsMain.cbsetennistournament.metaTags.map((tag) => {
          if (tag.name) {
            return (
              <meta name={tag.name} content={tag.content} key={tag.name} />
            );
          } else if (tag.property) {
            return (
              <meta
                property={tag.property}
                content={tag.content}
                key={tag.property}
              />
            );
          }
          return null;
        })}
        <link
          rel="canonical"
          href={pageSettingsMain.cbsetennistournament.canonical}
        />
      </Helmet>
      <Contact />
      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu
              breadCrumbTarget={
                isIndividual
                  ? "Foundation and Middle Years"
                  : "Cbse Tennis Tournament"
              }
            />
            <Grid
              ref={rightSectionRef}
              item
              xs={12}
              md={9}
              className={classes.rightSection}
            >
              <Grid container className={classes.containerMain}>
                <Grid item xs={12} className={classes.inspireSection}>
                  <Typography
                    class="h2b"
                    variant="h1"
                    style={{ lineHeight: "normal" }}
                  >
                    {isIndividual
                      ? "Foundation and Middle Years"
                      : "Lodha World School Custom Curriculum - Inspire Us"}
                  </Typography>
                  <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subText}>
                      At LWS, we follow a globally researched custom curriculum
                      formulated by the Lodha Institute of Teacher Education
                      (LITE). ‘Inspire Us’ - for Foundation and Middle Years
                      (Preschool to Class 8) - is specially designed to deliver
                      the academic rigour and excellence we aspire to achieve.
                      The books adopted are standardised across schools, setting
                      a benchmark and ensuring content proficiency and quality
                      control.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} className={classes.areasSection}>
                  <Typography class="h3b">Areas of Development</Typography>
                  <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subText}>
                      <List component="div">
                        <Box> 1. &nbsp; Personal, Social and Emotional</Box>
                        <Box> 2. &nbsp; Physical and Motor</Box>
                        <Box> 3. &nbsp; Cognitive</Box>
                        <Box> 4. &nbsp; Language and Literacy</Box>
                        <Box> 5. &nbsp; Art and Creative</Box>
                        <Box> 6. &nbsp; Technology</Box>
                        <Box> 7. &nbsp; Life Skills and Values</Box>
                      </List>
                    </Typography>
                  </Box>
                </Grid>

                {/* inspire us pointers */}
                <>
                  <Box className={classes.inspirePointers}>
                    <Container maxWidth="lg">
                      <Typography
                        className={classes.h1Text}
                        // variant="h1"
                        style={{ marginBottom: "10px" }}
                      >
                        With Inspire Us, learning is…
                      </Typography>
                      <Grid container className={classes.root}>
                        <Grid item xs={12} md={7}>
                          <div class="slider-wrap">
                            <div
                              className="single-slide"
                              style={{ transitionDuration: "800ms" }}
                              id={inspire[currentSlide % inspire.length].id}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={5} className={classes.textBox}>
                          <Box>
                            <Box style={{ textAlign: "center" }}>
                              <Typography
                                class="h2"
                                // variant="h1"
                                style={{
                                  fontFamily: "Bodoni",
                                  fontSize: "2em",
                                  fontWeight: "bold",
                                  transitionDuration: "800ms",
                                  color: "#263657",
                                  lineHeight: "27px",
                                }}
                              >
                                {inspire[currentSlide % inspire.length].title}
                              </Typography>
                              <Typography
                                class="h3"
                                variant="h3"
                                style={{
                                  padding: "10px",
                                  fontFamily: "Lato",
                                  fontWeight: "bold",
                                  transitionDuration: "800ms",
                                  color: "#263657",
                                  lineHeight: "27px",
                                  fontSize: "1.17em",
                                }}
                              >
                                {
                                  inspire[currentSlide % inspire.length]
                                    .description
                                }
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Container>
                  </Box>
                </>

                <Grid item xs={12} className={classes.inspireSection1}>
                  <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subText}>
                      When it comes to imparting knowledge, we go beyond
                      curricular and co-curricular learning. The Inspire Us
                      curriculum is enriched with modules and courses to help
                      our learners transform into leaders. Here are some of our
                      Life Skills offerings:
                      <List component="div">
                        <Box> &#8226; &nbsp; Financial Literacy</Box>
                        <Box> &#8226; &nbsp; Digital Literacy</Box>
                        <Box> &#8226; &nbsp; Home Reading Program</Box>
                        <Box> &#8226; &nbsp; Entrepreneurship</Box>
                        <Box> &#8226; &nbsp; Global Citizenship</Box>
                        <Box> &#8226; &nbsp; Resource Conservation</Box>
                        <Box>
                          {" "}
                          &#8226; &nbsp; Circle Time activities (Emotional
                          Wellness)
                        </Box>
                        <Box>
                          {" "}
                          &#8226; &nbsp; Working Model (Design Thinking)
                        </Box>
                      </List>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {scrollPosition < 0 ? (
        <BackToStart goToTarget={scrollToBackTarget} />
      ) : null}
    </>
  );
}
