import React, { useState, useRef, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Contact from "../components/Contact";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import BackToStart from "../components/BackToStart";
import usePathParameter from "../utils/usePathParameter";
import { infraThane, infraDataThane } from "../data/ThaneIndividualSiteData";
import { infraMain, infraDataMain } from "../data/MainSiteData";
import {
  infraCbgPalava,
  infraDataCbgPalava,
} from "../data/CbgPalavaIndividualSiteData";
import {
  infraLsgPalava,
  infraDataLsgPalava,
} from "../data/LsgPalavaIndividualSiteData";
import { infraTaloja, infraDataTaloja } from "../data/TalojaIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "150vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  root: {
    alignItems: "center",
    // "@media (min-width:600px)": {
    //   maxWidth: '750px'
    // }
  },
  textBox: {
    position: "relative",
    left: "-12rem",
    padding: "2rem 1rem",
    boxShadow: "0px 4px 31px rgba(37, 54, 87, 0.5)",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("md")]: {
      position: "initial",
    },
  },
  nextBtn: {
    position: "relative",
    left: "30rem",
    top: "-3rem",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    borderRadius: "50%",
    width: "90px",
    height: "90px",
    fontSize: "13px",
    border: "1px solid #263657",
    backgroundColor: "#263657",
    color: "#fff",
    "&:hover": {
      background: "#263657",
      border: "1px solid #263657",
      color: "#fff",
    },
  },
  containerMain: {
    height: "92vh",
    marginLeft: "7rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      flexDirection: "row",
      marginLeft: 0,
    },
  },
  subText: {
    width: "77vh",
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      width: "100vh",
      fontSize: "initial",
      padding: "1rem",
    },
  },
  subTextMain: {
    display: "flex",
    alignItems: "center",
    padding: "4rem 0",
    [theme.breakpoints.down("md")]: {
      padding: "1rem 0 0 0",
    },
  },
  h1Main: {
    marginLeft: "3rem",
    width: "70vw",
    display: "flex",
    alignItems: "flex-start",
    height: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      width: "100vw",
      height: "auto",
      padding: "1rem",
    },
  },
}));

export default function Infrastructure() {
  const classes = useStyles();
  const leftMenuRef = useRef(null);
  const rightSectionRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const [infra, setInfra] = useState([]);
  const [infraData, setInfraData] = useState({});

  useEffect(() => {
    if (isThane) {
      setInfra(infraThane);
      setInfraData(infraDataThane);
    } else if (isCbgPalava) {
      setInfra(infraCbgPalava);
      setInfraData(infraDataCbgPalava);
    } else if (isLsgPalava) {
      setInfra(infraLsgPalava);
      setInfraData(infraDataLsgPalava);
    } else if (isTaloja) {
      setInfra(infraTaloja);
      setInfraData(infraDataTaloja);
    } else if (!isIndividual) {
      setInfra(infraMain);
      setInfraData(infraDataMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  const scrollToBackTarget = () => {
    leftMenuRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const position = rightSectionRef.current?.getBoundingClientRect().x;
    setScrollPosition(position);
  };

  useEffect(() => {
    const interval = setInterval(() => setCurrentSlide((prv) => prv + 1), 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (infra.length === 0) {
    return null;
  }

  return (
    <>
      <Contact />
      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu breadCrumbTarget="Infrastructure" />
            <Grid
              ref={rightSectionRef}
              item
              xs={12}
              md={9}
              className={classes.rightSection}
            >
              <Grid container className={classes.containerMain}>
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      width: "50vw",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box className={classes.subTextMain}>
                      <Typography component="p" className={classes.subText}>
                        {infraData.para1}
                      </Typography>
                    </Box>
                  </Grid>
                  <Box className={classes.h1Main}>
                    <Typography
                      class="h1"
                      variant="h1"
                      style={{
                        marginBottom: "10px",
                        fontFamily: "Lato",
                        transitionDuration: "800ms",
                        fontWeight: "bold",
                        color: "#898EAC",
                        fontSize: "55px",
                      }}
                    >
                      {infra[currentSlide % infra.length].title}
                    </Typography>
                    <Grid container className={classes.root}>
                      <Grid item xs={12}>
                        <div class="slider-wrap-skill">
                          <div
                            className="single-slide"
                            style={{ transitionDuration: "800ms" }}
                            id={infra[currentSlide % infra.length].id}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {scrollPosition < 0 ? (
        <BackToStart goToTarget={scrollToBackTarget} />
      ) : null}
    </>
  );
}
