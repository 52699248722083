import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Grid, Box, Typography, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Contact from "../components/Contact";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import BackToStart from "../components/BackToStart";
import usePathParameter from "../utils/usePathParameter";
import { missionThane } from "../data/ThaneIndividualSiteData";
import { missionMain, pageSettingsMain } from "../data/MainSiteData";
import { missionCbgPalava } from "../data/CbgPalavaIndividualSiteData";
import { missionLsgPalava } from "../data/LsgPalavaIndividualSiteData";
import { missionTaloja } from "../data/TalojaIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "165vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  root: {
    alignItems: "center",
    // "@media (min-width:600px)": {
    //   maxWidth: '750px'
    // }
  },
  textBox: {
    position: "relative",
    left: "-12rem",
    padding: "2rem 1rem",
    top: "-3rem",
    display: "flex",
    alignItems: "center",
    height: "250px",
    boxShadow: "0px 4px 31px rgba(37, 54, 87, 0.5)",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("md")]: {
      position: "initial",
    },
  },
  nextBtn: {
    position: "relative",
    bottom: "3rem",
    left: "5rem",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    borderRadius: "50%",
    width: "90px",
    height: "90px",
    fontSize: "13px",
    border: "1px solid #263657",
    backgroundColor: "#263657",
    color: "#fff",
    "&:hover": {
      background: "#263657",
      border: "1px solid #263657",
      color: "#fff",
    },
  },
  visionQuote: {
    width: "32vw",
    margin: "0 10rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: 0,
    },
  },
  visionMain: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "5% 0",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
    },
  },
  mainContainer: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginBottom: "3rem",
    },
  },
}));

export default function Vision() {
  const classes = useStyles();
  const leftMenuRef = useRef(null);
  const rightSectionRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const [mission, setMission] = useState([]);

  useEffect(() => {
    if (isThane) {
      setMission(missionThane);
    } else if (isCbgPalava) {
      setMission(missionCbgPalava);
    } else if (isLsgPalava) {
      setMission(missionLsgPalava);
    } else if (isTaloja) {
      setMission(missionTaloja);
    } else if (!isIndividual) {
      setMission(missionMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  // const slides = [
  //   {
  //     id: "vslide-1",
  //     text: "To provide quality school education, progressive in challenging pupils to take advantage of the emerging global opportunities.",
  //   },
  //   {
  //     id: "vslide-2",
  //     text: "To nurture pupils to make them caring, competent and contributing members of society.",
  //   },
  //   {
  //     id: "vslide-3",
  //     text: "To establish pupil-centred environments for academics as well as extracurricular opportunities that nourish self-efficacy and self-esteem.",
  //   },
  //   {
  //     id: "vslide-4",
  //     text: "To stimulate critical thinking and commitment to excellence that gradually lead to success in higher education and career.",
  //   },
  //   {
  //     id: "vslide-5",
  //     text: "To create a school community that reflects and respects ethnic, racial, religious and national diversity.",
  //   },
  // ];

  const scrollToBackTarget = () => {
    leftMenuRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const position = rightSectionRef.current?.getBoundingClientRect().x;
    setScrollPosition(position);
  };

  useEffect(() => {
    const interval = setInterval(() => setCurrentSlide((prv) => prv + 1), 3500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (mission.length === 0) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{pageSettingsMain.vision.title}</title>
        {pageSettingsMain.vision.metaTags.map((tag) => {
          if (tag.name) {
            return (
              <meta name={tag.name} content={tag.content} key={tag.name} />
            );
          } else if (tag.property) {
            return (
              <meta
                property={tag.property}
                content={tag.content}
                key={tag.property}
              />
            );
          }
          return null;
        })}
        <link rel="canonical" href={pageSettingsMain.vision.canonical} />
      </Helmet>
      <Contact />
      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu breadCrumbTarget="Vision" />
            <Grid
              ref={rightSectionRef}
              item
              xs={12}
              md={9}
              style={{ width: "139vw" }}
            >
              <Grid container className={classes.visionMain}>
                <Grid item xs={12} className={classes.visionQuote}>
                  <Typography
                    class="h2b"
                    variant="h1"
                    style={{ textAlign: "center", lineHeight: "normal" }}
                  >
                    Lodha World School Vision
                  </Typography>

                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "4rem 0",
                      flexDirection: "column",
                    }}
                  >
                    <img src="/assets/quote1.svg" alt="" />
                    <Typography
                      style={{
                        padding: "2rem 2rem 0.5rem",
                        textAlign: "center",
                        fontSize: "28px",
                        lineHeight: "normal",
                      }}
                      class="h2"
                      variant="h2"
                    >
                      To nurture and inspire the Leaders of Tomorrow to pursue
                      the highest level of academic excellence.
                    </Typography>
                    <img src="/assets/quote2.svg" alt="" />
                  </Box>
                </Grid>

                <>
                  <Box style={{ width: "100vw" }}>
                    <Container maxWidth="lg" className={classes.mainContainer}>
                      <Typography class="h2b" variant="h1">
                        Lodha World School Mission
                      </Typography>
                      <Grid container className={classes.root}>
                        <Grid item xs={12} md={7}>
                          <div class="slider-wrap">
                            <div
                              className="single-slide"
                              style={{ transitionDuration: "800ms" }}
                              id={mission[currentSlide % mission.length].id}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={5} className={classes.textBox}>
                          <Box>
                            <Box style={{ textAlign: "center" }}>
                              <Typography
                                style={{
                                  padding: "1rem",
                                  fontFamily: "Bodoni",
                                  transitionDuration: "800ms",
                                  fontWeight: "bold",
                                  color: "#263657",
                                  lineHeight: "27px",
                                  fontSize: "1.5em",
                                }}
                                class="h2"
                                align="center"
                                color="primary"
                                gutterBottom
                                variant="h2"
                              >
                                {mission[currentSlide % mission.length].text}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Container>
                  </Box>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {scrollPosition < 0 ? (
        <BackToStart goToTarget={scrollToBackTarget} />
      ) : null}
    </>
  );
}
