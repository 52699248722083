import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Grid, Container, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import usePathParameter from "../../src/utils/usePathParameter";
import { academicsThane } from "../data/ThaneIndividualSiteData";
import { academicsMain } from "../data/MainSiteData";
import { academicsCbgPalava } from "../data/CbgPalavaIndividualSiteData";
import { academicsLsgPalava } from "../data/LsgPalavaIndividualSiteData";
import { academicsTaloja } from "../data/TalojaIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    textAlign: "left",
  },
  textBox: {
    backgroundColor: "#E3D5CA",
    position: "relative",
    right: "11rem",
    padding: "2rem 1rem",
    [theme.breakpoints.down("md")]: {
      position: "initial",
    },
  },
  main: {
    "@media (min-width:600px)": {
      maxWidth: "750px",
    },
  },
  academicsMain: {
    marginLeft: "14rem",
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
  },
  bg: {
    backgroundImage: `url(/assets/lwsMarkersBg.svg)`,
    backgroundPosition: "left center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: "100vh",
    marginBottom: "auto",
    [theme.breakpoints.down("md")]: {
      background: "none",
    },
  },
  boxMain: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  },
  mainTitle: {
    fontFamily: "Lato",
    fontWeight: "bold",
    color: "#898EAC",
    fontSize: "55px",
    marginBottom: "10px",
    marginTop: "0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "36px",
    },
  },
}));

// const styles = {
//   LwsMarkers: {
//       backgroundImage: `url(${LwsMarkersBg})`,
//       backgroundPosition: 'left center',
//       backgroundRepeat: 'no-repeat',
//       backgroundSize: 'contain',
//       height: '100vh',
//       marginBottom: 'auto',
//   },
// };

export default function Academics() {
  const classes = useStyles();
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane, pathUrl } =
    usePathParameter();
  const [academicsContent, setAcademicsContent] = useState([]);

  const navigate = useNavigate();

  const navigateToPage = () => {
    navigate(isIndividual ? `${pathUrl}/results` : "/results");
  };

  useEffect(() => {
    if (isThane) {
      setAcademicsContent(academicsThane);
    } else if (isCbgPalava) {
      setAcademicsContent(academicsCbgPalava);
    } else if (isLsgPalava) {
      setAcademicsContent(academicsLsgPalava);
    } else if (isTaloja) {
      setAcademicsContent(academicsTaloja);
    } else if (!isIndividual) {
      setAcademicsContent(academicsMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  return (
    <>
      <main className={classes.bg}>
        <Box className={classes.boxMain}>
          <Container maxWidth="lg" className={classes.academicsMain}>
            <Typography className={classes.mainTitle} variant="h2">
              {academicsContent?.title}
            </Typography>
            <Grid container className={classes.root} sx={{ mb: 7 }}>
              <Grid item xs={12} md={8}>
                <img
                  src={academicsContent?.imageSource}
                  // src="/assets/academicsImg.png"
                  width="700px"
                  alt={academicsContent?.alt}
                  title={academicsContent?.imgTitle}
                  class="responsiveImg"
                />
              </Grid>
              <Grid item xs={12} md={4} className={classes.textBox}>
                <Box>
                  <Box style={{ textAlign: "center" }}>
                    <Typography
                      style={{ padding: "1rem", marginTop: 0 }}
                      class="h2"
                      align="center"
                      color="primary"
                      gutterBottom
                      variant="h2"
                    >
                      {academicsContent?.name}
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={navigateToPage}
                    >
                      {academicsContent?.cta}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </main>
    </>
  );
}
