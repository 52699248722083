import React, { useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Slide from "@mui/material/Slide";
import usePathParameter from "../utils/usePathParameter";
import { sidebarlinkMain, sidebarMain } from "../data/MainSiteData";
import {
  sidebarCbgPalava,
  sidebarlinkCbgPalava,
} from "../data/CbgPalavaIndividualSiteData";
import {
  sidebarLsgPalava,
  sidebarlinkLsgPalava,
} from "../data/LsgPalavaIndividualSiteData";
import {
  sidebarTaloja,
  sidebarlinkTaloja,
} from "../data/TalojaIndividualSiteData";
import {
  sidebarThane,
  sidebarlinkThane,
} from "../data/ThaneIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  logoMain: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-evenly",
      flexWrap: "wrap",
    },
  },
  contact: {
    position: "fixed",
    zIndex: "999",
    right: "1rem",
    marginTop: "0.6rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "0.2rem",
    },
  },
  subMenu: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    color: "#898EAC",
    fontSize: "14px",
    marginBottom: "35px",
  },
  subTitle: {
    fontSize: "14px",
    color: "#fff",
    marginTop: "8px",
    textDecorationLine: "underline",
  },
  sitemap: {
    fontSize: "14px",
    color: "#fff",
    marginTop: "2rem",
  },
  media: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  contactMain: {
    backgroundColor: "#263657",
    width: "23%",
    right: 0,
    position: "fixed",
    overflowX: "-moz-hidden-unscrollable",
    overflowY: "-moz-hidden-unscrollable",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function Contact() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane, pathUrl } =
    usePathParameter();
  const [sidebar, setSidebar] = useState([]);
  const [logo, setLogos] = useState([]);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  React.useEffect(() => {
    if (isThane) {
      setSidebar(sidebarThane);
      setLogos(sidebarlinkThane);
    } else if (isCbgPalava) {
      setSidebar(sidebarCbgPalava);
      setLogos(sidebarlinkCbgPalava);
    } else if (isLsgPalava) {
      setSidebar(sidebarLsgPalava);
      setLogos(sidebarlinkLsgPalava);
    } else if (isTaloja) {
      setSidebar(sidebarTaloja);
      setLogos(sidebarlinkTaloja);
    } else if (!isIndividual) {
      setSidebar(sidebarMain);
      setLogos(sidebarlinkMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  return (
    <>
      <IconButton
        size="large"
        aria-label="contact"
        color="primary"
        className={classes.contact}
        aria-owns={anchorEl ? "simple-menu" : undefined}
        onClick={handleClick}
        onMouseOver={handleClick}
      >
        <img src="/assets/phone.svg" alt="" />
      </IconButton>

      <Dialog
        fullScreen
        id="simple-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Transition}
        BackdropProps={{ invisible: true }}
        // className={classes.contactMain}
        PaperProps={{
          className: classes.contactMain,
          // style: {
          //   backgroundColor: '#263657',
          //   width: '23%',
          //   right: 0,
          //   position: 'fixed',
          //   overflowX:'-moz-hidden-unscrollable',
          //   overflowY: '-moz-hidden-unscrollable',
          // },
        }}
      >
        <DialogContent
          className={classes.subMenu}
          onMouseLeave={handleClose}
          onBlur={handleClose}
        >
          <Box style={{ textAlign: "center" }}>
            <Box>
              <a
                href={isIndividual ? `${pathUrl}` : "/"}
                aria-label="go to homepage"
              >
                <img
                  src="/assets/logo.svg"
                  alt=""
                  width="150px"
                  class="responsiveImg"
                  className={classes.logo}
                />
              </a>
              <Typography
                style={{ margin: "1rem", textAlign: "center", color: "#fff" }}
              >
                LODHA WORLD SCHOOL
              </Typography>
              <Box className={classes.media}>
                <a
                  href={sidebar.fb}
                  alt="linkedin"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/assets/fb1.svg" alt="" />
                </a>
                <a
                  href={sidebar.insta}
                  alt="linkedin"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/assets/insta1.svg" alt="" />
                </a>
                <a
                  href={sidebar.linkedin}
                  alt="linkedin"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/assets/linkedin1.svg" alt="" />
                </a>
              </Box>
            </Box>

            <Box style={{ margin: "3rem 0px" }}>
              {isIndividual ? (
                <Box>
                  <Typography className={classes.title}>
                    <b>Telephone</b>
                    <a href={sidebar.phone} alt="telephone">
                      <Typography className={classes.subTitle}>
                        {sidebar.phonename}
                      </Typography>
                    </a>
                  </Typography>
                </Box>
              ) : (
                ""
              )}

              <Box>
                <Typography className={classes.title}>
                  <b>Email Id</b>
                  <a href={sidebar.email} alt="email id">
                    <Typography className={classes.subTitle}>
                      {sidebar.emailname}
                    </Typography>
                  </a>
                </Typography>
              </Box>

              <Box>
                <Typography className={classes.title}>
                  <b>Admissions</b>
                  <a href={sidebar.admissionname} alt="admissions">
                    <Typography className={classes.subTitle}>
                      {sidebar.admissionname}
                    </Typography>
                  </a>
                </Typography>
              </Box>
            </Box>

            <Box className={classes.logoMain}>
              {logo.map((e) => (
                <Link href={e.link} alt="" target="_blank">
                  <img
                    src={e.logo}
                    alt=""
                    width={e.width}
                    class="responsiveImg"
                  />
                </Link>
              ))}
            </Box>
            <Box>
            <Link href={"/sitemap"} underline="hover" style={{color: "#fff"}}>
              Sitemap
            </Link>
             
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
