import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
  Button,
  Dialog,
  IconButton,
  DialogContentText,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import usePathParameter from "../utils/usePathParameter";
import { chairpersonMsgThane } from "../data/ThaneIndividualSiteData";
import { chairpersonMsgMain } from "../data/MainSiteData";
import { chairpersonMsgCbgPalava } from "../data/CbgPalavaIndividualSiteData";
import { chairpersonMsgLsgPalava } from "../data/LsgPalavaIndividualSiteData";
import { chairpersonMsgTaloja } from "../data/TalojaIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    textAlign: "center",
  },
  main: {
    "@media (min-width:600px)": {
      maxWidth: "750px",
    },
  },
  textBox: {
    position: "relative",
    left: "-12rem",
    padding: "2rem 1rem",
    boxShadow: "0px 4px 31px rgba(37, 54, 87, 0.5)",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("md")]: {
      position: "initial",
    },
  },
  content: {
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
    "@media (min-width:1024px) and (max-width:1240px)": {
      padding: "40px",
    },
    "@media (min-width:760px) and (max-width:850px)": {
      padding: "40px",
    },
  },
  mainImg: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    background: "#E3D5CA",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  },
  chairpersonMain: {
    marginLeft: "20%",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
  mainContainer: {
    width: "100vw",
    [theme.breakpoints.down("md")]: {
      margin: "4rem",
    },
  },
}));

export default function ChairpersonMsg({ domRef }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const [chairpersonMsg, setChairpersonMsg] = useState([]);

  useEffect(() => {
    if (isThane) {
      setChairpersonMsg(chairpersonMsgThane);
    } else if (isCbgPalava) {
      setChairpersonMsg(chairpersonMsgCbgPalava);
    } else if (isLsgPalava) {
      setChairpersonMsg(chairpersonMsgLsgPalava);
    } else if (isTaloja) {
      setChairpersonMsg(chairpersonMsgTaloja);
    } else if (!isIndividual) {
      setChairpersonMsg(chairpersonMsgMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box ref={domRef} className={classes.mainContainer}>
        <Container maxWidth="lg" className={classes.chairpersonMain}>
          <Grid container className={classes.root}>
            <Grid item xs={12} md={7} className={classes.mainImg}>
              <img
                src={chairpersonMsg?.imageSource}
                width="600px"
                alt={chairpersonMsg?.alt}
                title={chairpersonMsg?.imgTitle}
                class="responsiveImg"
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.textBox}>
              <Box>
                <Box style={{ textAlign: "center" }}>
                  <Typography
                    style={{ padding: "1rem 1rem 0 1rem", marginTop: 0 }}
                    class="h2"
                    align="center"
                    color="primary"
                    gutterBottom
                    variant="h2"
                  >
                    {chairpersonMsg?.title}
                  </Typography>
                  <Typography
                    style={{ fontSize: "18px", marginTop: 0 }}
                    class="h1"
                  >
                    {chairpersonMsg?.nameMain}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickOpen}
                  >
                    {chairpersonMsg?.cta}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "#E3D5CA",
            },
          }}
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          maxWidth="lg"
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            <Typography
              style={{ textAlign: "center" }}
              class="h2b"
              align="center"
              color="primary"
              gutterBottom
              variant="h1"
            >
              <i>{chairpersonMsg?.cta}</i>
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.primary.main,
                border: "1px solid #263657",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ px: 14 }} className={classes.content}>
            <DialogContentText
              sx={{ color: (theme) => theme.palette.black.main }}
            >
              <Grid container gutterBottom>
                <Grid item xs={12}>
                  <img src="/assets/upQuote.svg" alt="" />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ px: 11 }} className={classes.content}>
                    {chairpersonMsg?.dialogText1}
                    <br /> <br />
                    {chairpersonMsg?.dialogText2}
                    <br /> <br />
                    {chairpersonMsg?.dialogText3}
                    <br /> <br />
                    {chairpersonMsg?.dialogText4}
                    <Typography
                      class="h2"
                      variant="h2"
                      style={{ marginBottom: 0 }}
                    >
                      {chairpersonMsg?.name}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "end" }}>
                  <img src="/assets/downQuote.svg" alt="" />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}
