import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Grid, Container, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import usePathParameter from "../../src/utils/usePathParameter";
import { inspireUsThane } from "../data/ThaneIndividualSiteData";
import { inspireUsMain } from "../data/MainSiteData";
import { inspireUsCbgPalava } from "../data/CbgPalavaIndividualSiteData";
import { inspireUsLsgPalava } from "../data/LsgPalavaIndividualSiteData";
import { inspireUsTaloja } from "../data/TalojaIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    textAlign: "center",
    height: "100vh",
  },
  textBox: {
    position: "relative",
    left: "3rem",
    padding: "2rem 1rem",
    boxShadow: "0px 4px 31px rgba(37, 54, 87, 0.5)",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("md")]: {
      position: "initial",
    },
  },
  mainImg: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    background: "#E3D5CA",
    [theme.breakpoints.down("md")]: {
      height: "70vh",
    },
  },
  bg: {
    backgroundImage: `url(/assets/inspireUsBg.svg)`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: "100vh",
    marginBottom: "auto",
    [theme.breakpoints.down("md")]: {
      background: "none",
      margin: "4rem 0 10rem 0",
    },
  },
  container: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  mainTitle: {
    fontFamily: "Lato",
    fontWeight: "bold",
    color: "#898EAC",
    fontSize: "55px",
    marginBottom: "10px",
    marginTop: "0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "36px",
    },
  },
}));

// const styles = {
//   InspireUs: {
//       backgroundImage: `url(${InspireUsBg})`,
//       backgroundPosition: 'center center',
//       backgroundRepeat: 'no-repeat',
//       backgroundSize: 'contain',
//       height: '100vh',
//       marginBottom: 'auto',
//   },
// };

export default function InspireUsCurriculum() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane, pathUrl } =
  usePathParameter();
const [inspireUsContent, setInspireUsContent] = useState([]);

  const navigateToPage = () => {
    navigate(
      isIndividual ? `${pathUrl}/inspireuscurriculum` : "/inspireuscurriculum"
    );
  };

  useEffect(() => {
    if (isThane) {
      setInspireUsContent(inspireUsThane);
    } else if (isCbgPalava) {
      setInspireUsContent(inspireUsCbgPalava);
    } else if (isLsgPalava) {
      setInspireUsContent(inspireUsLsgPalava);
    } else if (isTaloja) {
      setInspireUsContent(inspireUsTaloja);
    } else if (!isIndividual) {
      setInspireUsContent(inspireUsMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  return (
    <>
      <main className={classes.bg}>
        <Box style={{ width: "100vw" }}>
          <Container maxWidth="lg" className={classes.container}>
            <Grid container className={classes.root}>
              <Grid item xs={12} md={7} className={classes.mainImg}>
                <Typography
                  className={classes.mainTitle}
                  variant="h2"
                  style={{ marginBottom: "2rem" }}
                >
                  {inspireUsContent?.title1} <br />
                  {inspireUsContent?.title2}
                </Typography>
                <img
                  src="/assets/inspireUsImg.png"
                  width="550px"
                  alt={inspireUsContent?.alt}
                  class="responsiveImg"
                  title={inspireUsContent?.imgTitle}
                />
              </Grid>
              <Grid item xs={12} md={5} className={classes.textBox}>
                <Box>
                  <Box>
                    <Typography
                      style={{ padding: "1rem", marginTop: 0 }}
                      class="h2"
                      align="center"
                      color="primary"
                      gutterBottom
                      variant="h2"
                    >
                      At LWS, we redefine conventional learning. Our globally
                      researched curriculum is crafted to deliver academic
                      excellence.
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={navigateToPage}
                    >
                      Inspire Us Curriculum
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </main>
    </>
  );
}
