import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Grid, Box, Typography, Container, useMediaQuery, List } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Contact from "../components/Contact";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import BackToStart from "../components/BackToStart";
import usePathParameter from "../utils/usePathParameter";
import { valuesThane } from "../data/ThaneIndividualSiteData";
import {
  cbsetennistournamentMain,
  pageSettingsMain,
  cbsetennistournamentImgMain,
} from "../data/MainSiteData";
import { valuesCbgPalava } from "../data/CbgPalavaIndividualSiteData";
import { valuesLsgPalava } from "../data/LsgPalavaIndividualSiteData";
import { valuesTaloja } from "../data/TalojaIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "150vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  root: {
    alignItems: "center",
    // "@media (min-width:600px)": {
    //   maxWidth: '750px'
    // }
  },
  textBox: {
    position: "relative",
    left: "-12rem",
    padding: "2rem 1rem",
    display: "flex",
    alignItems: "center",
    height: "230px",
    boxShadow: "0px 4px 31px rgba(37, 54, 87, 0.5)",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("md")]: {
      position: "initial",
      height: "auto",
    },
  },
  nextBtn: {
    position: "relative",
    bottom: "3rem",
    left: "5rem",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    borderRadius: "50%",
    width: "90px",
    height: "90px",
    fontSize: "13px",
    border: "1px solid #263657",
    backgroundColor: "#263657",
    color: "#fff",
    "&:hover": {
      background: "#263657",
      border: "1px solid #263657",
      color: "#fff",
    },
  },
  containerMain: {
    height: "100vh",
    marginLeft: "7rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      marginLeft: 0,
    },
  },
  inspireSection: {
    width: "50vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      padding: "1rem",
    },
  },
  inspireSection1: {
    width: "50vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      padding: "3rem 1rem 1rem 1rem",
    },
  },
  areasSection: {
    width: "35vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      padding: "1rem",
    },
  },
  subText: {
    width: "77vh",
    fontSize: "18px",
    fontWeight: 600,
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      fontSize: "initial",
    },
  },
  subTextbulet: {
    width: "77vh",
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      fontSize: "initial",
      lineHeight: "30px",
      marginBottom: "10px",
    },
  },
  MuiBoxli: {
      marginBottom: "10px",
  },
  subTextMain: {
    display: "flex",
    alignItems: "center",
    padding: "1rem 0",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  inspirePointers: {
    width: "100vw",
    display: "flex",
    alignItems: "center",
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      display: "block",
      height: "auto",
      paddingTop: "2rem",
    },
  },
  h3Text: {
    fontFamily: "Lato",
    fontWeight: "bold",
    color: "#263657",
    fontSize: "35px",
    marginBottom: "10px",
    marginTop: "0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
  },
  h1Text: {
    fontFamily: "Lato",
    fontWeight: "bold",
    color: "#898EAC",
    fontSize: "55px",
    marginBottom: "10px",
    marginTop: "0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  },
  links: {
    margin: 0,
    padding: 0,
    color: "#263657",
    textDecoration: "underline",
    textAlign: "inherit",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linksNotLinked: {
    margin: 0,
    padding: 0,
    color: "#263657",
    textDecoration: "none",
    pointerEvents: "none",
    textAlign: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
  },
  title1: {
    fontSize: "17px",
    padding: "3px 3px 10px 3px",
  },
  imgMain: {
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
}));

export default function Cbsetennistournament() {
  const classes = useStyles();
  const leftMenuRef = useRef(null);
  const rightSectionRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const [valuesContent, setValuesContent] = useState([]);

  useEffect(() => {
    if (isThane) {
      setValuesContent(valuesThane);
    } else if (isCbgPalava) {
      setValuesContent(valuesCbgPalava);
    } else if (isLsgPalava) {
      setValuesContent(valuesLsgPalava);
    } else if (isTaloja) {
      setValuesContent(valuesTaloja);
    } else if (!isIndividual) {
      setValuesContent(cbsetennistournamentMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  // const slides = [{
  //   "id":"houses-1",
  //   "title":"Atulya:",
  //   "color": "#9C2020",
  //   "subtitle":"the incomparable.",
  //   "description":"A leader is incomparable, unequalled in their quest for excellence, and exhibits unmitigable zeal.",
  //   "quote":"Inspiration: Shri JRD Tata - ethical and trailblazing business leadership & corporate social initiatives"
  // },
  // {
  //   "id":"houses-2",
  //   "title":"Advaita:",
  //   "color": "#276440",
  //   "subtitle":"the one and only one.",
  //   "description":"A leader has an unchanging, singular vision of the goal.",
  //   "quote":"Inspiration: Shri APJ Abdul Kalam - unique peerless vision for our country and society"
  // },
  // {
  //   "id":"houses-3",
  //   "title":"Ananta:",
  //   "color": "#034667",
  //   "subtitle":"the infinite.",
  //   "description":"A leader embodies limitless possibilities and energy.",
  //   "quote":"Inspiration: Mother Teresa - utmost compassion, extended to the most downtrodden"
  // },
  // {
  //   "id":"houses-4",
  //   "title":"Akshaya:",
  //   "color": "#CF9832",
  //   "subtitle":"the undiminishing, inexhaustible spirit.",
  //   "description":"A leader exemplifies an undiminishing, inexhaustible spirit in all they set out to do.",
  //   "quote":"Inspiration: Mahatma Gandhi - inexhaustible strength, tolerance, patience, determination to achieve freedom"
  // }]

  const scrollToBackTarget = () => {
    leftMenuRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const position = rightSectionRef.current?.getBoundingClientRect().x;
    setScrollPosition(position);
  };

  useEffect(() => {
    const interval = setInterval(() => setCurrentSlide((prv) => prv + 1), 4500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageSettingsMain.cbsetennistournament.title}</title>
        {pageSettingsMain.cbsetennistournament.metaTags.map((tag) => {
          if (tag.name) {
            return (
              <meta name={tag.name} content={tag.content} key={tag.name} />
            );
          } else if (tag.property) {
            return (
              <meta
                property={tag.property}
                content={tag.content}
                key={tag.property}
              />
            );
          }
          return null;
        })}
        <link rel="canonical" href={pageSettingsMain.cbsetennistournament.canonical} />
      </Helmet>
      <Contact />
      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu breadCrumbTarget="CBSE Tennis Tournament" />
            <Grid
              ref={rightSectionRef}
              item
              xs={12}
              md={9}
              className={classes.rightSection}
            >
              <Grid container className={classes.containerMain}>
                <Grid
                  item
                  xs={12}
                  style={{
                    width: "90vw",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                <Typography
                    class="h2b"
                    variant="h1"
                    style={{ lineHeight: "normal" }}
                  >
                    {isIndividual
                      ? "Foundation and Middle Years"
                      : "CBSE Tennis Tournament"}
                  </Typography>
                  <Box className={classes.imgMain}>
                    <img
                      src={
                        matchesSM
                          ? "/assets/Cbse-Tennis-Tournament700x-480px_AS.jpg"
                          : "/assets/Cbse-Tennis-Tournament700x-480px_AS.jpg"
                      }
                      width="700px"
                      alt={cbsetennistournamentImgMain?.alt}
                      title={cbsetennistournamentImgMain?.imgTitle}
                      class="responsiveImg"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} className={classes.inspireSection}>
                  <Typography
                    class="h2b"
                    variant="h1"
                    style={{ lineHeight: "normal" }}
                  >
                    {isIndividual
                      ? "Foundation and Middle Years"
                      : "From The Principal"}
                  </Typography>
                  <Typography class="h3bcb">Dr. Rashmirekha Saha – Principal, LSG Palava</Typography>
                  <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subText}>
                    It gives me great honour to announce that CBSE has entrusted LODHA WORLD SCHOOL, LAKESHORE. PALAVA (Phase II) with the responsibility of conducting the SOUTH ZONE 
                    II TENNIS TOURNAMENT 2024-25, scheduled from 27th SEPTEMBER TO 30TH SEPTEMBER 2024.<br></br><br></br>

                    We cordially invite the participation of all CBSE-Affiliated Schools of South Zone II 
                    (as per circular No. Acad-58/2024 Annexure - B, Bangaluru Region, Pune Region and 
                    Thiruvananthapuram Region) in this prestigious tournament.<br></br><br></br>

                    We request you to kindly go through the CBSE South Zone II rules and regulations carefully.
Looking forward to your kind co-operation and support.
                    </Typography>
                    
                  </Box>
                </Grid>

                


                <Grid item xs={12} className={classes.inspireSection}>
                <Typography class="h3b">About The Event</Typography>
                  <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subText}>
                    The CBSE Tennis Tournament will be played according to the rules and regulations 
                    of All India Tennis Association (AITA), with modifications wherever applicable. 
                    At Zonal Level, the tournament will be played on a knock-out basis. In case there 
                    are fewer teams, it may be played on a league-cum-knockout basis.<br></br><br></br>

                    The tournament shall consist of two single and one doubles event in both the sections, 
                    i.e. boys and girls. Two singles matches shall be held, and in the case of a tie, 
                    doubles will follow.<br></br><br></br>

                    All matches shall be played as Best of Thirteen games with six-all as tie-breaker. 
                    The semi-final will be played on a “short set” basis and finals on the Best of Three 
                    sets with tie-breaker rule, if necessary.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} className={classes.inspireSection}>
                  
				        <Typography class="h3b">Eligibility And Registration Details</Typography>
                  <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subText}>
                    To register, participating schools must fill in the following participation forms:
                    </Typography>
                    </Box>
                    <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subTextbulet}>
                      <List component="div">
                      <Box> &#8226; Participant Details</Box>
                      <Box> &#8226; Travel Plan</Box>
                      <Box> &#8226; Boarding, Lodging, and Food Requirements</Box>
                      </List>
                    </Typography>
                  </Box>
                  <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subText}>
                    Participating schools may send one team, comprising a minimum of two and 
                    a maximum of four players, in separate Boys and Girls Categories in each 
                    of the following age groups*:
                    </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} className={classes.inspireSection}>
                    <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subTextbulet}>
                      <List component="div">
                      <Box> &#8226; Under 14: Born on or after 01.01.2011</Box>
                      <Box> &#8226; Under 17: Born on or after 01.01.2008</Box>
                      <Box> &#8226; Under 19: Born on or after 01.01.2006</Box>
                      </List>
                    </Typography>
                  </Box>
                  <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subText}>
                    <a
                       target="_blank"
                       href="/assets/Code-of-Conduct-&-Disqualification.pdf"
                        className={classes.links}
                    >
                    <Typography className={classes.title1}>
                        <b>Code of Conduct &amp; Disqualification Terms</b>
                    </Typography>
                    </a>
                    </Typography>
                    </Box>  
                    <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subText}>
                    <a
                       target="_blank"
                       href="/assets/LWS_Tournament_Forms.pdf"
                        className={classes.links}
                    >
                    <Typography className={classes.title1}>
                        <b>LWS Tournament Forms</b>
                    </Typography>
                    </a>
                    </Typography>
                  </Box>  
                  <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subText}>
                    <a
                       target="_blank"
                       href="/assets/LOS_Tournament_Food_Menu.pdf"
                        className={classes.links}
                    >
                    <Typography className={classes.title1}>
                        <b>Menu &amp; Food Arrangements</b>
                    </Typography>
                    </a>
                    </Typography>
                  </Box> 
                </Grid>

                <Grid item xs={12} className={classes.inspireSection}>
                  <Typography
                    class="h3b"
                    variant="h3"
                    style={{ lineHeight: "normal" }}
                  >
                    {isIndividual
                      ? "Foundation and Middle Years"
                      : "Mandatory Documents"}
                  </Typography>
                    <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subText}>
                    Soft copy to be shared via email to 
                    <a
                                    href="mailto:principal_lsg@lodhaworldschool.com"
                                    alt="email id"
                                    className={classes.links}
                                  >
                                    <Typography className={classes.title1}>
                                    principal_lsg@lodhaworldschool.com
                                    </Typography>
                                  </a>
                                   
                                  <a
                                    href="mailto:contact_lsg@lodhaworldschool.com"
                                    alt="email id"
                                    className={classes.links}
                                  >
                                    <Typography className={classes.title1}>
                                    CC- contact_lsg@lodhaworldschool.com
                                    </Typography>
                                  </a>
                    
                    </Typography>
                  </Box>
                  <Box className={classes.subTextMain}>
                  <Typography component="p" className={classes.subTextbulet}>
                      <List component="div">
                      <Box> &#8226; Consent Form - Letter of Authorization from School Principal</Box>
                      <Box> &#8226; Travel Plan</Box>
                      </List>
                    </Typography>
                    </Box>     
                </Grid>

                <Grid item xs={12} className={classes.inspireSection}>
                <Typography class="h3b">Hard copy to be carried by the participating school to the tournament</Typography>
                  <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subTextbulet}>
                    <List component="div">
                      <Box> &#8226; Consent Form - Letter of Authorization from School Principal</Box>
                      <Box> &#8226; Admit Cards as per CBSE online registration</Box>
                      <Box> &#8226; Participants List according to CBSE online registration</Box>
                      <Box> &#8226; Birth certificate photocopy (age proof)</Box>
                      <Box> &#8226; School flag</Box>
                      <Box> &#8226; Original entry form - signed by Principal</Box>
                      <Box> &#8226; Manager, coach and students’ photographs, duly attested by the Principal</Box>
                      <Box> &#8226; Participation forms on previous page</Box>
                      <Box> &#8226; Screenshot of payment for food &amp; accommodation</Box>
                      </List>
                    </Typography>
                  </Box>
                </Grid>


               
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {scrollPosition < 0 ? (
        <BackToStart goToTarget={scrollToBackTarget} />
      ) : null}
    </>
  );
}
