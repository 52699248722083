import React, { useState, useEffect } from "react";
import { Grid, Box, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "100vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  textBox: {
    // boxShadow: '0px 4px 31px rgba(37, 54, 87, 0.5)',
    backgroundColor: "#ffffff",
    borderRadius: "20px",
    border: "1px solid #263657",
    padding: "2rem 0rem",
    height: "100%",
    // margin: '0 2rem',
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
      height: "100%",
      width: "70vw",
      padding: "2rem 0",
    },
  },
  mainDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "100vh",
    // flexDirection: 'column',
    [theme.breakpoints.down("md")]: {
      height: "76vh",
      alignItems: "start",
    },
  },
  subDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      justifyContent: "center",
      alignItems: "flex-start",
    },
  },
  textMain: {
    marginLeft: "2rem",
    fontFamily: "Bodoni BE",
    fontWeight: "normal",
    fontStyle: "italic",
    color: "#263657",
    lineHeight: "27px",
    fontSize: "46px",
    [theme.breakpoints.down("md")]: {
      margin: "1rem",
    },
  },
  carousel: {
    width: "52vw",
    padding: "0 2rem",
    [theme.breakpoints.down("md")]: {
      padding: 0,
      width: "78vw",
      margin: "auto 0",
    },
  },
  leftBtn: {
    justifyContent: "space-evenly",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    fontSize: "13px",
    backgroundColor: "#898EAC",
    color: "#fff",
    "&:hover": {
      background: "#263657",
      border: "1px solid #263657",
    },
    [theme.breakpoints.down("md")]: {
      width: "35px",
      height: "35px",
    },
  },
  backBtn: {
    [theme.breakpoints.down("md")]: {
      margin: "auto 0",
    },
  },
  responsiveImg1: {
    width: "150px",
    height: "auto",
    margin: "20px",
    // transition: "transform 0.5s ease-in-out",
  },
}));

export default function LogoSlider() {
  const classes = useStyles();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const Logos = [
    {
      logo: "/assets/logos/uni1.png",
    },
    {
      logo: "/assets/logos/uni2.png",
    },
    {
      logo: "/assets/logos/uni3.png",
    },
    {
      logo: "/assets/logos/uni4.png",
    },
    {
      logo: "/assets/logos/uni5.png",
    },
    {
      logo: "/assets/logos/uni6.png",
    },
    {
      logo: "/assets/logos/uni7.png",
    },
    {
      logo: "/assets/logos/uni8.png",
    },
    {
      logo: "/assets/logos/uni9.png",
    },
    {
      logo: "/assets/logos/uni10.png",
    },
    {
      logo: "/assets/logos/uni11.png",
    },
    {
      logo: "/assets/logos/uni12.png",
    },
    {
      logo: "/assets/logos/uni13.png",
    },
    {
      logo: "/assets/logos/uni14.png",
    },
    {
      logo: "/assets/logos/uni15.png",
    },
    {
      logo: "/assets/logos/uni16.png",
    },
    {
      logo: "/assets/logos/uni17.png",
    },
    {
      logo: "/assets/logos/uni18.png",
    },
    {
      logo: "/assets/logos/uni19.png",
    },
    {
      logo: "/assets/logos/uni20.png",
    },
    {
      logo: "/assets/logos/uni21.png",
    },
    {
      logo: "/assets/logos/uni22.png",
    },
    {
      logo: "/assets/logos/uni23.png",
    },
    {
      logo: "/assets/logos/uni24.png",
    },
    {
      logo: "/assets/logos/uni25.png",
    },
    {
      logo: "/assets/logos/uni26.png",
    },
    {
      logo: "/assets/logos/uni27.png",
    },
    {
      logo: "/assets/logos/uni28.png",
    },
    {
      logo: "/assets/logos/uni29.png",
    },
    {
      logo: "/assets/logos/uni30.png",
    },
    {
      logo: "/assets/logos/uni31.png",
    },
    {
      logo: "/assets/logos/uni32.png",
    },
    {
      logo: "/assets/logos/uni33.png",
    },
    {
      logo: "/assets/logos/uni34.png",
    },
    {
      logo: "/assets/logos/uni35.png",
    },
    {
      logo: "/assets/logos/uni36.png",
    },
    {
      logo: "/assets/logos/uni37.png",
    },
    {
      logo: "/assets/logos/uni38.png",
    },
    {
      logo: "/assets/logos/uni39.png",
    },
  ];

  const goToNext = () => {
    setCurrentSlide((prev) => (prev + 1) % Logos.length);
    setIsPaused(true);
    setTimeout(() => setIsPaused(false), 500);
  };

  const goToPrevious = () => {
    setCurrentSlide((prev) => (prev === 0 ? Logos.length - 1 : prev - 1));
    setIsPaused(true);
    setTimeout(() => setIsPaused(false), 500);
  };

  useEffect(() => {
    if (isPaused) return;

    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % Logos.length);
    }, 1200);

    return () => clearInterval(interval);
  }, [isPaused, currentSlide, Logos.length]);

  const getSlideStyle = () => {
    return {
      transform: `translateX(-${currentSlide * 115}%)`,
      transition: "transform 1.2s ease-in-out",
    };
  };

  return (
    <>
      <Grid item xs={12} className={classes.subDiv}>
        <Box className={classes.backBtn} style={{ paddingRight: "1rem" }}>
          <IconButton
            className={classes.leftBtn}
            onClick={goToPrevious}
            aria-label="previous"
            variant="contained"
            align="center"
          >
            <img src="/assets/arrowWhite.svg" alt="" />
          </IconButton>
        </Box>

        <div style={{ width: "65%" }}>
          <div style={{ display: "flex", overflowX: "hidden" }}>
            {Logos.map((e, index) => (
              <img
                src={e.logo}
                alt=""
                width={e.width}
                className={classes.responsiveImg1}
                style={getSlideStyle(index)}
              />
            ))}
          </div>
        </div>

        <Box className={classes.backBtn} style={{ paddingLeft: "1rem" }}>
          <IconButton
            className={classes.leftBtn}
            onClick={goToNext}
            aria-label="next"
            variant="contained"
            align="center"
          >
            <img
              src="/assets/arrowWhite.svg"
              alt=""
              style={{ transform: "rotate(180deg)" }}
            />
          </IconButton>
        </Box>
      </Grid>
    </>
  );
}
