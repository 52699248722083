import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Grid, Box, Typography, Container, useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Contact from "../components/Contact";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import BackToStart from "../components/BackToStart";
import usePathParameter from "../utils/usePathParameter";
import { valuesThane } from "../data/ThaneIndividualSiteData";
import {
  valuesMain,
  pageSettingsMain,
  valuesImgMain,
} from "../data/MainSiteData";
import { valuesCbgPalava } from "../data/CbgPalavaIndividualSiteData";
import { valuesLsgPalava } from "../data/LsgPalavaIndividualSiteData";
import { valuesTaloja } from "../data/TalojaIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "250vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  root: {
    alignItems: "center",
    // "@media (min-width:600px)": {
    //   maxWidth: '750px'
    // }
  },
  textBox: {
    position: "relative",
    left: "-12rem",
    top: 0,
    padding: "2rem 1rem",
    display: "flex",
    alignItems: "center",
    height: "338px",
    boxShadow: "0px 4px 31px rgba(37, 54, 87, 0.5)",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("md")]: {
      position: "initial",
    },
  },
  nextBtn: {
    position: "relative",
    bottom: "3rem",
    left: "5rem",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    borderRadius: "50%",
    width: "90px",
    height: "90px",
    fontSize: "13px",
    border: "1px solid #263657",
    backgroundColor: "#263657",
    color: "#fff",
    "&:hover": {
      background: "#263657",
      border: "1px solid #263657",
      color: "#fff",
    },
  },
  containerMain: {
    height: "92vh",
    marginLeft: "7rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: "100vh",
      flexDirection: "row",
      marginLeft: 0,
    },
  },
  housesText: {
    padding: "2rem 2rem 0.5rem",
    textAlign: "center",
    fontSize: "24px",
    lineHeight: "27px",
    fontFamily: "Bodoni",
    fontWeight: "bold",
    color: "#263657",
    [theme.breakpoints.down("md")]: {
      padding: "1rem",
      fontSize: "22px",
    },
  },
  house: {
    width: "100vw",
    display: "flex",
    alignItems: "center",
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  imgMain: {
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
}));

export default function Values() {
  const classes = useStyles();
  const leftMenuRef = useRef(null);
  const rightSectionRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const [valuesContent, setValuesContent] = useState([]);

  useEffect(() => {
    if (isThane) {
      setValuesContent(valuesThane);
    } else if (isCbgPalava) {
      setValuesContent(valuesCbgPalava);
    } else if (isLsgPalava) {
      setValuesContent(valuesLsgPalava);
    } else if (isTaloja) {
      setValuesContent(valuesTaloja);
    } else if (!isIndividual) {
      setValuesContent(valuesMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  // const slides = [{
  //   "id":"houses-1",
  //   "title":"Atulya:",
  //   "color": "#9C2020",
  //   "subtitle":"the incomparable.",
  //   "description":"A leader is incomparable, unequalled in their quest for excellence, and exhibits unmitigable zeal.",
  //   "quote":"Inspiration: Shri JRD Tata - ethical and trailblazing business leadership & corporate social initiatives"
  // },
  // {
  //   "id":"houses-2",
  //   "title":"Advaita:",
  //   "color": "#276440",
  //   "subtitle":"the one and only one.",
  //   "description":"A leader has an unchanging, singular vision of the goal.",
  //   "quote":"Inspiration: Shri APJ Abdul Kalam - unique peerless vision for our country and society"
  // },
  // {
  //   "id":"houses-3",
  //   "title":"Ananta:",
  //   "color": "#034667",
  //   "subtitle":"the infinite.",
  //   "description":"A leader embodies limitless possibilities and energy.",
  //   "quote":"Inspiration: Mother Teresa - utmost compassion, extended to the most downtrodden"
  // },
  // {
  //   "id":"houses-4",
  //   "title":"Akshaya:",
  //   "color": "#CF9832",
  //   "subtitle":"the undiminishing, inexhaustible spirit.",
  //   "description":"A leader exemplifies an undiminishing, inexhaustible spirit in all they set out to do.",
  //   "quote":"Inspiration: Mahatma Gandhi - inexhaustible strength, tolerance, patience, determination to achieve freedom"
  // }]

  const scrollToBackTarget = () => {
    leftMenuRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const position = rightSectionRef.current?.getBoundingClientRect().x;
    setScrollPosition(position);
  };

  useEffect(() => {
    const interval = setInterval(() => setCurrentSlide((prv) => prv + 1), 4500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageSettingsMain.values.title}</title>
        {pageSettingsMain.values.metaTags.map((tag) => {
          if (tag.name) {
            return (
              <meta name={tag.name} content={tag.content} key={tag.name} />
            );
          } else if (tag.property) {
            return (
              <meta
                property={tag.property}
                content={tag.content}
                key={tag.property}
              />
            );
          }
          return null;
        })}
        <link rel="canonical" href={pageSettingsMain.values.canonical} />
      </Helmet>
      <Contact />
      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu breadCrumbTarget="Core Values" />
            <Grid
              ref={rightSectionRef}
              item
              xs={12}
              md={9}
              className={classes.rightSection}
            >
              <Grid container className={classes.containerMain}>
                <Grid
                  item
                  xs={12}
                  style={{
                    width: "100vw",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box className={classes.imgMain}>
                    <img
                      src={
                        matchesSM
                          ? "/assets/corevaluesmobile1.svg"
                          : "/assets/coreValues.svg"
                      }
                      width="970px"
                      alt={valuesImgMain?.alt}
                      title={valuesImgMain?.imgTitle}
                      class="responsiveImg"
                    />
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    width: "42vw",
                    display: "flex",
                    flexDirection: "initial",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "4rem 0",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      class="h1"
                      variant="h1"
                      style={{ marginBottom: "10px", textAlign: "center" }}
                    >
                      Lodha World School Core Values Of Its Houses
                    </Typography>
                    <Typography className={classes.housesText} variant="h2">
                      The four houses of Lodha World School further prescribe
                      virtues that all our young leaders uphold and honour. The
                      names of these houses have their roots in Sanskrit and
                      have eternal relevance to humans. They also transcend
                      gender identities.
                    </Typography>
                  </Box>
                </Grid>

                {/* houses */}
                <>
                  <Box className={classes.house}>
                    <Container maxWidth="lg">
                      <Grid container className={classes.root}>
                        <Grid item xs={12} md={7}>
                          <div class="slider-wrap-values">
                            <div
                              className="single-slide"
                              style={{ transitionDuration: "800ms" }}
                              id={
                                valuesContent[
                                  currentSlide % valuesContent.length
                                ]?.id
                              }
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={5} className={classes.textBox}>
                          <Box>
                            <Box style={{ textAlign: "center" }}>
                              <Typography
                                class="h2"
                                // variant="h1"
                                style={{
                                  color: `${
                                    valuesContent[
                                      currentSlide % valuesContent.length
                                    ]?.color
                                  }`,
                                  fontFamily: "Bodoni",
                                  transitionDuration: "800ms",
                                  fontSize: "2em",
                                  fontWeight: "bold",
                                  lineHeight: "27px",
                                }}
                              >
                                {
                                  valuesContent[
                                    currentSlide % valuesContent.length
                                  ]?.title
                                }{" "}
                                <br />{" "}
                                {
                                  valuesContent[
                                    currentSlide % valuesContent.length
                                  ]?.subtitle
                                }
                              </Typography>
                              <Typography
                                style={{
                                  padding: "10px",
                                  fontFamily: "Lato",
                                  fontWeight: "bold",
                                  color: "#263657",
                                  transitionDuration: "800ms",
                                  lineHeight: "27px",
                                  fontSize: "1.17em",
                                }}
                                class="h3"
                                variant="h3"
                              >
                                {
                                  valuesContent[
                                    currentSlide % valuesContent.length
                                  ]?.description
                                }
                              </Typography>
                              <Typography
                                style={{
                                  padding: "10px",
                                  fontFamily: "Bodoni BE",
                                  fontWeight: "normal",
                                  transitionDuration: "800ms",
                                  color: "#263657",
                                }}
                                class="h4"
                              >
                                {
                                  valuesContent[
                                    currentSlide % valuesContent.length
                                  ]?.quote
                                }
                              </Typography>
                              {isIndividual ? (
                                <Typography
                                  style={{
                                    transitionDuration: "800ms",
                                    fontSize: "14px",
                                  }}
                                  class="h1"
                                  variant="h1"
                                >
                                  {
                                    valuesContent[
                                      currentSlide % valuesContent.length
                                    ]?.captain
                                  }
                                </Typography>
                              ) : null}
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Container>
                  </Box>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {scrollPosition < 0 ? (
        <BackToStart goToTarget={scrollToBackTarget} />
      ) : null}
    </>
  );
}
