import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Grid, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Contact from "../components/Contact";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import BackToStart from "../components/BackToStart";
import usePathParameter from "../utils/usePathParameter";
import {
  skillachievementsMain,
  skillMain,
  pageSettingsMain,
} from "../data/MainSiteData";
import {
  skillachievementsCbgPalava,
  skillCbgPalava,
} from "../data/CbgPalavaIndividualSiteData";
import {
  skillachievementsLsgPalava,
  skillLsgPalava,
} from "../data/LsgPalavaIndividualSiteData";
import {
  skillachievementsTaloja,
  skillTaloja,
} from "../data/TalojaIndividualSiteData";
import {
  skillachievementsThane,
  skillThane,
} from "../data/ThaneIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "150vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  mainImg: {
    // width: "311px",
    textAlign: "center",
    // margin: "0 3rem",
    [theme.breakpoints.down("md")]: {
      margin: "3rem 0 0 0",
    },
  },
  root: {
    alignItems: "center",
    // "@media (min-width:600px)": {
    //   maxWidth: '750px'
    // }
  },
  textBox: {
    position: "relative",
    left: "-12rem",
    padding: "2rem 1rem",
    boxShadow: "0px 4px 31px rgba(37, 54, 87, 0.5)",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("md")]: {
      position: "initial",
    },
  },
  nextBtn: {
    position: "relative",
    left: "30rem",
    top: "-3rem",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    borderRadius: "50%",
    width: "90px",
    height: "90px",
    fontSize: "13px",
    border: "1px solid #263657",
    backgroundColor: "#263657",
    color: "#fff",
    "&:hover": {
      background: "#263657",
      border: "1px solid #263657",
      color: "#fff",
    },
  },
  containerMain: {
    height: "92vh",
    marginLeft: "7rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: "100vh",
      flexDirection: "row",
      marginLeft: 0,
    },
  },
  subText: {
    width: "77vh",
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      width: "100vh",
      fontSize: "initial",
      padding: "1rem",
    },
  },
  subTextMain: {
    display: "flex",
    alignItems: "center",
    padding: "0 4rem 4rem 0",
    [theme.breakpoints.down("md")]: {
      padding: "1rem 0 0 0",
    },
  },
  h1Main: {
    marginLeft: "3rem",
    width: "70vw",
    display: "flex",
    alignItems: "flex-start",
    height: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      width: "100vw",
      height: "auto",
      padding: "1rem",
    },
  },
}));

export default function SkillDevelopment() {
  const classes = useStyles();
  const leftMenuRef = useRef(null);
  const rightSectionRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const [achievements, setAchievements] = useState([]);
  const [skill, setSkill] = useState([]);

  // const slides = [
  //   {
  //     id: "skill-1",
  //     title: "Sports",
  //   },
  //   {
  //     id: "skill-2",
  //     title: "Music",
  //   },
  //   {
  //     id: "skill-3",
  //     title: "Dance",
  //   },
  //   {
  //     id: "skill-4",
  //     title: "Drama & Theatre",
  //   },
  //   {
  //     id: "skill-5",
  //     title: "Art",
  //   },
  // ];

  React.useEffect(() => {
    if (isThane) {
      setAchievements(skillachievementsThane);
      setSkill(skillThane);
    } else if (isCbgPalava) {
      setAchievements(skillachievementsCbgPalava);
      setSkill(skillCbgPalava);
    } else if (isLsgPalava) {
      setAchievements(skillachievementsLsgPalava);
      setSkill(skillLsgPalava);
    } else if (isTaloja) {
      setAchievements(skillachievementsTaloja);
      setSkill(skillTaloja);
    } else if (!isIndividual) {
      setAchievements(skillachievementsMain);
      setSkill(skillMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  const scrollToBackTarget = () => {
    leftMenuRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const position = rightSectionRef.current?.getBoundingClientRect().x;
    setScrollPosition(position);
  };

  useEffect(() => {
    const interval = setInterval(() => setCurrentSlide((prv) => prv + 1), 4500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (skill.length === 0) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{pageSettingsMain.skillDevelopment.title}</title>
        {pageSettingsMain.skillDevelopment.metaTags.map((tag) => {
          if (tag.name) {
            return (
              <meta name={tag.name} content={tag.content} key={tag.name} />
            );
          } else if (tag.property) {
            return (
              <meta
                property={tag.property}
                content={tag.content}
                key={tag.property}
              />
            );
          }
          return null;
        })}
        <link
          rel="canonical"
          href={pageSettingsMain.skillDevelopment.canonical}
        />
      </Helmet>
      <Contact />
      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu breadCrumbTarget="Skill Development" />
            <Grid
              ref={rightSectionRef}
              item
              xs={12}
              md={9}
              className={classes.rightSection}
            >
              <Grid container className={classes.containerMain}>
                {!isIndividual ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      width: "50vw",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      class="h2b"
                      variant="h1"
                      style={{ lineHeight: "normal" }}
                    >
                      Lodha World School - Skill Development
                    </Typography>

                    <Box className={classes.subTextMain}>
                      <Typography component="p" className={classes.subText}>
                        Learning at LWS is not confined to the four walls of the
                        classroom. It is the essence of all we are and do.
                        <br />
                        <br />
                        We aim to mould and nurture all-round leaders,
                        facilitating their development through our
                        all-encompassing range of co-curricular activities and
                        opportunities. Through these, our young leaders are not
                        just encouraged to enhance their inherent leadership
                        qualities, but also discover new ones along the way.
                        <br />
                        <br />
                        Our beyond-the-classroom learning, therefore, provides
                        the essential skills as well as the platform for a young
                        leader to perform and flourish.
                      </Typography>
                    </Box>
                  </Grid>
                ) : (
                  ""
                )}

                {/* sports */}
                <>
                  <Box className={classes.h1Main}>
                    <Typography
                      class="h1"
                      // variant="h1"
                      style={{
                        marginBottom: "10px",
                        fontFamily: "Lato",
                        transitionDuration: "800ms",
                        fontWeight: "bold",
                        color: "#898EAC",
                        fontSize: "55px",
                      }}
                    >
                      {skill[currentSlide % skill.length].title}
                    </Typography>
                    <Grid container className={classes.root}>
                      <Grid item xs={12}>
                        <div class="slider-wrap-skill">
                          <div
                            className="single-slide"
                            style={{ transitionDuration: "800ms" }}
                            id={skill[currentSlide % skill.length].id}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </>
                {!isIndividual ? (
                  ""
                ) : (
                  <>
                    {achievements.map((e) => (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                          width: "40vw",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={e?.imageSource}
                          width="220px"
                          alt=""
                          class="responsiveImg"
                          className={classes.img}
                        />
                        <Typography
                          class="h2"
                          variant="h4"
                          style={{ margin: 0 }}
                        >
                          {e?.name}
                        </Typography>
                        <Typography style={{ fontSize: "14px" }} class="h1">
                          {e?.title}
                        </Typography>
                        <Typography style={{ fontSize: "14px" }} class="h1">
                          {e?.title1}
                        </Typography>
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {scrollPosition < 0 ? (
        <BackToStart goToTarget={scrollToBackTarget} />
      ) : null}
    </>
  );
}
