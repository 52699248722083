import React, { useState, useRef } from "react";
import { Grid, Box, Typography, List } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Contact from "../components/Contact";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import BackToStart from "../components/BackToStart";
import usePathParameter from "../../src/utils/usePathParameter";
import {
  seniorpara1Thane,
  seniorpara2Thane,
  seniorpara3Thane,
  seniorpara4Thane,
} from "../data/ThaneIndividualSiteData";
import {
  seniorpara1Main,
  seniorpara2Main,
  seniorpara3Main,
  seniorpara4Main,
} from "../data/MainSiteData";
import {
  seniorpara1CbgPalava,
  seniorpara2CbgPalava,
  seniorpara3CbgPalava,
  seniorpara4CbgPalava,
} from "../data/CbgPalavaIndividualSiteData";
import {
  seniorpara1LsgPalava,
  seniorpara2LsgPalava,
  seniorpara3LsgPalava,
  seniorpara4LsgPalava,
} from "../data/LsgPalavaIndividualSiteData";
import {
  seniorpara1Taloja,
  seniorpara2Taloja,
  seniorpara3Taloja,
  seniorpara4Taloja,
} from "../data/TalojaIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "110vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  root: {
    alignItems: "center",
  },
  textBox: {
    position: "relative",
    left: "-12rem",
    padding: "2rem 1rem",
    display: "flex",
    alignItems: "center",
    height: "230px",
    boxShadow: "0px 4px 31px rgba(37, 54, 87, 0.5)",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("md")]: {
      position: "initial",
      height: "auto",
    },
  },
  nextBtn: {
    position: "relative",
    bottom: "3rem",
    left: "5rem",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    borderRadius: "50%",
    width: "90px",
    height: "90px",
    fontSize: "13px",
    border: "1px solid #263657",
    backgroundColor: "#263657",
    color: "#fff",
    "&:hover": {
      background: "#263657",
      border: "1px solid #263657",
      color: "#fff",
    },
  },
  containerMain: {
    height: "100vh",
    marginLeft: "7rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      marginLeft: 0,
    },
  },
  inspireSection: {
    width: "50vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      padding: "1rem",
    },
  },
  inspireSection1: {
    width: "50vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      padding: "3rem 1rem 1rem 1rem",
    },
  },
  areasSection: {
    // width: "80vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      padding: "1rem",
    },
  },
  subText: {
    // width: "70vh",
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "inherit",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      fontSize: "initial",
      textAlign: "initial",
    },
  },
  subTextLast: {
    width: "74vh",
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "inherit",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      fontSize: "initial",
      textAlign: "initial",
    },
  },
  subTextMain: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  inspirePointers: {
    width: "100vw",
    display: "flex",
    alignItems: "center",
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      display: "block",
      height: "auto",
      paddingTop: "2rem",
    },
  },
  h1Text: {
    fontFamily: "Lato",
    fontWeight: "bold",
    color: "#898EAC",
    fontSize: "55px",
    marginBottom: "10px",
    marginTop: "0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  },
}));

export default function SeniorYears() {
  const classes = useStyles();
  const leftMenuRef = useRef(null);
  const rightSectionRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const [para1, setpara1] = useState([]);
  const [para2, setpara2] = useState([]);
  const [para3, setpara3] = useState([]);
  const [para4, setpara4] = useState([]);

  const scrollToBackTarget = () => {
    leftMenuRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const position = rightSectionRef.current?.getBoundingClientRect().x;
    setScrollPosition(position);
  };

  React.useEffect(() => {
    if (isThane) {
      setpara1(seniorpara1Thane);
      setpara2(seniorpara2Thane);
      setpara3(seniorpara3Thane);
      setpara4(seniorpara4Thane);
    } else if (isCbgPalava) {
      setpara1(seniorpara1CbgPalava);
      setpara2(seniorpara2CbgPalava);
      setpara3(seniorpara3CbgPalava);
      setpara4(seniorpara4CbgPalava);
    } else if (isLsgPalava) {
      setpara1(seniorpara1LsgPalava);
      setpara2(seniorpara2LsgPalava);
      setpara3(seniorpara3LsgPalava);
      setpara4(seniorpara4LsgPalava);
    } else if (isTaloja) {
      setpara1(seniorpara1Taloja);
      setpara2(seniorpara2Taloja);
      setpara3(seniorpara3Taloja);
      setpara4(seniorpara4Taloja);
    } else if (!isIndividual) {
      setpara1(seniorpara1Main);
      setpara2(seniorpara2Main);
      setpara3(seniorpara3Main);
      setpara4(seniorpara4Main);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  return (
    <>
      <Contact />
      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu breadCrumbTarget="Senior Years" />
            <Grid
              ref={rightSectionRef}
              item
              xs={12}
              md={9}
              className={classes.rightSection}
            >
              <Grid container className={classes.containerMain}>
                <Grid
                  item
                  xs={12}
                  className={classes.inspireSection}
                  style={{ marginRight: "8rem" }}
                >
                  {/* <Typography class="h2b" variant="h1">
                    Senior Years
                  </Typography> */}
                  <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subText}>
                      {para1.para1}
                      <br />
                      <br />
                      {para1.para2}
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={classes.areasSection}
                  style={{ marginRight: "8rem" }}
                >
                  <Typography class="h3b">{para1.title1}</Typography>
                  <Box className={classes.subTextMain}>
                    <Typography
                      component="p"
                      className={classes.subTextLast}
                      style={{
                        justifyContent: "space-between",
                        textAlign: "initial",
                      }}
                    >
                      {para1.para3}
                      <List component="div">
                        {para2.map((e) => (
                          <Box>&#8226; &nbsp; {e.name}</Box>
                        ))}
                      </List>
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={classes.areasSection}
                  style={{ marginRight: "8rem" }}
                >
                  <Typography class="h3b">{para1.title2}</Typography>
                  <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subTextLast}>
                      <List component="div">
                        {para3.map((e) => (
                          <Box>&#8226; &nbsp; {e.name}</Box>
                        ))}
                      </List>
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={classes.areasSection}
                  style={{ marginRight: "8rem" }}
                >
                  <Typography class="h3b">{para1.title3}</Typography>
                  <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subText}>
                      <List component="div">
                        {para4.map((e) => (
                          <>
                            <Box>&#8226; &nbsp; {e.name}</Box>
                          </>
                        ))}
                      </List>
                      {para1.foot1} <br />
                      {para1.foot2}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item style={{ padding: "1px" }}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {scrollPosition < 0 ? (
        <BackToStart goToTarget={scrollToBackTarget} />
      ) : null}
    </>
  );
}
