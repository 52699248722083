import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Grid, Box, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Contact from "../components/Contact";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import usePathParameter from "../utils/usePathParameter";
import { testimonialsThane } from "../data/ThaneIndividualSiteData";
import { testimonialsMain, pageSettingsMain } from "../data/MainSiteData";
import { testimonialsCbgPalava } from "../data/CbgPalavaIndividualSiteData";
import { testimonialsLsgPalava } from "../data/LsgPalavaIndividualSiteData";
import { testimonialsTaloja } from "../data/TalojaIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "100vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  textBox: {
    // boxShadow: '0px 4px 31px rgba(37, 54, 87, 0.5)',
    backgroundColor: "#ffffff",
    borderRadius: "20px",
    border: "1px solid #263657",
    padding: "2rem 0rem",
    height: "100%",
    // margin: '0 2rem',
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
      height: "100%",
      width: "70vw",
      padding: "2rem 0",
    },
  },
  mainDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "100vh",
    // flexDirection: 'column',
    [theme.breakpoints.down("md")]: {
      height: "76vh",
      alignItems: "start",
    },
  },
  subDiv: {
    height: "33vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "7rem",
    marginLeft: "2rem",
    [theme.breakpoints.down("md")]: {
      height: "80vh",
      marginLeft: "auto",
      justifyContent: "center",
      alignItems: "flex-start",
    },
  },
  textMain: {
    marginLeft: "2rem",
    fontFamily: "Bodoni BE",
    fontWeight: "normal",
    fontStyle: "italic",
    color: "#263657",
    lineHeight: "27px",
    fontSize: "46px",
    [theme.breakpoints.down("md")]: {
      margin: "1rem",
    },
  },
  carousel: {
    width: "52vw",
    padding: "0 2rem",
    [theme.breakpoints.down("md")]: {
      padding: 0,
      width: "78vw",
      margin: "auto 0",
    },
  },
  leftBtn: {
    justifyContent: "space-evenly",
    borderRadius: "50%",
    width: "65px",
    height: "65px",
    fontSize: "13px",
    backgroundColor: "#898EAC",
    color: "#fff",
    "&:hover": {
      background: "#263657",
      border: "1px solid #263657",
    },
    [theme.breakpoints.down("md")]: {
      width: "35px",
      height: "35px",
    },
  },
  backBtn: {
    [theme.breakpoints.down("md")]: {
      margin: "auto 0",
    },
  },
}));

export default function Testimonials() {
  const classes = useStyles();
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const leftMenuRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [testimonials, setTestimonials] = useState([]);
  const rightSectionRef = useRef(null);

  useEffect(() => {
    if (isThane) {
      setTestimonials(testimonialsThane);
    } else if (isCbgPalava) {
      setTestimonials(testimonialsCbgPalava);
    } else if (isLsgPalava) {
      setTestimonials(testimonialsLsgPalava);
    } else if (isTaloja) {
      setTestimonials(testimonialsTaloja);
    } else if (!isIndividual) {
      setTestimonials(testimonialsMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  const goToNext = () => {
    setCurrentSlide((prv) => prv + 1);
  };

  const goToPrevious = () => {
    setCurrentSlide((prv) => prv - 1);
  };

  return (
    <>
      <Helmet>
        <title>{pageSettingsMain.testimonials.title}</title>
        {pageSettingsMain.testimonials.metaTags.map((tag) => {
          if (tag.name) {
            return (
              <meta name={tag.name} content={tag.content} key={tag.name} />
            );
          } else if (tag.property) {
            return (
              <meta
                property={tag.property}
                content={tag.content}
                key={tag.property}
              />
            );
          }
          return null;
        })}
        <link rel="canonical" href={pageSettingsMain.testimonials.canonical} />
      </Helmet>
      <Contact />
      <Box className={classes.outerWrapper}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu breadCrumbTarget="Testimonials" />
            <Grid
              ref={rightSectionRef}
              item
              xs={12}
              md={9}
              className={classes.rightSection}
            >
              <Grid
                container
                style={{ height: "100vh" }}
                className={classes.mainDiv}
              >
                <Grid item xs={12}>
                  <Typography
                    class="h2b"
                    variant="h1"
                    style={{ lineHeight: "normal", marginLeft: "2rem" }}
                  >
                    Lodha World School - Testimonials
                  </Typography>
                </Grid>
                {testimonials.length ? (
                  <Grid item xs={12} className={classes.subDiv}>
                    <Box className={classes.backBtn}>
                      <IconButton
                        className={classes.leftBtn}
                        onClick={goToPrevious}
                        aria-label="previous"
                        variant="contained"
                        align="center"
                      >
                        <img src="/assets/arrowWhite.svg" alt="" />
                      </IconButton>
                    </Box>

                    <Box className={classes.carousel}>
                      <Box className={classes.textBox}>
                        <img
                          src="/assets/quote1.svg"
                          style={{ width: "auto" }}
                          alt=""
                        />
                        <Typography
                          style={{
                            padding: "0.5rem 1rem 0.5rem",
                            fontSize: "17px",
                            textAlign: "center",
                            lineHeight: "normal",
                          }}
                          class="h2"
                          variant="h3"
                        >
                          {
                            testimonials[currentSlide % testimonials.length]
                              ?.title
                          }
                        </Typography>
                        <img
                          src="/assets/quote2.svg"
                          style={{ width: "auto" }}
                          alt=""
                        />
                        <Typography
                          style={{
                            fontSize: "18px",
                            margin: "1rem",
                            textAlign: "center",
                          }}
                          class="h1"
                        >
                          {
                            testimonials[currentSlide % testimonials.length]
                              ?.name1
                          }
                          <Typography
                            style={{
                              padding: 0,
                              marginTop: "8px",
                              fontSize: "14px",
                              textAlign: "center",
                              lineHeight: "normal",
                            }}
                            class="h2"
                            variant="h3"
                          >
                            {
                              testimonials[currentSlide % testimonials.length]
                                ?.name2
                            }
                          </Typography>
                        </Typography>
                      </Box>
                      <Box></Box>
                    </Box>

                    <Box className={classes.backBtn}>
                      <IconButton
                        className={classes.leftBtn}
                        onClick={goToNext}
                        aria-label="next"
                        variant="contained"
                        align="center"
                      >
                        <img
                          src="/assets/arrowWhite.svg"
                          alt=""
                          style={{ transform: "rotate(180deg)" }}
                        />
                      </IconButton>
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12} className={classes.subDiv}></Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
