import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import "./index.css";
import App from "./App";
import withFacebookPixel from "./utils/withFacebookPixel";

const tagManagerArgs = {
  gtmId: "GTM-NBWCQ5S",
};

TagManager.initialize(tagManagerArgs);

const options = {
  autoConfig: true,
  debug: false,
};

ReactPixel.init(473742003304034, null, options);

const AppWithAnalytics = withFacebookPixel(App);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppWithAnalytics />
    </BrowserRouter>
  </React.StrictMode>
);
