import React, { useState, useRef } from "react";
import { Grid, Box, Typography, List, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Contact from "../components/Contact";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import BackToStart from "../components/BackToStart";
import usePathParameter from "../utils/usePathParameter";
import { openFileInNewTab } from "../utils/minio";
import { markersMain } from "../data/MainSiteData";
import { markersCbgPalava } from "../data/CbgPalavaIndividualSiteData";
import { markersLsgPalava } from "../data/LsgPalavaIndividualSiteData";
import { markersTaloja } from "../data/TalojaIndividualSiteData";
import { markersThane } from "../data/ThaneIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "450vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  root: {
    alignItems: "center",
    textAlign: "center",
    width: "100vw",
    "@media (min-width:600px)": {
      maxWidth: "750px",
    },
  },
  content: {
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
    "@media (min-width:1024px) and (max-width:1240px)": {
      padding: "40px",
    },
    "@media (min-width:760px) and (max-width:850px)": {
      padding: "40px",
    },
  },
  mainDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  visionQuote: {
    width: "60vw",
    margin: "0 10rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: 0,
      padding: "1rem",
    },
  },
  containerMain: {
    height: "92vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "9% 0",
    [theme.breakpoints.down("md")]: {
      padding: 0,
      height: "auto",
    },
  },
  subText: {
    padding: "0 0",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  subTextTypo: {
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      fontSize: "initial",
    },
  },
  markersTitle: {
    padding: "2rem 2rem 0.5rem",
    marginBottom: 0,
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  description: {
    textAlign: "justify",
    margin: "0 10rem 0 7rem",
    width: "35vw",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      width: "100%",
      paddingTop: "2rem",
    },
  },
  description1: {
    textAlign: "justify",
    margin: "0 10rem 0 7rem",
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      width: "100%",
      paddingTop: "2rem",
    },
  },
  subDesc: {
    fontSize: "17px",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "auto",
      padding: "1rem",
    },
  },
  section: {
    [theme.breakpoints.down("md")]: {
      marginTop: "3rem",
    },
  },
  h3bText: {
    textAlign: "initial",
    fontFamily: "Lato",
    fontWeight: "bold",
    color: "#898EAC",
    fontSize: "28px",
    marginTop: "18px",
    marginLeft: "0px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "1rem",
    },
  },
  link: {
    textDecoration: "none",
    color: "#263657",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default function Sitemap() {
  const classes = useStyles();
  const leftMenuRef = useRef(null);
  const rightSectionRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const [markers, setMarkers] = useState([]);

  const openPublicDisclosure = () => {
    openFileInNewTab("Public Disclosure.pdf");
  };

  React.useEffect(() => {
    if (isThane) {
      setMarkers(markersThane);
    } else if (isCbgPalava) {
      setMarkers(markersCbgPalava);
    } else if (isLsgPalava) {
      setMarkers(markersLsgPalava);
    } else if (isTaloja) {
      setMarkers(markersTaloja);
    } else if (!isIndividual) {
      setMarkers(markersMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  const scrollToBackTarget = () => {
    leftMenuRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const position = rightSectionRef.current?.getBoundingClientRect().x;
    setScrollPosition(position);
  };

  if (markers.length === 0) {
    return null;
  }

  return (
    <>
      <Contact />
      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu breadCrumbTarget="Sitemap" />
            <Grid
              ref={rightSectionRef}
              item
              xs={12}
              md={9}
              className={classes.rightSection}
            >
              <Grid container className={classes.containerMain}>
                {/* LWS Main sitemap */}
                <Grid item xs={12} className={classes.visionQuote}>
                  <Typography
                    class="h2b"
                    variant="h1"
                    style={{ lineHeight: "normal" }}
                  >
                    Lodha World School - Sitemap
                  </Typography>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        About Us
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link href={"/ourstory"} className={classes.link}>
                              Our Story
                            </Link>
                          </Box>
                          <Box>
                            {" "}
                            &#8226; &nbsp; Our Philosophy
                            <List
                              component="div"
                              sx={{ display: "grid", ml: 4 }}
                            >
                              <Box>
                                <Link href={"/vision"} className={classes.link}>
                                  {" "}
                                  <span
                                    style={{
                                      color: "#898EAC",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    &#8226;{" "}
                                  </span>{" "}
                                  Vision
                                </Link>{" "}
                              </Box>
                              <Box>
                                <Link
                                  href={"/lwsmarkers"}
                                  className={classes.link}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      color: "#898EAC",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    &#8226;{" "}
                                  </span>{" "}
                                  LWS Markers
                                </Link>{" "}
                              </Box>
                              <Box>
                                <Link href={"/values"} className={classes.link}>
                                  {" "}
                                  <span
                                    style={{
                                      color: "#898EAC",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    &#8226;{" "}
                                  </span>{" "}
                                  Values
                                </Link>{" "}
                              </Box>
                            </List>
                          </Box>
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link href={"/people"} className={classes.link}>
                              People
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>

                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        Academics
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/inspireuscurriculum"}
                              className={classes.link}
                            >
                              The Inspire Us Curriculum
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link href={"/results"} className={classes.link}>
                              Results and Placements
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>

                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        Beyond the Classroom
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/skilldevelopment"}
                              className={classes.link}
                            >
                              Skill Development
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/leadershipdevelopment"}
                              className={classes.link}
                            >
                              Leadership Development
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>
                  </div>

                  <div style={{ display: "flex" }}>
                    <Box
                      className={classes.subText}
                      style={{ marginRight: "4rem" }}
                    >
                      <Typography className={classes.h3bText}>
                        In Other thoughts
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link href={"/awards"} className={classes.link}>
                              Awards
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/testimonials"}
                              className={classes.link}
                            >
                              Testimonials
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>

                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        Join LWS
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link href={"/career"} className={classes.link}>
                              Career
                            </Link>
                          </Box>
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link href={"/alumni"} className={classes.link}>
                              Alumni
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>
                  </div>
                </Grid>

                {/* LWS Thane sitemap */}
                <Grid item xs={12} className={classes.visionQuote}>
                  <Typography
                    class="h2b"
                    variant="h1"
                    style={{ lineHeight: "normal" }}
                  >
                    Lodha World School, Thane
                  </Typography>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        About Us
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/thane/ourstory"}
                              className={classes.link}
                            >
                              Our Story
                            </Link>
                          </Box>
                          <Box>
                            {" "}
                            &#8226; &nbsp; Our Philosophy
                            <List
                              component="div"
                              sx={{ display: "grid", ml: 4 }}
                            >
                              <Box>
                                <Link
                                  href={"/thane/vision"}
                                  className={classes.link}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      color: "#898EAC",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    &#8226;{" "}
                                  </span>{" "}
                                  Vision
                                </Link>{" "}
                              </Box>
                              <Box>
                                <Link
                                  href={"/thane/lwsmarkers"}
                                  className={classes.link}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      color: "#898EAC",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    &#8226;{" "}
                                  </span>{" "}
                                  LWS Markers
                                </Link>{" "}
                              </Box>
                              <Box>
                                <Link
                                  href={"/thane/values"}
                                  className={classes.link}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      color: "#898EAC",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    &#8226;{" "}
                                  </span>{" "}
                                  Values
                                </Link>{" "}
                              </Box>
                            </List>
                          </Box>
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/thane/people"}
                              className={classes.link}
                            >
                              People
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>

                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        Academics
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/thane/inspireuscurriculum"}
                              className={classes.link}
                            >
                              Foundation and Middle Years
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/thane/senioryears"}
                              className={classes.link}
                            >
                              Senior Years
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/thane/results"}
                              className={classes.link}
                            >
                              Results and Placements
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>

                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        Beyond the Classroom
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/thane/skilldevelopment"}
                              className={classes.link}
                            >
                              Skill Development
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/thane/leadershipdevelopment"}
                              className={classes.link}
                            >
                              Leadership Development
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/thane/infrastructure"}
                              className={classes.link}
                            >
                              Infrastructure
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>
                  </div>

                  <div style={{ display: "flex" }}>
                    <Box
                      className={classes.subText}
                      style={{ marginRight: "4rem" }}
                    >
                      <Typography className={classes.h3bText}>
                        In Other thoughts
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/thane/awards"}
                              className={classes.link}
                            >
                              Awards
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/thane/testimonials"}
                              className={classes.link}
                            >
                              Testimonials
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>

                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        Join LWS
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/thane/career"}
                              className={classes.link}
                            >
                              Career
                            </Link>
                          </Box>
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/thane/alumni"}
                              className={classes.link}
                            >
                              Alumni
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>
                  </div>
                </Grid>

                {/* LWS CBG sitemap */}
                <Grid item xs={12} className={classes.visionQuote}>
                  <Typography
                    class="h2b"
                    variant="h1"
                    style={{ lineHeight: "normal" }}
                  >
                    Lodha World School, CBG, Palava
                  </Typography>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        About Us
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/cbgpalava/ourstory"}
                              className={classes.link}
                            >
                              Our Story
                            </Link>
                          </Box>
                          <Box>
                            {" "}
                            &#8226; &nbsp; Our Philosophy
                            <List
                              component="div"
                              sx={{ display: "grid", ml: 4 }}
                            >
                              <Box>
                                <Link
                                  href={"/cbgpalava/vision"}
                                  className={classes.link}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      color: "#898EAC",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    &#8226;{" "}
                                  </span>{" "}
                                  Vision
                                </Link>{" "}
                              </Box>
                              <Box>
                                <Link
                                  href={"/cbgpalava/lwsmarkers"}
                                  className={classes.link}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      color: "#898EAC",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    &#8226;{" "}
                                  </span>{" "}
                                  LWS Markers
                                </Link>{" "}
                              </Box>
                              <Box>
                                <Link
                                  href={"/cbgpalava/values"}
                                  className={classes.link}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      color: "#898EAC",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    &#8226;{" "}
                                  </span>{" "}
                                  Values
                                </Link>{" "}
                              </Box>
                            </List>
                          </Box>
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/cbgpalava/people"}
                              className={classes.link}
                            >
                              People
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>

                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        Academics
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/cbgpalava/inspireuscurriculum"}
                              className={classes.link}
                            >
                              Foundation and Middle Years
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/cbgpalava/senioryears"}
                              className={classes.link}
                            >
                              Senior Years
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/cbgpalava/results"}
                              className={classes.link}
                            >
                              Results and Placements
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>

                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        Beyond the Classroom
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/cbgpalava/skilldevelopment"}
                              className={classes.link}
                            >
                              Skill Development
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/cbgpalava/leadershipdevelopment"}
                              className={classes.link}
                            >
                              Leadership Development
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/cbgpalava/infrastructure"}
                              className={classes.link}
                            >
                              Infrastructure
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>
                  </div>

                  <div style={{ display: "flex" }}>
                    <Box
                      className={classes.subText}
                      style={{ marginRight: "4rem" }}
                    >
                      <Typography className={classes.h3bText}>
                        In Other thoughts
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/cbgpalava/awards"}
                              className={classes.link}
                            >
                              Awards
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/cbgpalava/testimonials"}
                              className={classes.link}
                            >
                              Testimonials
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>

                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        Join LWS
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/cbgpalava/career"}
                              className={classes.link}
                            >
                              Career
                            </Link>
                          </Box>
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/cbgpalava/alumni"}
                              className={classes.link}
                            >
                              Alumni
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>
                  </div>
                </Grid>

                {/* LWS LSG sitemap */}
                <Grid item xs={12} className={classes.visionQuote}>
                  <Typography
                    class="h2b"
                    variant="h1"
                    style={{ lineHeight: "normal" }}
                  >
                    Lodha World School, LSG, Palava
                  </Typography>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        About Us
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/lsgpalava/ourstory"}
                              className={classes.link}
                            >
                              Our Story
                            </Link>
                          </Box>
                          <Box>
                            {" "}
                            &#8226; &nbsp; Our Philosophy
                            <List
                              component="div"
                              sx={{ display: "grid", ml: 4 }}
                            >
                              <Box>
                                <Link
                                  href={"/lsgpalava/vision"}
                                  className={classes.link}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      color: "#898EAC",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    &#8226;{" "}
                                  </span>{" "}
                                  Vision
                                </Link>{" "}
                              </Box>
                              <Box>
                                <Link
                                  href={"/lsgpalava/lwsmarkers"}
                                  className={classes.link}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      color: "#898EAC",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    &#8226;{" "}
                                  </span>{" "}
                                  LWS Markers
                                </Link>{" "}
                              </Box>
                              <Box>
                                <Link
                                  href={"/lsgpalava/values"}
                                  className={classes.link}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      color: "#898EAC",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    &#8226;{" "}
                                  </span>{" "}
                                  Values
                                </Link>{" "}
                              </Box>
                            </List>
                          </Box>
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/lsgpalava/people"}
                              className={classes.link}
                            >
                              People
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              to="#"
                              onClick={openPublicDisclosure}
                              className={classes.link}
                            >
                              Public Disclosure
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>

                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        Academics
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/lsgpalava/inspireuscurriculum"}
                              className={classes.link}
                            >
                              Foundation and Middle Years
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/lsgpalava/senioryears"}
                              className={classes.link}
                            >
                              Senior Years
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>

                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        Beyond the Classroom
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/lsgpalava/skilldevelopment"}
                              className={classes.link}
                            >
                              Skill Development
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/lsgpalava/leadershipdevelopment"}
                              className={classes.link}
                            >
                              Leadership Development
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/lsgpalava/infrastructure"}
                              className={classes.link}
                            >
                              Infrastructure
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>
                  </div>

                  <div style={{ display: "flex" }}>
                    <Box
                      className={classes.subText}
                      style={{ marginRight: "4rem" }}
                    >
                      <Typography className={classes.h3bText}>
                        In Other thoughts
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/lsgpalava/awards"}
                              className={classes.link}
                            >
                              Awards
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/lsgpalava/testimonials"}
                              className={classes.link}
                            >
                              Testimonials
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>

                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        Join LWS
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/lsgpalava/career"}
                              className={classes.link}
                            >
                              Career
                            </Link>
                          </Box>
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/lsgpalava/alumni"}
                              className={classes.link}
                            >
                              Alumni
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>
                  </div>
                </Grid>

                {/* LWS Taloja sitemap */}
                <Grid item xs={12} className={classes.visionQuote}>
                  <Typography
                    class="h2b"
                    variant="h1"
                    style={{ lineHeight: "normal" }}
                  >
                    Lodha World School, Taloja
                  </Typography>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        About Us
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/taloja/ourstory"}
                              className={classes.link}
                            >
                              Our Story
                            </Link>
                          </Box>
                          <Box>
                            {" "}
                            &#8226; &nbsp; Our Philosophy
                            <List
                              component="div"
                              sx={{ display: "grid", ml: 4 }}
                            >
                              <Box>
                                <Link
                                  href={"/taloja/vision"}
                                  className={classes.link}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      color: "#898EAC",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    &#8226;{" "}
                                  </span>{" "}
                                  Vision
                                </Link>{" "}
                              </Box>
                              <Box>
                                <Link
                                  href={"/taloja/lwsmarkers"}
                                  className={classes.link}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      color: "#898EAC",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    &#8226;{" "}
                                  </span>{" "}
                                  LWS Markers
                                </Link>{" "}
                              </Box>
                              <Box>
                                <Link
                                  href={"/taloja/values"}
                                  className={classes.link}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      color: "#898EAC",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    &#8226;{" "}
                                  </span>{" "}
                                  Values
                                </Link>{" "}
                              </Box>
                            </List>
                          </Box>
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/taloja/people"}
                              className={classes.link}
                            >
                              People
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>

                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        Academics
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/taloja/inspireuscurriculum"}
                              className={classes.link}
                            >
                              Foundation and Middle Years
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/taloja/senioryears"}
                              className={classes.link}
                            >
                              Senior Years
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>

                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        Beyond the Classroom
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/taloja/skilldevelopment"}
                              className={classes.link}
                            >
                              Skill Development
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/taloja/leadershipdevelopment"}
                              className={classes.link}
                            >
                              Leadership Development
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/taloja/infrastructure"}
                              className={classes.link}
                            >
                              Infrastructure
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>
                  </div>

                  <div style={{ display: "flex" }}>
                    <Box
                      className={classes.subText}
                      style={{ marginRight: "4rem" }}
                    >
                      <Typography className={classes.h3bText}>
                        In Other thoughts
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/taloja/awards"}
                              className={classes.link}
                            >
                              Awards
                            </Link>
                          </Box>

                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/taloja/testimonials"}
                              className={classes.link}
                            >
                              Testimonials
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>

                    <Box className={classes.subText}>
                      <Typography className={classes.h3bText}>
                        Join LWS
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        <List component="div">
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/taloja/career"}
                              className={classes.link}
                            >
                              Career
                            </Link>
                          </Box>
                          <Box>
                            {" "}
                            &#8226; &nbsp;
                            <Link
                              href={"/taloja/alumni"}
                              className={classes.link}
                            >
                              Alumni
                            </Link>
                          </Box>
                        </List>
                      </Typography>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {scrollPosition < 0 ? (
        <BackToStart goToTarget={scrollToBackTarget} />
      ) : null}
    </>
  );
}
