// import React from 'react';
import React, {useState, useEffect} from 'react';
import { Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import ArrowWhite from "../assets/arrowWhite.svg";

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center', textAlign: 'center', height: '100vh',
  },
  iconBtn: {
    position: 'fixed',
    bottom: '2rem',
    right: '2rem',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: '50%',
    width: '65px',
    height: '65px',
    fontSize: '13px',
    backgroundColor: '#898EAC', color: '#fff',
    '&:hover': {
      background: "#263657",
      border: '1px solid 263657',
    },
  },
  backBtn: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }
}));

export default function BackToStart({ goToTarget }) {
  const classes = useStyles()
  const [showBtn, setShowBtn] = useState('none');
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if(window.scrollY > 20) {
        setShowBtn('initial');
      }
    })
  }, [])

  return (
    <>
    <Box className={classes.backBtn}>
      <IconButton className={classes.iconBtn} style={{display: {showBtn}}} onClick={goToTarget} aria-label="delete" variant='contained' align="center">
        <img src='/assets/arrowWhite.svg' alt="" />
        Back
      </IconButton>
    </Box>
    </>
  );
}