import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Grid, Box, Typography, List } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Contact from "../components/Contact";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import BackToStart from "../components/BackToStart";
import usePathParameter from "../utils/usePathParameter";
import {
  markersMain,
  designMain,
  environmentMain,
  wellnessMain,
  pageSettingsMain,
} from "../data/MainSiteData";
import {
  markersCbgPalava,
  designCbgPalava,
  environmentCbgPalava,
  wellnessCbgPalava,
} from "../data/CbgPalavaIndividualSiteData";
import {
  markersLsgPalava,
  designLsgPalava,
  environmentLsgPalava,
  wellnessLsgPalava,
} from "../data/LsgPalavaIndividualSiteData";
import {
  markersTaloja,
  designTaloja,
  environmentTaloja,
  wellnessTaloja,
} from "../data/TalojaIndividualSiteData";
import {
  markersThane,
  designThane,
  environmentThane,
  wellnessThane,
} from "../data/ThaneIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "500vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  root: {
    alignItems: "center",
    textAlign: "center",
    width: "100vw",
    "@media (min-width:600px)": {
      maxWidth: "750px",
    },
  },
  content: {
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
    "@media (min-width:1024px) and (max-width:1240px)": {
      padding: "40px",
    },
    "@media (min-width:760px) and (max-width:850px)": {
      padding: "40px",
    },
  },
  mainDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  visionQuote: {
    width: "40vw",
    margin: "0 10rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: 0,
      padding: "1rem",
    },
  },
  containerMain: {
    height: "92vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "9% 0",
    [theme.breakpoints.down("md")]: {
      padding: 0,
      height: "auto",
    },
  },
  subText: {
    padding: "4rem 0",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  subTextTypo: {
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      fontSize: "initial",
    },
  },
  markersTitle: {
    padding: "2rem 2rem 0.5rem",
    marginBottom: 0,
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  description: {
    textAlign: "justify",
    margin: "0 10rem 0 7rem",
    width: "35vw",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      width: "100%",
      paddingTop: "2rem",
    },
  },
  description1: {
    textAlign: "justify",
    margin: "0 10rem 0 7rem",
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      width: "100%",
      paddingTop: "2rem",
    },
  },
  subDesc: {
    fontSize: "17px",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "auto",
      padding: "1rem",
    },
  },
  section: {
    [theme.breakpoints.down("md")]: {
      marginTop: "3rem",
    },
  },
  h3bText: {
    textAlign: "initial",
    fontFamily: "Lato",
    fontWeight: "bold",
    color: "#898EAC",
    fontSize: "28px",
    marginTop: "18px",
    marginLeft: "0px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "1rem",
    },
  },
}));

export default function Markers() {
  const classes = useStyles();
  const leftMenuRef = useRef(null);
  const rightSectionRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const [markers, setMarkers] = useState([]);
  const [design, setDesign] = useState([]);
  const [environment, setEnvironment] = useState([]);
  const [wellness, setWellness] = useState([]);

  React.useEffect(() => {
    if (isThane) {
      setMarkers(markersThane);
      setDesign(designThane);
      setEnvironment(environmentThane);
      setWellness(wellnessThane);
    } else if (isCbgPalava) {
      setMarkers(markersCbgPalava);
      setDesign(designCbgPalava);
      setEnvironment(environmentCbgPalava);
      setWellness(wellnessCbgPalava);
    } else if (isLsgPalava) {
      setMarkers(markersLsgPalava);
      setDesign(designLsgPalava);
      setEnvironment(environmentLsgPalava);
      setWellness(wellnessLsgPalava);
    } else if (isTaloja) {
      setMarkers(markersTaloja);
      setDesign(designTaloja);
      setEnvironment(environmentTaloja);
      setWellness(wellnessTaloja);
    } else if (!isIndividual) {
      setMarkers(markersMain);
      setDesign(designMain);
      setEnvironment(environmentMain);
      setWellness(wellnessMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  const scrollToBackTarget = () => {
    leftMenuRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const position = rightSectionRef.current?.getBoundingClientRect().x;
    setScrollPosition(position);
  };

  if (markers.length === 0) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{pageSettingsMain.lwsMarkers.title}</title>
        {pageSettingsMain.lwsMarkers.metaTags.map((tag) => {
          if (tag.name) {
            return (
              <meta name={tag.name} content={tag.content} key={tag.name} />
            );
          } else if (tag.property) {
            return (
              <meta
                property={tag.property}
                content={tag.content}
                key={tag.property}
              />
            );
          }
          return null;
        })}
        <link rel="canonical" href={pageSettingsMain.lwsMarkers.canonical} />
      </Helmet>
      <Contact />
      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu breadCrumbTarget="LWS Markers" />
            <Grid
              ref={rightSectionRef}
              item
              xs={12}
              md={9}
              className={classes.rightSection}
            >
              <Grid container className={classes.containerMain}>
                <Grid item xs={12} className={classes.visionQuote}>
                  <Typography
                    class="h2b"
                    variant="h1"
                    style={{ lineHeight: "normal" }}
                  >
                    Lodha World School - Markers
                  </Typography>

                  <Box className={classes.subText}>
                    <Typography component="p" className={classes.subTextTypo}>
                      At Lodha World School, the pupil is at the heart of
                      everything we do. Our vision of building a revolutionary
                      future begins with them, and we go the extra mile to mould
                      these young leaders in revolutionary ways.
                      <br />
                      <br />
                      Our multifaceted learning environment goes beyond
                      brick-and-mortar, fostering curiosity, sparking
                      conversations and exchange of ideas, helping them become
                      the best they can be.
                    </Typography>
                  </Box>
                </Grid>

                {/* 1. symbiotic learning */}
                <>
                  <Grid item xs={12} className={classes.section}>
                    <Grid
                      container
                      className={classes.root}
                      sx={{ bgcolor: "#E3D5CA" }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          className={classes.markersTitle}
                          class="h2b"
                          align="center"
                          color="primary"
                          gutterBottom
                          variant="h2"
                        >
                          1. Symbiotic Learning
                        </Typography>
                        <Typography class="h6" variant="h6">
                          Learning from everyone and everything
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            top: "2rem",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div class="slider-wrap1">
                            {markers.map((m) => (
                              <div class="markers-slide" id={m.id} />
                            ))}
                            {/* <div class="markers-slide" id="symbiotic-1" />
                            <div class="markers-slide" id="symbiotic-2" />
                            <div class="markers-slide" id="symbiotic-3" />
                            <div class="markers-slide" id="symbiotic-4" />
                            <div class="markers-slide" id="symbiotic-5" /> */}
                          </div>
                          <Box
                            style={{
                              backgroundColor: "#263657",
                              height: "1.2rem",
                              width: "450px",
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* description */}
                  <Grid item xs={12}>
                    <Box className={classes.description}>
                      <Typography component="p" className={classes.subDesc}>
                        Symbiotic Learning blurs the line between the teacher
                        and the taught. It reinforces the idea that learning can
                        be derived from anyone and anything and encourages the
                        sharing of knowledge. Since every individual experiences
                        the world in their unique way, we inculcate Experiential
                        Learning, giving every child the space to think,
                        analyse, and apply their own understanding to concepts
                        taught.
                        <br />
                        <br />
                        Our go-to learning technique is a blend between
                        Collaboration and Experiential Learning, and a hallmark
                        of the Montessori methodology which is followed in our
                        schools.
                      </Typography>
                      <Typography className={classes.h3bText}>
                        Multi-age learning environment
                      </Typography>
                      <br />
                      <Typography component="p" className={classes.subDesc}>
                        Our multi-age learning environment is mutually
                        beneficial not just for our pupils, but also faculty.
                        The interaction between the younger and older pupils and
                        between the experienced and new teachers sparks
                        curiosity, different perspectives and new learnings.
                      </Typography>
                    </Box>
                  </Grid>
                </>

                {/* 2. Design Thinking */}
                <>
                  <Grid item xs={12} className={classes.section}>
                    <Grid
                      container
                      className={classes.root}
                      sx={{ bgcolor: "#E3D5CA" }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          className={classes.markersTitle}
                          class="h2b"
                          align="center"
                          color="primary"
                          gutterBottom
                          variant="h2"
                        >
                          2. Design Thinking
                        </Typography>
                        <Typography class="h6" variant="h6">
                          Going beyond convention to find answers
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            top: "2rem",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div class="slider-wrap1">
                            {design.map((d) => (
                              <div class="design-slide" id={d.id} />
                            ))}
                            {/* <div class="design-slide" id="design-1" />
                            <div class="design-slide" id="design-2" />
                            <div class="design-slide" id="design-3" />
                            <div class="design-slide" id="design-4" /> */}
                          </div>
                          <Box
                            style={{
                              backgroundColor: "#263657",
                              height: "1.2rem",
                              width: "450px",
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* description */}
                  <Grid item xs={12}>
                    <Box className={classes.description}>
                      <Typography component="p" className={classes.subDesc}>
                        At LWS, learning occurs in myriad ways. We encourage the
                        implementation of Design Thinking – a methodology in
                        which learning and creativity intersect. Presented with
                        a real-world problem, our young leaders are given the
                        opportunity to work together and solve it. This
                        collaborative approach to learning sparks ideas,
                        conversations, debates, leading to a new and
                        unconventional solution instead of the tried and tested.
                      </Typography>
                      <Typography className={classes.h3bText}>
                        Inculcating Life Skills
                      </Typography>
                      <br />

                      <Typography component="p" className={classes.subDesc}>
                        It’s not about being right or wrong.
                        <br />
                        <br />
                        It’s about honing life skills like creativity, critical
                        thinking and problem solving – skills that are integral
                        for our leaders of tomorrow.
                      </Typography>
                    </Box>
                  </Grid>
                </>

                {/* 3. Environmental Consciousness */}
                <>
                  <Grid item xs={12} className={classes.section}>
                    <Grid
                      container
                      className={classes.root}
                      sx={{ bgcolor: "#E3D5CA" }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          className={classes.markersTitle}
                          class="h2b"
                          align="center"
                          color="primary"
                          gutterBottom
                          variant="h2"
                        >
                          3. Environmental Consciousness
                        </Typography>
                        <Typography class="h6" variant="h6">
                          Protecting and nurturing our environment
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            top: "2rem",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div class="slider-wrap1">
                            {environment.map((e) => (
                              <div class="design-slide" id={e.id} />
                            ))}
                            {/* <div class="design-slide" id="environment-1" />
                            <div class="design-slide" id="environment-2" />
                            <div class="design-slide" id="environment-3" />
                            <div class="design-slide" id="environment-4" /> */}
                          </div>
                          <Box
                            style={{
                              backgroundColor: "#263657",
                              height: "1.2rem",
                              width: "450px",
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* description */}
                  <Grid item xs={12} style={{}}>
                    <Box className={classes.description}>
                      <Typography component="p" className={classes.subDesc}>
                        We aspire for a better tomorrow in every aspect. When it
                        comes to protecting and nurturing the environment, we
                        strive to lead by example.
                      </Typography>

                      <Typography className={classes.h3bText}>
                        Green School Policy
                      </Typography>

                      <Typography component="p" className={classes.subDesc}>
                        Environmental Consciousness is not just another
                        initiative; it is embedded throughout our way of life.
                        Every activity and event in our schools promotes
                        environment-friendliness.
                        <List component="div">
                          <Box> &#8226; &nbsp; Green Drives</Box>
                          <Box> &#8226; &nbsp; Cleanliness Campaigns</Box>
                          <Box> &#8226; &nbsp; Planting more flora</Box>
                          <Box> &#8226; &nbsp; Eco events</Box>
                          <Box>
                            {" "}
                            &#8226; &nbsp; Projects/Initiatives beyond the
                            school
                          </Box>
                        </List>
                      </Typography>

                      <Typography className={classes.h3bText}>
                        Zero Plastic School
                      </Typography>

                      <Typography component="p" className={classes.subDesc}>
                        We aim to become a Zero Plastic School. We educate,
                        inspire, and involve everyone in this cause.
                      </Typography>
                    </Box>
                  </Grid>
                </>

                {/* 4. Emotional Wellness */}
                <>
                  <Grid item xs={12} className={classes.section}>
                    <Grid
                      container
                      className={classes.root}
                      sx={{ bgcolor: "#E3D5CA" }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          className={classes.markersTitle}
                          class="h2b"
                          align="center"
                          color="primary"
                          gutterBottom
                          variant="h2"
                        >
                          4. Emotional Wellness
                        </Typography>
                        <Typography class="h6" variant="h6">
                          Looking inward and reaching out
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            top: "2rem",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div class="slider-wrap1">
                            {wellness.map((w) => (
                              <div class="design-slide" id={w.id} />
                            ))}
                            {/* <div class="design-slide" id="wellness-1" />
                            <div class="design-slide" id="wellness-2" />
                            <div class="design-slide" id="wellness-3" />
                            <div class="design-slide" id="wellness-4" /> */}
                          </div>
                          <Box
                            style={{
                              backgroundColor: "#263657",
                              height: "1.2rem",
                              width: "450px",
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* description */}
                  <Grid item xs={12}>
                    <Box className={classes.description1}>
                      <Typography component="p" className={classes.subDesc}>
                        We train our young leaders for life beyond the
                        classroom. Emotional quotient, therefore, plays a strong
                        part in all we do, along with intelligence quotient.
                      </Typography>
                      <Typography className={classes.h3bText}>
                        Vipasyana
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        We are in touch with the inside. Through the Vipasyana
                        program, our children connect to their mind and soul.
                      </Typography>

                      <Typography className={classes.h3bText}>
                        Community Outreach
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        We are in touch with the outside. LWS gives back to the
                        community. Our community outreach programs allow our
                        students a peek into another’s world, encouraging them
                        to reach out and contribute in their own way. Serving
                        others becomes second nature.
                      </Typography>

                      <Typography className={classes.h3bText}>
                        Shramadaan
                      </Typography>
                      <Typography component="p" className={classes.subDesc}>
                        We take nothing for granted. Shramadaan develops among
                        our children a newfound appreciation for their
                        belongings and resources – they learn to value and
                        preserve them.
                      </Typography>
                    </Box>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {scrollPosition < 0 ? (
        <BackToStart goToTarget={scrollToBackTarget} />
      ) : null}
    </>
  );
}
