import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Grid, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Contact from "../components/Contact";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import BackToStart from "../components/BackToStart";
import usePathParameter from "../utils/usePathParameter";
import {
  peopleThane,
  personSchemaThane,
} from "../data/ThaneIndividualSiteData";
import {
  peopleMain,
  personSchemaMain,
  pageSettingsMain,
} from "../data/MainSiteData";
import {
  peopleCbgPalava,
  personSchemaCbgPalava,
} from "../data/CbgPalavaIndividualSiteData";
import {
  peopleLsgPalava,
  personSchemaLsgPalava,
} from "../data/LsgPalavaIndividualSiteData";
import {
  peopleTaloja,
  personSchemaTaloja,
} from "../data/TalojaIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "100vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  mainImg: {
    width: "311px",
    textAlign: "center",
    margin: "0 3rem",
    [theme.breakpoints.down("md")]: {
      margin: "3rem 0 0 0",
    },
  },
  mainImga: {
    width: "311px",
    textAlign: "center",
    margin: "4rem 3rem 0 3rem",
    [theme.breakpoints.down("md")]: {
      margin: "3rem 0 0 0",
    },
  },
  img: {
    border: "1px solid #263657",
  },
  mainContainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
    },
  },
  title: {
    padding: "3rem 4rem 2rem 4rem",
    [theme.breakpoints.down("md")]: {
      padding: "0 2rem",
    },
  },
}));

export default function People() {
  const classes = useStyles();
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const leftMenuRef = useRef(null);
  const rightSectionRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [people, setPeople] = useState([]);
  const [jsonLd, setJsonLd] = useState(null);

  useEffect(() => {
    if (isThane) {
      setPeople(peopleThane);
      setJsonLd(personSchemaThane);
    } else if (isCbgPalava) {
      setPeople(peopleCbgPalava);
      setJsonLd(personSchemaCbgPalava);
    } else if (isLsgPalava) {
      setPeople(peopleLsgPalava);
      setJsonLd(personSchemaLsgPalava);
    } else if (isTaloja) {
      setPeople(peopleTaloja);
      setJsonLd(personSchemaTaloja);
    } else if (!isIndividual) {
      setPeople(peopleMain);
      setJsonLd(personSchemaMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  const scrollToBackTarget = () => {
    leftMenuRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const position = rightSectionRef.current?.getBoundingClientRect().x;
    setScrollPosition(position);
  };

  return (
    <>
      <Helmet>
        <title>{pageSettingsMain.people.title}</title>
        {pageSettingsMain.people.metaTags.map((tag) => {
          if (tag.name) {
            return (
              <meta name={tag.name} content={tag.content} key={tag.name} />
            );
          } else if (tag.property) {
            return (
              <meta
                property={tag.property}
                content={tag.content}
                key={tag.property}
              />
            );
          }
          return null;
        })}
        <link rel="canonical" href={pageSettingsMain.people.canonical} />
        {jsonLd && (
          <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
        )}
      </Helmet>
      <Contact />
      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu breadCrumbTarget="People" />
            <Grid ref={rightSectionRef} item xs={12} md={9}>
              <Grid item container xs={12}>
                <Grid item xs={12} className={classes.title}>
                  <Typography
                    class="h2b"
                    align="center"
                    color="primary"
                    gutterBottom
                    variant="h1"
                  >
                    People
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  spacing={1}
                  className={classes.mainContainer}
                >
                  {people.map((person, index) => (
                    <>
                      <Grid
                        item
                        xs={12}
                        md={5}
                        className={
                          index % 2 === 0 ? classes.mainImg : classes.mainImga
                        }
                      >
                        <img
                          src={person.imageSource}
                          width="300px"
                          alt={person.imageAlt}
                          title={person.imgTitle}
                          class="responsiveImg"
                          className={classes.img}
                        />
                        <Typography
                          class="h2"
                          align="center"
                          color="primary"
                          gutterBottom
                          variant="h3"
                          style={{ marginBottom: "6px" }}
                        >
                          {person.name}
                        </Typography>
                        <Typography style={{ fontSize: "14px" }} class="h1">
                          {person.title}
                        </Typography>
                      </Grid>
                    </>
                  ))}
                  <Grid item xs={12} md={5}></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {scrollPosition < 0 ? (
        <BackToStart goToTarget={scrollToBackTarget} />
      ) : null}
    </>
  );
}
