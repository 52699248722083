import React, { useState } from "react";
import { Helmet } from "react-helmet";
import clsx from "clsx";
import {
  Grid,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Contact from "../components/Contact";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import usePathParameter from "../utils/usePathParameter";
import { storyMain, pageSettingsMain } from "../data/MainSiteData";
import { storyCbgPalava } from "../data/CbgPalavaIndividualSiteData";
import { storyLsgPalava } from "../data/LsgPalavaIndividualSiteData";
import { storyTaloja } from "../data/TalojaIndividualSiteData";
import { storyThane } from "../data/ThaneIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    //flexDirection: 'row', width: '100vw', height: '100vh',
    width: "100vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  textBox: {
    // padding: '2rem', backgroundColor: '#263657', borderRadius: '100px',
    // textAlign: 'center',

    fontFamily: "Bodoni BE",
    borderRadius: "50%",
    width: "125px",
    height: "125px",
    padding: "10px",
    backgroundColor: "#fff",
    color: "#263657",
    border: "1px solid #263657",
    "&:hover": {
      background: "#263657",
      border: "1px solid #263657",
      color: "#E3D5CA",
    },
    [theme.breakpoints.down("md")]: {
      width: "100px",
      height: "100px",
    },
  },
  active: {
    background: "#263657",
    border: "1px solid #263657",
    color: "#E3D5CA",
  },
  hide: {
    display: "none",
  },
  subText: {
    width: "90vh",
    fontSize: "15px",
    fontWeight: 600,
    textAlign: "justify",
    paddingTop: "1rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  subText1: {
    fontSize: "18px",
    fontWeight: 600,
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  textBlock: {
    [theme.breakpoints.down("md")]: {
      padding: "1rem",
    },
  },
  rightSection: {
    marginTop: "5rem",
    [theme.breakpoints.down("md")]: {
      margin: "1rem",
    },
  },
  btnMain: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
    },
  },
  descMain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "2rem",
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
  descMain1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginLeft: "7rem",
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
      marginLeft: 0,
    },
  },
  marginMain: {
    margin: "1rem",
    [theme.breakpoints.down("md")]: {
      margin: "0.2rem",
    },
  },
  timeline: {
    margin: "2rem 0",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
}));

export default function OurStory() {
  const [btnShow, setBtnShow] = useState("presentText");
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [story, setStory] = useState([]);

  React.useEffect(() => {
    if (isThane) {
      setStory(storyThane);
    } else if (isCbgPalava) {
      setStory(storyCbgPalava);
    } else if (isLsgPalava) {
      setStory(storyLsgPalava);
    } else if (isTaloja) {
      setStory(storyTaloja);
    } else if (!isIndividual) {
      setStory(storyMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  const btns = [
    {
      name: "Where we were",
      button: "pastText",
    },
    {
      name: "Where we are",
      button: "presentText",
    },
    {
      name: "Where we will be",
      button: "futureText",
    },
  ];

  const handleBtnChange = (text) => {
    setBtnShow(text);
  };

  if (story.length === 0) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{pageSettingsMain.ourStory.title}</title>
        {pageSettingsMain.ourStory.metaTags.map((tag) => {
          if (tag.name) {
            return (
              <meta name={tag.name} content={tag.content} key={tag.name} />
            );
          } else if (tag.property) {
            return (
              <meta
                property={tag.property}
                content={tag.content}
                key={tag.property}
              />
            );
          }
          return null;
        })}
        <link rel="canonical" href={pageSettingsMain.ourStory.canonical} />
      </Helmet>
      <Contact />
      <Box className={classes.outerWrapper}>
        <Box className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu breadCrumbTarget="Our Story" />
            <Grid item xs={12} md={9} className={classes.rightSection}>
              {isIndividual ? (
                <Grid container>
                  <Grid item xs={12} md={12} className={classes.descMain1}>
                    <Typography class="h2b" variant="h1">
                      Our Story
                    </Typography>

                    <Box className={classes.subText}>
                      {story.map((s) => (
                        <Typography component="p" className={classes.subText1}>
                          {s.text}
                          <br />
                          <br />
                        </Typography>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Typography
                    class="h2b"
                    variant="h1"
                    style={{ lineHeight: "normal", marginLeft: "5rem" }}
                  >
                    Lodha World School Journey
                  </Typography>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        margin: "1rem 0",
                      }}
                    >
                      <Box className={classes.btnMain}>
                        {btns.map((btn) => (
                          <Box className={classes.marginMain}>
                            <IconButton
                              onClick={() => handleBtnChange(btn.button)}
                              size="small"
                              className={clsx(
                                classes.textBox,
                                btnShow === btn.button ? classes.active : ""
                              )}
                            >
                              {btn.name}
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={9} className={classes.descMain}>
                      {/* where we were */}
                      <Box
                        className={clsx(
                          btnShow === "pastText" ? classes.show : classes.hide,
                          classes.textBlock
                        )}
                      >
                        <Typography
                          class="h2b"
                          align="center"
                          color="primary"
                          gutterBottom
                          variant="caption"
                        >
                          Where we were
                        </Typography>
                        <Typography component="p" className={classes.subText}>
                          The first step to moulding the ‘Leaders of Tomorrow’
                          is educating them. A world-class education that
                          empowers them.
                          <br />
                          <br />
                          This is the premise Lodha World School was founded on.
                          <br />
                          <br />
                          Under the aegis of the Sitaben Shah Memorial Trust,
                          our journey towards academic excellence began in June
                          2008 in the heart of Thane. We started with 24 eager
                          learners – big enough to kickstart an education
                          movement with, small enough to be called family. While
                          we grew and explored unfamiliar paths over the years,
                          we stuck to our roots – carrying along that warmth and
                          sense of belonging unique to Lodha World School.
                        </Typography>
                      </Box>

                      {/* where we are */}
                      <Box
                        className={clsx(
                          btnShow === "presentText"
                            ? classes.show
                            : classes.hide,
                          classes.textBlock
                        )}
                      >
                        <Typography
                          class="h2b"
                          align="center"
                          color="primary"
                          gutterBottom
                          variant="caption"
                        >
                          Where we are
                        </Typography>
                        <Typography component="p" className={classes.subText}>
                          We have come a long way from 2008. Today, the Lodha
                          World School community boasts of 4 campuses and 3500+
                          pupils. We are affiliated to the ICSE, CBSE, SSC and
                          ISC boards across our schools.
                          <br />
                          <br />
                          With growth came diversity - reflected in our
                          ever-growing community and in the ways we adapted. Be
                          it the syllabus, curriculum, or co-curricular
                          activities, each component is carefully curated to
                          meet the varying needs of our community in an equal
                          and fulfilling manner.
                        </Typography>
                        <Box className={classes.timeline}>
                          <img
                            src={
                              matchesSM
                                ? "/assets/timelinemobile1.svg"
                                : "/assets/timeline.svg"
                            }
                            alt=""
                          />
                        </Box>
                      </Box>

                      {/* where we will be */}
                      <Box
                        className={clsx(
                          btnShow === "futureText"
                            ? classes.show
                            : classes.hide,
                          classes.textBlock
                        )}
                      >
                        <Typography
                          class="h2b"
                          align="center"
                          color="primary"
                          gutterBottom
                          variant="caption"
                        >
                          Where we will be
                        </Typography>
                        <Typography component="p" className={classes.subText}>
                          We began with a small number and a big dream - to
                          spread our wings in multiple geographies, impacting
                          and inspiring a larger pupil population.
                          <br />
                          <br />
                          LWS is slowly growing into a chain of several quality
                          schools. As we get bigger, we strive to be a force to
                          reckon with within education and a brand with deep
                          roots.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
