import * as minio from "minio";
const mc = new minio.Client({
  endPoint: "storage.eduailytics.com",
  useSSL: true,
  accessKey: "w4H69rMbVUQUozOERhKI",
  secretKey: "Kb9sh9HydYFDn9jxrIjOYKcCCWymdszjGNOn7rD6",
});

export const openFileInNewTab = (fileName) => {
  let pdfOpened = false;
  let streamChunksBuf = [];
  let pdfFile = new Blob([new Buffer([])], {
    type: "application/pdf",
  });

  let fileURL = URL.createObjectURL(pdfFile);
  mc.getObject(
    "eduailytics",
    `website/${fileName}`,
    function (err, dataStream) {
      if (err) {
        console.log("err", err);
      }
      let pdfWindow = window.open();
      pdfWindow.document.write("<h1>Loading...</h1>");
      pdfOpened = true;
      dataStream.on("data", function (chunk) {
        console.log("in data");
        streamChunksBuf.push(Buffer.from(chunk));
        if (pdfWindow && !pdfOpened) {
          pdfWindow.location.href = fileURL;
        }
      });
      dataStream.on("end", function () {
        console.log("in end");
        pdfFile = new Blob([Buffer.concat(streamChunksBuf)], {
          type: "application/pdf",
        });
        fileURL = URL.createObjectURL(pdfFile);
        if (pdfWindow) {
          URL.revokeObjectURL(pdfWindow.location.href);
          pdfWindow.location.href = fileURL;
        }
      });
      dataStream.on("error", function (err) {
        console.log(err);
      });
    }
  );
};

export default mc;
