import React from "react";
import { Box, Button, Link, } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "../App.css";

const useStyles = makeStyles((theme) => ({
    Link: {
        textDecoration: "none",
      },
      mainCta: {
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
}));

export default function AdmissionsTicker() {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.mainCta} style={{ marginRight: "2rem" }}>
              <Link
                href={`https://applyonline.lodhaworldschool.com${
                  localStorage.getItem("queryString") ?? ""
                }`}
                target="_blank"
                rel="noreferrer"
                className={classes.Link}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  disableElevation
                  sx={{ mr: 2 }}
                  className="flash-button"
                >
                  Admissions Open for AY 2025-26
                </Button>
              </Link>
              {/* <Button
                color="secondary"
                variant="contained"
                disableElevation
                sx={{ mr: 2 }}
              >
                Fee Payment
              </Button> */}
            </Box>
    </>
  );
}
