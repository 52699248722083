import React, {useState, useRef} from "react";
import { Grid, Box, AppBar, Toolbar, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import Contact from '../components/Contact';
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import BackToStart from "../components/BackToStart";


const useStyles = makeStyles(theme => ({
  outerWrapper: {
    width: '100vh', height: '100vw', position: 'absolute', transform: 'rotate(-90deg) translateX(-100vh)',
    transformOrigin: 'top left', overflowY: 'scroll', overflowX: 'hidden', scrollbarWidth: 'none',
    [theme.breakpoints.down('md')]: {
      transform: 'none', transformOrigin: 'unset', height: '100%', position: 'initial', width: 'auto',
    },
  },
  wrapper: {
    display: 'flex', alignItems: 'center',
    width: '100vw', 
    transform: 'rotate(90deg) translateY(-100vh)',
    transformOrigin: 'top left',
    [theme.breakpoints.down('md')]: {
      transform: 'none', transformOrigin: 'unset',
      flexDirection: 'column', width: '100vw'
    },
  },
  main: {
    width: '100vw', height: '100vh',
  },
}));

export default function Student() {
  const classes = useStyles();
  const leftMenuRef = useRef(null);
  const rightSectionRef = useRef(null); 
  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollToBackTarget = () => {
    leftMenuRef.current.scrollIntoView({
      behavior: "smooth"
    })
  }

  const handleScroll = () => {
    const position = rightSectionRef.current?.getBoundingClientRect().x;
    setScrollPosition(position);
  };

  return (
    <>
      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
        <Grid container className={classes.main}>
          <SubPagesLeftMenu breadCrumbTarget='Student' />
          <Grid ref={rightSectionRef} item xs={12} md={9} className={classes.rightSection}>
            <AppBar position="static" elevation={0}  sx={{ bgcolor: '#fff'}}>
              <Toolbar>
                <Box sx={{ flexGrow: 1 }} />
                <Contact />  
              </Toolbar>
            </AppBar>

            <Grid container style={{height: '91vh', marginLeft: '4rem'}}>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'left', justifyContent: 'left'}}>
              {scrollPosition<0 ? <BackToStart goToTarget={scrollToBackTarget}/> : null}
                <Typography
                    class="h2b"
                    align="center"
                    color="primary"
                    gutterBottom
                    variant='h1'
                  >
                    Student
                  </Typography>
                
              </Grid>

              <Grid item xs={12} md={9} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>              
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </Box>
      </Box>
    </>
  );
}