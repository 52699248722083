import React from "react";
import {
  Grid,
  Box,
  Typography,
  Breadcrumbs,
  Link,
  AppBar,
  Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavMenu from "../components/NavMenu";
import LogoMedia from "../components/LogoMedia";
import usePathParameter from "../utils/usePathParameter";

const useStyles = makeStyles((theme) => ({
  leftSection: {
    backgroundColor: "#E3D5CA",
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      backgroundColor: "transparent",
      height: "auto",
    },
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
  },
  media: {
    display: "flex",
    margin: "0.5rem 3rem",
    justifyContent: "space-evenly",
  },
  appbar: {
    backgroundColor: "#E3D5CA",
    position: "static",
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      zIndex: "9",
    },
  },
  logoMedia: {
    height: "calc(100vh - 160px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  breadcrumbs: {
    margin: "2rem",
    [theme.breakpoints.down("md")]: {
      margin: "5rem 1rem 1rem 1rem",
    },
  },
}));

export default function SubPagesLeftMenu({ breadCrumbTarget }) {
  const classes = useStyles();
  const { isIndividual, pathUrl } = usePathParameter();

  return (
    <>
      <Grid item xs={12} md={3} className={classes.leftSection}>
        <AppBar elevation={0} className={classes.appbar}>
          <Toolbar>
            <Box sx={{ margin: "0 5px" }}>
              <NavMenu />
              {/* <Search /> */}
            </Box>
          </Toolbar>
        </AppBar>

        <Box className={classes.breadcrumbs}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="primary"
              href={isIndividual ? `${pathUrl}` : "/"}
            >
              Home
            </Link>
            <Typography color="primary">{breadCrumbTarget}</Typography>
          </Breadcrumbs>
        </Box>
        <LogoMedia />
      </Grid>
    </>
  );
}
