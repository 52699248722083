import { createTheme } from "@mui/material";

const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        disableRipple: true,
        disableTouchRipple: true,
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      }
    },
  
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '14px',
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontStyle: "normal",
          fontFamily: "lato",
          fontSize: "16px",
          lineHeight: "19px",
          alignItems: "center",
          textAlign: "center",
          margin: "5px",
          fontWeight: 500,
        },
        containedSecondary: {
          borderRadius: "0px",
          padding: "14px 20px",
          border: "1px solid #E3D5CA",
          color: "#263657",
          '&:hover': {
            background: "#263657",
            color: "#E3D5CA",
          },
        },
        containedPrimary: {
          borderRadius: "0px",
          padding: "14px 20px",
          border: "1px solid #263657",
          color: "#E3D5CA",
          '&:hover': {
            background: "#E3D5CA",
            color: "#263657",
            border: "1px solid #263657",
          },
        },
        outlinedPrimary: {
          borderRadius: "0px",
          padding: "14px 20px",
          border: "1px solid",
          '&:hover': {
            background: "#263657",
            color: "#E3D5CA",
          },
        }
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
        },
      }},

    MuiTypography: {
      root: {
        textTransform: "capitalize",
        color: "#263657"
      },
      h1: {}
    },
  },

  typography: {
    fontFamily: 'Lato, sans-serif'
  },

  palette: {
    primary: {
      main: "#263657"
    },
    secondary: {
      main: "#E3D5CA"
    },
    black: {
      main: "#000"
    }
  }
});

export default theme;