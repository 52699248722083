import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

const withFacebookPixel = (WrappedComponent) => {
  return () => {
    const location = useLocation();

    useEffect(() => {
      ReactPixel.pageView();
    }, [location.pathname]);

    return <WrappedComponent />;
  };
};

export default withFacebookPixel;
