import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
  DialogContent,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import usePathParameter from "../utils/usePathParameter";
import { joinLwsThane } from "../data/ThaneIndividualSiteData";
import { joinLwsMain } from "../data/MainSiteData";
import { joinLwsCbgPalava } from "../data/CbgPalavaIndividualSiteData";
import { joinLwsLsgPalava } from "../data/LsgPalavaIndividualSiteData";
import { joinLwsTaloja } from "../data/TalojaIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    textAlign: "center",
    height: "100vh",
  },
  textBox: {
    position: "relative",
    padding: "3rem 2rem",
    left: "-5rem",
    backgroundColor: "#E3D5CA",
    [theme.breakpoints.down("md")]: {
      position: "initial",
    },
  },
  fourSchoolImg: {
    width: "250px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

export default function JoinLws() {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);

  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
  usePathParameter();
  const [joinLwsContent, setJoinLwsContent] = useState([]);

  const styles = {
    JoinLws: {
      // backgroundImage: `url(${JoinLwsBg})`,
      // backgroundPosition: 'left center',
      // backgroundRepeat: 'no-repeat',
      // backgroundSize: 'contain',
      // height: '102vh',
      marginBottom: "auto",
    },
    StudentsImg: {
      // backgroundImage: `url(/assets/students.jpg)`,
      backgroundImage: `url(${joinLwsContent?.imageSource})`,
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: "100vh",
      marginBottom: "auto",
    },
  };

useEffect(() => {
  if (isThane) {
    setJoinLwsContent(joinLwsThane);
  } else if (isCbgPalava) {
    setJoinLwsContent(joinLwsCbgPalava);
  } else if (isLsgPalava) {
    setJoinLwsContent(joinLwsLsgPalava);
  } else if (isTaloja) {
    setJoinLwsContent(joinLwsTaloja);
  } else if (!isIndividual) {
    setJoinLwsContent(joinLwsMain);
  }
}, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Schools = [
    {
      img: "/assets/thane.jpg",
      name: "Thane",
      established: "Established in 2008",
      class: "Nursery - Class 10",
      board: "Board: ICSE",
      cta: "Thane",
      ctalink: "https://www.lodhaworldschool.com/thane",
    },
    {
      img: "/assets/cbgPalava.png",
      name: "CBG, Palava",
      established: "Established in 2013",
      class: "Nursery - Class 12",
      board: "Board: ICSE & ISC",
      cta: "CBG Palava",
      ctalink: "https://www.lodhaworldschool.com/palava/",
    },
    {
      img: "/assets/lsgPalava.jpg",
      name: "LSG, Palava",
      established: "Established in 2019",
      class: "Nursery - Class 12",
      board: "Board: CBSE",
      cta: "LSG Palava",
      ctalink: "https://www.lodhaworldschool.com/lsgpalava/",
    },
    {
      img: "/assets/taloja.jpg",
      name: "Taloja",
      established: "Established in 2021",
      class: "Nursery - Class 8",
      board: "Board: SSC",
      cta: "Taloja",
      ctalink: "https://www.lodhaworldschool.com/taloja/",
    },
  ];

  return (
    <>
      <main style={styles.JoinLws}>
        <Box
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Container maxWidth="lg">
            <Grid container className={classes.root}>
              <Grid item xs={12} md={9} style={styles.StudentsImg} />
              {/* <img src={Students} alt="" width="722px" class="responsiveImg" />
              </Grid> */}
              <Grid
                item
                xs={12}
                md={3}
                onClick={handleClickOpen}
                className={classes.textBox}
              >
                <Link href={!isIndividual? null : "https://applyonline.lodhaworldschool.com/"} rel="noreferrer" target="_blank" style={{textDecoration: "none"}}>
                <Typography
                  style={{
                    lineHeight: "40px",
                    fontSize: "32px",
                    cursor: "pointer",
                    border: "1px solid #263657",
                  }}
                  class="h2"
                  align="center"
                  color="primary"
                  gutterBottom
                  variant="h2"
                >
                  {joinLwsContent?.title}
                </Typography>
                </Link>
              </Grid>
            </Grid>
          </Container>
        </Box>
        {!isIndividual ?
          (<Dialog
            PaperProps={{
              style: {
                backgroundColor: "#E3D5CA",
              },
            }}
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            maxWidth="lg"
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              <Typography
                class="h2b"
                align="center"
                color="primary"
                gutterBottom
                variant="h1"
              >
                <i>Our Schools</i>
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.primary.main,
                  border: "1px solid #263657",
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid container sx={{ mb: 3 }} spacing={2}>
                {Schools.map((school, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Grid container style={{ textAlign: "left" }}>
                      <Grid item xs={12} md={6}>
                        <img
                          src={school.img}
                          alt=""
                          className={classes.fourSchoolImg}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          class="h2"
                          color="primary"
                          variant="h1"
                          gutterBottom
                        >
                          {school.name}
                        </Typography>
                        <Box sx={{ color: (theme) => theme.palette.black.main }}>
                          <b>
                            {school.established} <br />
                            {school.class} <br />
                            {school.board} <br />
                          </b>
                        </Box>
                        <Link
                          href={school.ctalink}
                          target="_blank"
                          style={{ TextDecoration: "none" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ margin: "1rem 0" }}
                            size="small"
                            fullWidth
                            disableElevation
                          >
                            {school.cta}
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
          </Dialog>) : null
        }
      </main>
    </>
  );
}
