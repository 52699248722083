import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LandingPageMain from "./LandingPageMain";
import ChairpersonMsg from "./components/ChairpersonMsg";
import LwsMarkers from "./components/LwsMarkers";
import SeniorYears from "./components/SeniorYears";
import Academics from "./components/Academics";
import InspireUsCurriculum from "./components/InspireUsCurriculum";
import JoinLws from "./components/JoinLws";
import BackToStart from "./components/BackToStart";
import Contact from "./components/Contact";
import NavMenu from "./components/NavMenu";
import usePathParameter from "./utils/usePathParameter";
import LandingPageThane from "./LandingPageThane";
import LandingPageTaloja from "./LandingPageTaloja";
import LandingPageLsgPalava from "./LandingPageLsgPalava";
import LandingPageCbgPalava from "./LandingPageCbgPalava";
import {
  websiteSchemaThane,
  pageSettingsThane,
} from "./data/ThaneIndividualSiteData";
import { websiteSchemaMain, pageSettingsMain } from "./data/MainSiteData";
import {
  websiteSchemaCbgPalava,
  pageSettingsCbgPalava,
} from "./data/CbgPalavaIndividualSiteData";
import {
  websiteSchemaLsgPalava,
  pageSettingsLsgPalava,
} from "./data/LsgPalavaIndividualSiteData";
import {
  websiteSchemaTaloja,
  pageSettingsTaloja,
} from "./data/TalojaIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapperBig: {
    display: "flex",
    alignItems: "center",
    height: "100vh",
    width: "600vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  wrapperSmall: {
    display: "flex",
    alignItems: "center",
    height: "100vh",
    width: "500vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
}));

export default function Home() {
  const classes = useStyles();
  const landingPageRef = useRef(null);
  const chairpersonMsgRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [jsonLd, setJsonLd] = useState(null);
  const [seoData, setSeoData] = useState({});
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (isIndividual) {
      if (isThane) {
        setJsonLd(websiteSchemaThane);
        setSeoData(pageSettingsThane.landingPage);
      } else if (isTaloja) {
        setJsonLd(websiteSchemaTaloja);
        setSeoData(pageSettingsTaloja.landingPage);
      } else if (isCbgPalava) {
        setJsonLd(websiteSchemaCbgPalava);
        setSeoData(pageSettingsCbgPalava.landingPage);
      } else if (isLsgPalava) {
        setJsonLd(websiteSchemaLsgPalava);
        setSeoData(pageSettingsLsgPalava.landingPage);
      }
    } else {
      setJsonLd(websiteSchemaMain);
      setSeoData(pageSettingsMain.landingPage);
    }
  }, [isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane]);

  const handleScroll = () => {
    const position = chairpersonMsgRef.current?.getBoundingClientRect().x;
    setScrollPosition(position);
  };

  const scrollToBackTarget = () => {
    landingPageRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  const scrollToNextTarget = () => {
    chairpersonMsgRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>
      <Helmet>
        <title>{seoData?.title}</title>
        {seoData?.metaTags?.map((tag) => {
          if (tag.name) {
            return (
              <meta name={tag.name} content={tag.content} key={tag.name} />
            );
          } else if (tag.property) {
            return (
              <meta
                property={tag.property}
                content={tag.content}
                key={tag.property}
              />
            );
          }
          return null;
        })}
        <link rel="canonical" href={seoData?.canonical} />
        {jsonLd && (
          <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
        )}
      </Helmet>
      <Contact />
      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box
          ref={landingPageRef}
          className={
            isTaloja || isLsgPalava ? classes.wrapperSmall : classes.wrapperBig
          }
        >
          {!isIndividual ? (
            <LandingPageMain
              goToNextTarget={scrollToNextTarget}
              className={classes.main}
            />
          ) : isThane ? (
            <LandingPageThane
              goToNextTarget={scrollToNextTarget}
              className={classes.main}
            />
          ) : isTaloja ? (
            <LandingPageTaloja
              goToNextTarget={scrollToNextTarget}
              className={classes.main}
            />
          ) : isLsgPalava ? (
            <LandingPageLsgPalava
              goToNextTarget={scrollToNextTarget}
              className={classes.main}
            />
          ) : isCbgPalava ? (
            <LandingPageCbgPalava
              goToNextTarget={scrollToNextTarget}
              className={classes.main}
            />
          ) : null}
          <ChairpersonMsg domRef={chairpersonMsgRef} className={classes.main} />
          {!isIndividual ? <LwsMarkers className={classes.main} /> : ""}

          <InspireUsCurriculum className={classes.main} />
          {!isIndividual ? null : <SeniorYears className={classes.main} />}
          {isTaloja || isLsgPalava ? null : (
            <Academics className={classes.main} />
          )}
          {/* <Academics className={classes.main} /> */}
          <JoinLws className={classes.main} />
        </Box>
      </Box>
      {scrollPosition < 0 ? (
        <Box style={{ position: "absolute", top: "1rem", left: "2rem" }}>
          <NavMenu onClick={handleClick} onMouseOver={handleClick} />
        </Box>
      ) : null}
      {scrollPosition < 0 ? (
        <BackToStart
          className={classes.main}
          goToTarget={scrollToBackTarget}
          style={{ bottom: "6rem" }}
        />
      ) : null}
    </>
  );
}
