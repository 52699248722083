import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Grid, Box, Typography, List } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Contact from "../components/Contact";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import BackToStart from "../components/BackToStart";
import usePathParameter from "../utils/usePathParameter";
// import Board from "../../public/assets/leadershipboard.png";
import {
  leadershipachievementsMain,
  leadershipMain,
  leadersMain,
  prefectMain,
  captainMain,
  headsMain,
  eventsMain,
  pageSettingsMain,
} from "../data/MainSiteData";
import {
  leadershipachievementsCbgPalava,
  leadershipCbgPalava,
  leadersCbgPalava,
  prefectCbgPalava,
  captainCbgPalava,
  headsCbgPalava,
  eventsCbgPalava,
} from "../data/CbgPalavaIndividualSiteData";
import {
  leadershipachievementsLsgPalava,
  leadershipLsgPalava,
  leadersLsgPalava,
  prefectLsgPalava,
  captainLsgPalava,
  headsLsgPalava,
  eventsLsgPalava,
} from "../data/LsgPalavaIndividualSiteData";
import {
  leadershipachievementsTaloja,
  leadershipTaloja,
  leadersTaloja,
  prefectTaloja,
  captainTaloja,
  headsTaloja,
  eventsTaloja,
} from "../data/TalojaIndividualSiteData";
import {
  leadershipachievementsThane,
  leadershipThane,
  leadersThane,
  prefectThane,
  captainThane,
  headsThane,
  eventsThane,
} from "../data/ThaneIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "150vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  root: {
    alignItems: "center",
    // "@media (min-width:600px)": {
    //   maxWidth: '750px'
    // }
  },
  textBox: {
    position: "relative",
    left: "-12rem",
    padding: "2rem 1rem",
    boxShadow: "0px 4px 31px rgba(37, 54, 87, 0.5)",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("md")]: {
      position: "initial",
    },
  },
  nextBtn: {
    position: "relative",
    left: "30rem",
    top: "-3rem",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    borderRadius: "50%",
    width: "90px",
    height: "90px",
    fontSize: "13px",
    border: "1px solid #263657",
    backgroundColor: "#263657",
    color: "#fff",
    "&:hover": {
      background: "#263657",
      border: "1px solid #263657",
      color: "#fff",
    },
  },
  containerMain: {
    height: "92vh",
    marginLeft: "7rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: "100vh",
      flexDirection: "row",
      marginLeft: 0,
    },
  },
  subText: {
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "initial",
    [theme.breakpoints.down("md")]: {
      width: "auto",
      fontSize: "initial",
      padding: 0,
      textAlign: "initial",
    },
  },
  subText1: {
    width: "26vw",
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "initial",
    [theme.breakpoints.down("md")]: {
      width: "auto",
      fontSize: "initial",
      padding: 0,
      textAlign: "initial",
    },
  },
  mainPara: {
    width: "80%",
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "initial",
    [theme.breakpoints.down("md")]: {
      width: "100vh",
      fontSize: "initial",
      padding: "1rem",
    },
  },
  subTextMain: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    [theme.breakpoints.down("md")]: {
      padding: "1rem 0 0 0",
    },
  },
  h1Main: {
    // marginRight: "10rem",
    // width: "250vh",
    display: "flex",
    alignItems: "flex-start",
    height: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      width: "100vw",
      height: "auto",
      padding: "1rem",
    },
  },
  h2Main: {
    marginLeft: "3rem",
    width: "60vw",
    display: "flex",
    alignItems: "flex-start",
    height: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      width: "100vw",
      height: "auto",
      padding: "1rem",
    },
  },
  table: {
    display: "flex",
    // justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
}));

export default function LeadershipDevelopment() {
  const classes = useStyles();
  const leftMenuRef = useRef(null);
  const rightSectionRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const [achievements, setAchievements] = useState([]);
  const [leadership, setLeadership] = useState([]);
  const [leaders, setLeaders] = useState([]);
  const [prefect, setPrefect] = useState([]);
  const [captain, setCaptain] = useState([]);
  const [events, setEvents] = useState([]);
  const [heads, setHeads] = useState([]);

  React.useEffect(() => {
    if (isThane) {
      setAchievements(leadershipachievementsThane);
      setLeadership(leadershipThane);
      setLeaders(leadersThane);
      setPrefect(prefectThane);
      setCaptain(captainThane);
      setEvents(eventsThane);
      setHeads(headsThane);
    } else if (isCbgPalava) {
      setAchievements(leadershipachievementsCbgPalava);
      setLeadership(leadershipCbgPalava);
      setLeaders(leadersCbgPalava);
      setPrefect(prefectCbgPalava);
      setCaptain(captainCbgPalava);
      setHeads(headsCbgPalava);
      setEvents(eventsCbgPalava);
    } else if (isLsgPalava) {
      setAchievements(leadershipachievementsLsgPalava);
      setLeadership(leadershipLsgPalava);
      setLeaders(leadersLsgPalava);
      setPrefect(prefectLsgPalava);
      setCaptain(captainLsgPalava);
      setHeads(headsLsgPalava);
      setEvents(eventsLsgPalava);
    } else if (isTaloja) {
      setAchievements(leadershipachievementsTaloja);
      setLeadership(leadershipTaloja);
      setLeaders(leadersTaloja);
      setPrefect(prefectTaloja);
      setCaptain(captainTaloja);
      setHeads(headsTaloja);
      setEvents(eventsTaloja);
    } else if (!isIndividual) {
      setAchievements(leadershipachievementsMain);
      setLeadership(leadershipMain);
      setLeaders(leadersMain);
      setPrefect(prefectMain);
      setCaptain(captainMain);
      setHeads(headsMain);
      setEvents(eventsMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  const scrollToBackTarget = () => {
    leftMenuRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const position = rightSectionRef.current?.getBoundingClientRect().x;
    setScrollPosition(position);
  };

  useEffect(() => {
    const interval = setInterval(() => setCurrentSlide((prv) => prv + 1), 4500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (leadership.length === 0) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{pageSettingsMain.leadershipDevelopment.title}</title>
        {pageSettingsMain.leadershipDevelopment.metaTags.map((tag) => {
          if (tag.name) {
            return (
              <meta name={tag.name} content={tag.content} key={tag.name} />
            );
          } else if (tag.property) {
            return (
              <meta
                property={tag.property}
                content={tag.content}
                key={tag.property}
              />
            );
          }
          return null;
        })}
        <link
          rel="canonical"
          href={pageSettingsMain.leadershipDevelopment.canonical}
        />
      </Helmet>
      <Contact />
      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu breadCrumbTarget="Leadership Development" />
            <Grid
              ref={rightSectionRef}
              item
              xs={12}
              md={9}
              className={classes.rightSection}
            >
              <Grid container className={classes.containerMain}>
                {!isIndividual ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      width: "50vw",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      class="h2b"
                      variant="h1"
                      style={{ lineHeight: "normal" }}
                    >
                      Lodha World School - Leadership Development
                    </Typography>

                    <Box className={classes.subTextMain}>
                      <Typography component="p" className={classes.mainPara}>
                        Learning at LWS is not confined to the four walls of the
                        classroom. It is the essence of all we are and do.
                        <br />
                        <br />
                        We aim to mould and nurture all-round leaders,
                        facilitating their development through our
                        all-encompassing range of co-curricular activities and
                        opportunities. Through these, our young leaders are not
                        just encouraged to enhance their inherent leadership
                        qualities, but also discover new ones along the way.
                        <br />
                        <br />
                        Our beyond-the-classroom learning, therefore, provides
                        the essential skills as well as the platform for a young
                        leader to perform and flourish.
                      </Typography>
                    </Box>
                  </Grid>
                ) : (
                  ""
                )}

                {/* leadership development slide */}
                <>
                  <Box className={classes.h2Main}>
                    <Typography
                      class="h1"
                      // variant="h1"
                      style={{
                        marginBottom: "10px",
                        fontFamily: "Lato",
                        transitionDuration: "800ms",
                        fontWeight: "bold",
                        color: "#898EAC",
                        fontSize: "55px",
                      }}
                    >
                      {leadership[currentSlide % leadership.length].title}
                    </Typography>
                    <Grid container className={classes.root}>
                      <Grid item xs={12}>
                        <div class="slider-wrap-skill">
                          <div
                            className="single-slide"
                            style={{ transitionDuration: "800ms" }}
                            id={leadership[currentSlide % leadership.length].id}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </>

                {!isIndividual ? (
                  ""
                ) : (
                  <>
                    <Box className={classes.h1Main}>
                      <Grid container className={classes.root}>
                        {leaders.map((s) => (
                          <Grid item xs={12} className={classes.table}>
                            <div style={{ width: "50vw" }}>
                              <Typography class="h3b">
                                Student Council 2023-24
                              </Typography>
                              <Box style={{ marginRight: "12rem" }}>
                                <Typography
                                  component="p"
                                  className={classes.subText}
                                >
                                  <img
                                    src={s.imageSource}
                                    width="650px"
                                    alt=""
                                    class="responsiveImg"
                                    style={{ marginBottom: "1rem" }}
                                  />
                                  {prefect.map((p) => (
                                    <div>
                                      <span
                                        component="span"
                                        className={classes.subText}
                                        style={{
                                          marginBottom: "1rem",
                                          color: "#898EAC",
                                        }}
                                      >
                                        {p.position}
                                      </span>
                                      <span
                                        component="span"
                                        className={classes.subText}
                                        style={{ marginBottom: "1rem" }}
                                      >
                                        {p.name}
                                      </span>
                                    </div>
                                  ))}
                                </Typography>
                              </Box>
                            </div>

                            <div style={{ width: "30vw" }}>
                              <Typography class="h3b">{s.section1}</Typography>
                              <Box>
                                <Typography
                                  component="p"
                                  className={classes.subText1}
                                >
                                  <List component="div">
                                    {heads.map((p) => (
                                      <Box>
                                        {" "}
                                        &#8226; &nbsp;{" "}
                                        <>
                                          <span
                                            component="span"
                                            className={classes.subText}
                                            style={{
                                              marginBottom: "1rem",
                                              color: "#898EAC",
                                            }}
                                          >
                                            {p.position}
                                          </span>
                                          <span
                                            component="span"
                                            className={classes.subText}
                                            style={{ marginBottom: "1rem" }}
                                          >
                                            {p.name}
                                          </span>
                                        </>
                                      </Box>
                                    ))}
                                  </List>
                                </Typography>
                              </Box>
                            </div>

                            <div style={{ width: "30vw" }}>
                              <Typography class="h3b">{s.section2}</Typography>
                              <Box>
                                <Typography
                                  component="p"
                                  className={classes.subText}
                                >
                                  <List component="div">
                                    {captain.map((p) => (
                                      <Box>
                                        {" "}
                                        &#8226; &nbsp;{" "}
                                        <>
                                          <span
                                            component="span"
                                            className={classes.subText}
                                            style={{
                                              marginBottom: "1rem",
                                              color: "#898EAC",
                                            }}
                                          >
                                            {p.position}
                                          </span>
                                          <span
                                            component="span"
                                            className={classes.subText}
                                            style={{ marginBottom: "1rem" }}
                                          >
                                            {p.name}
                                          </span>
                                        </>
                                      </Box>
                                    ))}
                                  </List>
                                </Typography>
                              </Box>
                            </div>

                            {isLsgPalava && (
                              <div style={{ width: "30vw" }}>
                                <Typography class="h3b">
                                  {s.section3}
                                </Typography>
                                <Box>
                                  <Typography
                                    component="p"
                                    className={classes.subText}
                                  >
                                    <List component="div">
                                      {events.map((p) => (
                                        <Box>
                                          {" "}
                                          &#8226; &nbsp;{" "}
                                          <>
                                            <span
                                              component="span"
                                              className={classes.subText}
                                              style={{
                                                marginBottom: "1rem",
                                                color: "#898EAC",
                                              }}
                                            >
                                              {p.position}
                                            </span>
                                            <span
                                              component="span"
                                              className={classes.subText}
                                              style={{ marginBottom: "1rem" }}
                                            >
                                              {p.name}
                                            </span>
                                          </>
                                        </Box>
                                      ))}
                                    </List>
                                  </Typography>
                                </Box>
                              </div>
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </>
                )}

                {!isIndividual ? (
                  ""
                ) : (
                  <>
                    {achievements.map((e) => (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                          width: "40vw",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={e?.imageSource}
                          width="190px"
                          alt=""
                          class="responsiveImg"
                          className={classes.img}
                        />
                        <Typography
                          class="h2"
                          variant="h4"
                          style={{ margin: 0 }}
                        >
                          {e?.name}
                        </Typography>
                        <Typography
                          class="h2"
                          variant="h4"
                          style={{ margin: 0 }}
                        >
                          {e?.name1}
                        </Typography>
                        <Typography style={{ fontSize: "14px" }} class="h1">
                          {e?.title}
                        </Typography>
                        <Typography style={{ fontSize: "14px" }} class="h1">
                          {e?.title1}
                        </Typography>
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {scrollPosition < 0 ? (
        <BackToStart goToTarget={scrollToBackTarget} />
      ) : null}
    </>
  );
}
