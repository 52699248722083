import React, { useRef } from "react";
import {
  Grid,
  Box,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Button,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Contact from "../components/Contact";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { openFileInNewTab } from "../utils/minio";
import LogoSlider from "../components/LogoSlider";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "100vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  fourSchoolImg: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  rightSection: {
    [theme.breakpoints.down("md")]: {
      padding: "1rem",
    },
  },
  containerMain: {
    height: "100vh",
    marginLeft: "4rem",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },

  containerMain1: {
    height: "100vh",
    // marginLeft: "4rem",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      flexDirection: "row",
    },
  },
  subText: {
    width: "120vh",
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: "initial",
      paddingBottom: "10px",
    },
  },
  subText1: {
    width: "90vh",
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: "initial",
      paddingBottom: "10px",
    },
  },
  links: {
    margin: 0,
    padding: 0,
    color: "#263657",
    textDecoration: "none",
    textAlign: "inherit",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linksNotLinked: {
    margin: 0,
    padding: 0,
    color: "#263657",
    textDecoration: "none",
    pointerEvents: "none",
    textAlign: "inherit",
    textTransform: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
  },
  title1: {
    fontSize: "15px",
    lineHeight: 1.2,
  },
  altText: {
    overflowWrap: "anywhere",
    padding: "15px",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  openings: {
    // position: "fixed",
    marginLeft: "15%",
    marginTop: "6%",
    width: "50vw",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    paddingRight: "3rem",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
      paddingRight: 0,
      paddingBottom: "1rem",
      marginLeft: 0,
      marginTop: 0,
    },
  },
  h3bText: {
    textAlign: "initial",
    fontFamily: "Lato",
    fontWeight: "bold",
    color: "#898EAC",
    fontSize: "26px",
    marginTop: "18px",
    marginLeft: "0px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "1rem",
    },
  },
  subDesc: {
    fontSize: "15px",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "auto",
      padding: "1rem",
    },
  },
  logoMain: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-evenly",
      flexWrap: "nowrap",
    },
  },
  slideTrack: {
    width: "76%",
    display: "block",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  slider: {
    marginTop: "10px",
    backgroundColor: "white",
    // padding: "0 2em",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  RegisterBtn: {
    right: "5rem",
    zIndex: "999",
    position: "absolute",
    marginTop: "0.6rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "3px",
    },
  },
  pptSection: {
    width: "60vw",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    marginLeft: "20%",
    marginTop: "6%",
    [theme.breakpoints.down("md")]: {
      marginLeft: "1rem",
      marginTop: 0,
    },
  },
  stayText: {
    color: "#fff",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  carousel: {
    width: "52vw",
    padding: "0 2rem",
    [theme.breakpoints.down("md")]: {
      padding: 0,
      width: "78vw",
      margin: "auto 0",
    },
  },
  textBox: {
    // boxShadow: '0px 4px 31px rgba(37, 54, 87, 0.5)',
    backgroundColor: "#ffffff",
    borderRadius: "20px",
    border: "1px solid #263657",
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
      height: "100%",
      width: "70vw",
      padding: "2rem 0",
    },
  },
  subTextMain: {
    height: "60%",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
  scheduleDiv: {
    height: "88%",
    overflowY: "scroll",
    scrollbarColor: "pink lightblue",
  },
  accord: {
    height: "40%",
    overflowY: "scroll",
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  },
  section1: {
      width: "55vw",
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "column",
      marginTop: "6%",
      [theme.breakpoints.down("md")]: {
        marginTop: 0,
      },
  }
}));

export default function UniversityFair() {
  const classes = useStyles();
  const leftMenuRef = useRef(null);
  const rightSectionRef = useRef(null);
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const handleScroll = () => {
    // const position = rightSectionRef.current?.getBoundingClientRect().x;
    // setScrollPosition(position);
  };

  const Seminar = [
    {
      title:
        "SOP Writing",
      university:
        "Russell Square International College",
      time: "10:30 - 11:00 am",
    },
    {
      title: "Liberal Education: General Overview",
      university: "FLAME University",
      time: "10:30 - 11:00 am",
    },
    {
      title: "New Age Careers",
      university: "Masters’ Union",
      time: "11:15 - 11:45 am",
    },
    {
      title: "New Professionals for the 21st Century - Careers in Sciences, Social Sciences and Humanities",
      university: "Azim Premji University",
      time: "11:15 - 11:45 am",
    },
    {
      title: "Careers in Design",
      university: "Ecole Intuit Lab",
      time: "12:00 - 12:30 pm",
    },
    {
      title: "Summer School Program",
      university: "ATLAS Skilltech University",
      time: "12:00 - 12:30 pm",
    },
    {
      title: "Careers of the Future via Liberal Education",
      university: "Krea University",
      time: "12:45 - 1:15 pm",
    },
    {
      title: "Fields in Biology apart from MBBS and BDS",
      university: "MIT World Peace University",
      time: "12:45 - 1:15 pm",
    },
    {
      title: "Career Pathways in Psychology",
      university: "Russell Square International College",
      time: "1:30 - 2:00 pm",
    },
    {
      title: "Careers in the times of AI",
      university: "Universal AI University",
      time: "1:30 - 2:00 pm",
    },
    {
      title: "Global Immersion Programs at O. P. Jindal",
      university: "O. P. Jindal University",
      time: "2:15 – 2:45 pm",
    },
    {
      title: "Careers in Design",
      university: "Bhanwar Rathore Design Studio",
      time: "2:15 – 2:45 pm",
    },
  ];

  return (
    <>
      <Contact />
      <a
        href="https://forms.gle/RMt9CsChJ7s2N2R16"
        target="_blank"
        rel="noreferrer"
        className={classes.ctaLink}
      >
        <Button
          color="primary"
          variant="outlined"
          className={classes.RegisterBtn}
          onClick={() => {}}
        >
          Register Now
        </Button>
      </a>

      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu breadCrumbTarget="University fair" />
            <Grid
              ref={rightSectionRef}
              item
              xs={12}
              md={9}
              className={classes.rightSection}
            >
              <Grid container className={classes.containerMain}>
                <>
                  <Grid container className={classes.containerMain1}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        width: "100vw",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Box className={classes.imgMain}>
                        <a
                          href="https://forms.gle/RMt9CsChJ7s2N2R16"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={
                              matchesSM
                                ? "/assets/universityMobile.jpg"
                                : "/assets/university.jpg"
                            }
                            width="850px"
                            alt=""
                            class="responsiveImg"
                          />
                        </a>
                        <LogoSlider />
                      </Box>

                      {/* <div className={classes.slider}>
                        <div className={classes.slideTrack}>
                          <div class="slide">
                            <Box className={classes.logoMain}>
                              {Logos.map((e) => (
                                <img
                                  src={e.logo}
                                  alt=""
                                  width={e.width}
                                  class="responsiveImg1"
                                />
                              ))}
                            </Box>
                          </div>
                        </div>
                      </div> */}
                    </Grid>

                    {/* Why? What? */}
                    <Grid
                      item
                      xs={12}
                      className={classes.section1}
                    >
                      <Box>
                        <Typography
                          class="h2b"
                          variant="h1"
                          style={{ lineHeight: "normal", fontSize: "44px" }}
                        >
                          Why join the fair / What will you get?
                        </Typography>

                        <Typography className={classes.h3bText}>
                          Information gathering & analysis
                        </Typography>
                        <Typography component="p" className={classes.subDesc}>
                        Gain valuable information on admissions requirements, campus life, financial aid and more
                        to help you make a well-informed decision.
                        </Typography>

                        <Typography className={classes.h3bText}>
                        Exposure to diverse options
                        </Typography>
                        <Typography component="p" className={classes.subDesc}>
                        Meet 45 different Indian and international universities in one place and discover the
                        different course options available for you.
                        </Typography>

                        <Typography className={classes.h3bText}>
                        Personalised advice and interaction
                        </Typography>
                        <Typography component="p" className={classes.subDesc}>
                        Interact directly with university representatives to get advice and clarity on specific queries.
                        </Typography>

                        <Typography className={classes.h3bText}>
                        Networking opportunities
                        </Typography>
                        <Typography component="p" className={classes.subDesc}>
                        Meet admissions teams, faculty, alumni from all across the country and build useful
                        connections.
                        </Typography>
                      </Box>
                    </Grid>
                    {/* ppt schedule */}
                    <Grid item xs={12} className={classes.pptSection}>
                      <Box className={classes.subTextMain}>
                        <Typography
                          class="h2b"
                          variant="h1"
                          style={{ lineHeight: "normal", fontSize: "44px" }}
                        >
                          Presentation Schedule
                        </Typography>
                        <Grid container className={classes.scheduleDiv}>
                          {Seminar.map((e) => (
                            <Grid
                              item
                              xs={12}
                              md={6}
                              style={{
                                overflowWrap: "anywhere",
                                padding: "15px 15px 15px 0",
                              }}
                            >
                              <Typography style={{ color: "#898EAC" }}>
                                <b>{e.title}</b>
                              </Typography>
                              <Typography className={classes.title1}>
                                &#8226; {e.university}
                              </Typography>

                              <Typography className={classes.title1}>
                                &#8226; {e.time}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Grid>
                    {/* FAQ section */}
                    <Grid item xs={12} className={classes.openings}>
                      <Typography
                        class="h2b"
                        variant="h1"
                        style={{ lineHeight: "normal", fontSize: "44px" }}
                      >
                        FAQs
                      </Typography>
                      <div className={classes.accord}>
                        <Accordion
                          style={{
                            background: "#263657",
                            color: "#fff",
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon
                                style={{
                                  color: "#fff",
                                }}
                              />
                            }
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography
                              sx={{
                                flexShrink: 0,
                                width: "-webkit-fill-available",
                              }}
                            >
                              1. Who should attend the fair?
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              background: "#fff",
                              color: "#000",
                              maxHeight: "130px",
                              overflowY: "scroll",
                            }}
                          >
                            <Button
                              // onClick={() => openFileInNewTab("")}
                              alt="#"
                              className={classes.linksNotLinked}
                            >
                              <Typography className={classes.textStyle}>
                                <b> The University Fair is open to</b> <br />
                                &#8226; <b> Students from Grades 9-12</b>
                              </Typography>
                            </Button>
                            <br />
                            <Button
                              onClick={() => openFileInNewTab("")}
                              alt="#"
                              className={classes.linksNotLinked}
                            >
                              <Typography>
                                &#8226;{" "}
                                <b>
                                  Parents of students who are exploring higher
                                  education options
                                </b>
                              </Typography>
                            </Button>
                            <br />
                            <Button
                              // onClick={() => openFileInNewTab("")}
                              alt="#"
                              className={classes.linksNotLinked}
                            >
                              <Typography>
                                &#8226;{" "}
                                <b>
                                High school counsellors and faculty who would like up-to-date information to guide their
                                students
                                </b>
                              </Typography>
                            </Button>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          style={{
                            background: "#263657",
                            color: "#fff",
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon style={{ color: "#fff" }} />
                            }
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                          >
                            <Typography
                              sx={{
                                flexShrink: 0,
                                width: "-webkit-fill-available",
                              }}
                            >
                              2. How much does it cost to attend the fair?
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              background: "#fff",
                              color: "#000",
                              maxHeight: "130px",
                              overflowY: "scroll",
                            }}
                          >
                            <Button
                              // onClick={() => openFileInNewTab("")}
                              alt="#"
                              className={classes.linksNotLinked}
                            >
                              <Typography>
                                <b>
                                  There is no registration fee to attend the
                                  fair.
                                </b>
                              </Typography>
                            </Button>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          style={{
                            background: "#263657",
                            color: "#fff",
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon style={{ color: "#fff" }} />
                            }
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                          >
                            <Typography
                              sx={{
                                flexShrink: 0,
                                width: "-webkit-fill-available",
                              }}
                            >
                              3. What will I learn at the fair?
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              background: "#fff",
                              color: "#000",
                              maxHeight: "130px",
                              overflowY: "scroll",
                            }}
                          >
                            <Button alt="#" className={classes.linksNotLinked}>
                              <Typography>
                                <b>
                                You can learn about the programs, admissions requirements and campus life of the participating
universities. You can also directly interact with representatives of these universities and get
personalised advice and guidance.
                                </b>
                              </Typography>
                            </Button>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          style={{
                            background: "#263657",
                            color: "#fff",
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon style={{ color: "#fff" }} />
                            }
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                          >
                            <Typography
                              sx={{
                                flexShrink: 0,
                                width: "-webkit-fill-available",
                              }}
                            >
                              4. Will I be able to get a scholarship?
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              background: "#fff",
                              color: "#000",
                              maxHeight: "130px",
                              overflowY: "scroll",
                            }}
                          >
                            <Button alt="#" className={classes.linksNotLinked}>
                              <Typography>
                                <b>
                                Since this will depend on the unique offerings by the university, you may inquire and learn about the
                                financial aid options that are available to you.
                                </b>
                              </Typography>
                            </Button>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          style={{
                            background: "#263657",
                            color: "#fff",
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon style={{ color: "#fff" }} />
                            }
                            aria-controls="panel5bh-content"
                            id="panel5bh-header"
                          >
                            <Typography
                              sx={{
                                flexShrink: 0,
                                width: "-webkit-fill-available",
                              }}
                            >
                              5. Do I need to be there for the entire duration
                              of the fair?
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              background: "#fff",
                              color: "#000",
                              maxHeight: "130px",
                              overflowY: "scroll",
                            }}
                          >
                            <Button alt="#" className={classes.linksNotLinked}>
                              <Typography>
                                <b>
                                No, you don’t. The event is from 10 am to 5 pm, with presentations/seminars at different times of
the day. Participants are free to choose the sessions they would like to attend and plan their visit
timing and duration accordingly.{" "}
                                </b>
                              </Typography>
                            </Button>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          style={{
                            background: "#263657",
                            color: "#fff",
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon style={{ color: "#fff" }} />
                            }
                            aria-controls="panel5bh-content"
                            id="panel5bh-header"
                          >
                            <Typography
                              sx={{
                                flexShrink: 0,
                                width: "-webkit-fill-available",
                              }}
                            >
                              6. Can I bring someone else with me to the fair?
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              background: "#fff",
                              color: "#000",
                              maxHeight: "130px",
                              overflowY: "scroll",
                            }}
                          >
                            <Button alt="#" className={classes.linksNotLinked}>
                              <Typography>
                                <b>
                                Parents can accompany students to the fair. You can also bring along friends, but they will need to
                                register beforehand.
                                </b>
                              </Typography>
                            </Button>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
