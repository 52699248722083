import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Grid, Container, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import usePathParameter from "../../src/utils/usePathParameter";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    textAlign: "center",
  },
  textBox: {
    backgroundColor: "#E3D5CA",
    position: "relative",
    left: "2rem",
    padding: "2rem 1rem",
    height: "280px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      position: "initial",
    },
  },
  bg: {
    backgroundImage: `url(/assets/lwsMarkersBg.svg)`,
    backgroundPosition: "left center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: "100vh",
    marginBottom: "auto",
    [theme.breakpoints.down("md")]: {
      background: "none",
    },
  },
  boxMain: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "initial",
    },
  },
  container: {
    marginLeft: "14rem",
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
  },
  mainTitle: {
    fontFamily: "Lato",
    fontWeight: "bold",
    color: "#898EAC",
    fontSize: "55px",
    marginBottom: "10px",
    marginTop: "0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "36px",
    },
  },
}));

// const styles = {
//   LwsMarkers: {
//       backgroundImage: `url(${LwsMarkersBg})`,
//       backgroundPosition: 'left center',
//       backgroundRepeat: 'no-repeat',
//       backgroundSize: 'contain',
//       height: '100vh',
//       marginBottom: 'auto',
//   },
// };

export default function LwsMarkers() {
  const classes = useStyles();
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();
  const { isIndividual, pathUrl } = usePathParameter();

  const navigateToPage = () => {
    navigate(isIndividual ? `${pathUrl}/lwsmarkers` : `/lwsmarkers`);
  };

  const slides = [
    {
      id: "slide-1",
      backgroundImage: "./assets/markersSlide1.jpg",
      text: "We believe that collaboration is the best teacher. And our best teachers agree.",
      CTAText: "Symbiotic Learning",
      CTALink: "/lwsmarkers",
    },
    {
      id: "slide-2",
      backgroundImage: "./assets/markersSlide2.jpg",
      text: "We encourage deviation from convention. Our pedagogy goes beyond the tried and tested.",
      CTAText: "Design Thinking",
      CTALink: "/lwsmarkers",
    },
    {
      id: "slide-4",
      backgroundImage: "./assets/markersSlide1.jpg",
      text: "We care about the world we live in and the one we build for the leaders of tomorrow.",
      CTAText: "Environmental Consciousness",
      CTALink: "/lwsmarkers",
    },
    {
      id: "slide-3",
      backgroundImage: "",
      text: "We train young leaders for life beyond the classroom. We look inward, we reach out, we appreciate.",
      CTAText: "Emotional Wellness",
      CTALink: "/lwsmarkers",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => setCurrentSlide((prv) => prv + 1), 3500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <main className={classes.bg}>
        <Box className={classes.boxMain}>
          <Container maxWidth="md" className={classes.container}>
            <Typography className={classes.mainTitle} variant="h1">
              Lodha World School - Where excellence is a way of life
            </Typography>
            <Grid container className={classes.root}>
              <Grid item xs={12} md={7}>
                <div class="slider-wrap">
                  <div
                    className="single-slide"
                    style={{ transitionDuration: "800ms" }}
                    id={slides[currentSlide % slides.length].id}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={5} className={classes.textBox}>
                <Box>
                  <Box>
                    <Typography
                      style={{
                        padding: "1rem",
                        marginTop: 0,
                        transitionDuration: "800ms",
                        fontFamily: "Bodoni",
                        lineHeight: "27px",
                        fontSize: "1.5em",
                      }}
                      class="h2"
                      align="center"
                      color="primary"
                      gutterBottom
                      variant="h2"
                    >
                      {slides[currentSlide % slides.length].text}
                    </Typography>
                    <Button
                      style={{ transitionDuration: "800ms" }}
                      variant="outlined"
                      color="primary"
                      onClick={navigateToPage}
                    >
                      {slides[currentSlide % slides.length].CTAText}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </main>
    </>
  );
}
