import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import usePathParameter from "../utils/usePathParameter";
import { sidebarMain } from "../data/MainSiteData";
import { sidebarCbgPalava } from "../data/CbgPalavaIndividualSiteData";
import { sidebarLsgPalava } from "../data/LsgPalavaIndividualSiteData";
import { sidebarTaloja } from "../data/TalojaIndividualSiteData";
import { sidebarThane } from "../data/ThaneIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  leftSection: {
    backgroundColor: "#E3D5CA",
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      backgroundColor: "transparent",
      height: "auto",
    },
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
  },
  media: {
    display: "flex",
    margin: "0.5rem 1rem",
    justifyContent: "space-between",
  },
  logoMedia: {
    height: "calc(100vh - 210px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

export default function LogoMedia() {
  const classes = useStyles();
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane, pathUrl } =
    usePathParameter();
  const [sidebar, setSidebar] = React.useState([]);
  React.useEffect(() => {
    if (isThane) {
      setSidebar(sidebarThane);
    } else if (isCbgPalava) {
      setSidebar(sidebarCbgPalava);
    } else if (isLsgPalava) {
      setSidebar(sidebarLsgPalava);
    } else if (isTaloja) {
      setSidebar(sidebarTaloja);
    } else if (!isIndividual) {
      setSidebar(sidebarMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);
  return (
    <>
      <Box className={classes.logoMedia}>
        <Box className={classes.subMenu}>
          <Link href={isIndividual ? `${pathUrl}` : "/"}>
            <img
              src="/assets/logo.svg"
              alt=""
              width="180px"
              class="responsiveImg"
              className={classes.logo}
            />
          </Link>
          <Typography
            style={{
              margin: "1rem 1rem 0 1rem",
              textAlign: "center",
              fontSize: "1.2rem",
            }}
          >
            <b>Connect with LWS</b>
          </Typography>
          <Box className={classes.media}>
            <a
              href={sidebar.fb}
              alt="facebook"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/fb.svg" alt="" />
            </a>
            <a
              href={sidebar.insta}
              alt="instagram"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/insta.svg" alt="" />
            </a>
            <a
              href={sidebar.linkedin}
              alt="linkedin"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/linkedin.svg" alt="" />
            </a>
          </Box>
        </Box>
      </Box>
    </>
  );
}
