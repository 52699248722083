import "./App.css";
import "./fonts/BodoniBold.otf";
import "./fonts/BodoniBEMediumItalic.otf";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import theme from "./theme/Theme";
import Home from "./Home";
import OurStory from "./pages/OurStory";
import Vision from "./pages/Vision";
import Markers from "./pages/Markers";
import Values from "./pages/Values";
import People from "./pages/People";
import Awards from "./pages/Awards";
import Newsletter from "./pages/Newsletter";
import Testimonials from "./pages/Testimonials";
import InspireUsCurriculum from "./pages/InspireUs";
import Results from "./pages/Results";
import Cbsetennistournament from "./pages/Cbsetennistournament";
import UniversityFair from "./pages/UniversityFair";
import Student from "./pages/Student";
import Career from "./pages/Career";
import Alumni from "./pages/Alumni";
import SeniorYears from "./pages/SeniorYears";
import Infrastructure from "./pages/Infrastructure";
import SkillDevelopment from "./pages/SkillDevelopment";
import LeadershipDevelopment from "./pages/LeadershipDevelopment";
import React from "react";
import Sitemap from "./pages/Sitemap";

function App() {
  //   useEffect(() => {
  //     // eslint-disable-next-line no-undef
  //     fbq("track", "PageView");
  //   }, []);

  const allWebsites = ["/", "taloja/", "lsgpalava/", "cbgpalava/", "thane/"];
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Routes>
          {allWebsites.map((siteBaseUrl, index) => (
            <React.Fragment key={index}>
              <Route exact path={siteBaseUrl} element={<Home />} />
              <Route path={`${siteBaseUrl}sitemap`} element={<Sitemap />} />
              <Route path={`${siteBaseUrl}ourstory`} element={<OurStory />} />
              <Route path={`${siteBaseUrl}vision`} element={<Vision />} />
              <Route path={`${siteBaseUrl}lwsmarkers`} element={<Markers />} />
              <Route path={`${siteBaseUrl}values`} element={<Values />} />
              <Route path={`${siteBaseUrl}people`} element={<People />} />
              <Route path={`${siteBaseUrl}awards`} element={<Awards />} />
              <Route path={`${siteBaseUrl}newsletter`} element={<Newsletter />} />
              <Route path={`${siteBaseUrl}cbsetennistournament`} element={<Cbsetennistournament />} />
              <Route
                path={`${siteBaseUrl}testimonials`}
                element={<Testimonials />}
              />
              <Route
                path={`${siteBaseUrl}inspireuscurriculum`}
                element={<InspireUsCurriculum />}
              />
              <Route
                path={`${siteBaseUrl}senioryears`}
                element={<SeniorYears />}
              />
              <Route
                path={`${siteBaseUrl}infrastructure`}
                element={<Infrastructure />}
              />
              <Route
                path={`${siteBaseUrl}university-fair`}
                element={<UniversityFair />}
              /> 
              <Route path={`${siteBaseUrl}results`} element={<Results />} />
              <Route path={`${siteBaseUrl}student`} element={<Student />} />
              <Route path={`${siteBaseUrl}career`} element={<Career />} />
              <Route path={`${siteBaseUrl}alumni`} element={<Alumni />} />
              <Route
                path={`${siteBaseUrl}skilldevelopment`}
                element={<SkillDevelopment />}
              />
              <Route
                path={`${siteBaseUrl}leadershipdevelopment`}
                element={<LeadershipDevelopment />}
              />
              <Route path="*" element={<Home />} />
            </React.Fragment>
          ))}
        </Routes>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
