import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Grid, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Contact from "../components/Contact";
import { pageSettingsMain } from "../data/MainSiteData";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import BackToStart from "../components/BackToStart";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "150vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  root: {
    alignItems: "center",
    // "@media (min-width:600px)": {
    //   maxWidth: '750px'
    // }
  },
  textBox: {
    position: "relative",
    left: "-12rem",
    padding: "2rem 1rem",
    boxShadow: "0px 4px 31px rgba(37, 54, 87, 0.5)",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("md")]: {
      position: "initial",
    },
  },
  nextBtn: {
    position: "relative",
    left: "30rem",
    top: "-3rem",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    borderRadius: "50%",
    width: "90px",
    height: "90px",
    fontSize: "13px",
    border: "1px solid #263657",
    backgroundColor: "#263657",
    color: "#fff",
    "&:hover": {
      background: "#263657",
      border: "1px solid #263657",
      color: "#fff",
    },
  },
  containerMain: {
    height: "92vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: "100vh",
      flexDirection: "row",
      marginLeft: 0,
      alignItems: "flex-start",
    },
  },
  subText: {
    width: "77vh",
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      width: "100vh",
      fontSize: "initial",
    },
  },
  subTextMain: {
    display: "flex",
    alignItems: "center",
    padding: "4rem 0",
    [theme.breakpoints.down("md")]: {
      padding: "1rem 0 0 0",
    },
  },
  h1Main: {
    marginLeft: "3rem",
    width: "70vw",
    display: "flex",
    alignItems: "flex-start",
    height: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      width: "100vw",
      height: "auto",
      padding: "1rem",
    },
  },
  mainSection: {
    width: "50vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginLeft: "-10rem",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      padding: "1rem",
    },
  },
}));

export default function Alumni() {
  const classes = useStyles();
  const leftMenuRef = useRef(null);
  const rightSectionRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollToBackTarget = () => {
    leftMenuRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const position = rightSectionRef.current?.getBoundingClientRect().x;
    setScrollPosition(position);
  };

  return (
    <>
      <Helmet>
        <title>{pageSettingsMain.alumni.title}</title>
        {pageSettingsMain.alumni.metaTags.map((tag) => {
          if (tag.name) {
            return (
              <meta name={tag.name} content={tag.content} key={tag.name} />
            );
          } else if (tag.property) {
            return (
              <meta
                property={tag.property}
                content={tag.content}
                key={tag.property}
              />
            );
          }
          return null;
        })}
        <link rel="canonical" href={pageSettingsMain.alumni.canonical} />
      </Helmet>
      <Contact />
      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu breadCrumbTarget="Alumni" />
            <Grid
              ref={rightSectionRef}
              item
              xs={12}
              md={9}
              className={classes.rightSection}
            >
              <Grid container className={classes.containerMain}>
                <Grid item xs={12} className={classes.mainSection}>
                  <Typography class="h2b" variant="h1">
                    Lodha World School - Alumni
                  </Typography>
                  <Box className={classes.subTextMain}>
                    <Typography component="p" className={classes.subText}>
                      Once a member of the LWS community, always a member! We
                      take great pride in watching our LWS learners grow and go
                      out into the world, transforming into the leaders they
                      were meant to be.
                      <br />
                      <br />
                      We’re constantly working on different ways to stay
                      connected with our alumni across the world. Stay connected
                      with the LWS community and your classmates through our
                      alumni network.
                    </Typography>
                  </Box>
                </Grid>

                {/* sports */}
                {/* <>
              <Box className={classes.h1Main}>
                <Typography class="h1" variant='h1' 
                  style={{marginBottom: '10px', fontFamily: "Lato", transitionDuration: '800ms', fontWeight: 'bold', color: '#898EAC', fontSize: '55px'}}>
                  {slides[currentSlide%slides.length].title}
                </Typography>
                  <Grid container className={classes.root}>
                    <Grid item xs={12}>
                      <div class="slider-wrap-skill">
                        <div className="single-slide" style={{ transitionDuration: '800ms'}} id={slides[currentSlide%slides.length].id} />
                      </div>
                    </Grid>
                  </Grid>   
                    {/* <Button onClick={changeSlide} className={classes.nextBtn} aria-label="next" variant='contained' align="center">
                      <ArrowForwardIcon />
                    </Button>
              </Box>
              </> */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {scrollPosition < 0 ? (
        <BackToStart goToTarget={scrollToBackTarget} />
      ) : null}
    </>
  );
}
