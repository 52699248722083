import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Grid, Box, Typography, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Contact from "../components/Contact";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import BackToStart from "../components/BackToStart";
import usePathParameter from "../utils/usePathParameter";
// import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { starAwardsMain, pageSettingsMain } from "../data/MainSiteData";
import { starAwardsCbgPalava } from "../data/CbgPalavaIndividualSiteData";
import { starAwardsLsgPalava } from "../data/LsgPalavaIndividualSiteData";
import { starAwardsTaloja } from "../data/TalojaIndividualSiteData";
import { starAwardsThane } from "../data/ThaneIndividualSiteData";
// import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "150vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  root: {
    alignItems: "center",
    // "@media (min-width:600px)": {
    //   maxWidth: '750px'
    // }
  },
  textBox: {
    position: "relative",
    left: "-12rem",
    padding: "2rem 1rem",
    boxShadow: "0px 4px 31px rgba(37, 54, 87, 0.5)",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("md")]: {
      position: "initial",
    },
  },
  nextBtn: {
    position: "relative",
    left: "30rem",
    top: "-3rem",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    borderRadius: "50%",
    width: "90px",
    height: "90px",
    fontSize: "13px",
    border: "1px solid #263657",
    backgroundColor: "#263657",
    color: "#fff",
    "&:hover": {
      background: "#263657",
      border: "1px solid #263657",
      color: "#fff",
    },
  },
  containerMain: {
    height: "80vh",
    marginLeft: "7rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: "100vh",
      flexDirection: "row",
      marginLeft: 0,
    },
  },
  mainImg: {
    // width: "311px",
    textAlign: "center",
    // margin: "0 3rem",
    [theme.breakpoints.down("md")]: {
      margin: "3rem 0 0 0",
    },
  },
  fourSchoolImg: {
    width: "250px",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  img: {
    cursor: "pointer",
  },
  subText: {
    width: "77vh",
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      width: "100vh",
      fontSize: "initial",
      padding: "1rem",
    },
  },
  subTextMain: {
    display: "flex",
    alignItems: "center",
    padding: "4rem 0",
    [theme.breakpoints.down("md")]: {
      padding: "1rem 0 0 0",
    },
  },
  h1Main: {
    marginLeft: "3rem",
    width: "80vw",
    display: "flex",
    alignItems: "flex-start",
    height: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      width: "100vw",
      height: "auto",
      padding: "1rem",
    },
  },
}));

export default function Awards() {
  const classes = useStyles();
  const leftMenuRef = useRef(null);
  const rightSectionRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const [awards, setAwards] = useState([]);
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (isThane) {
      setAwards(starAwardsThane);
    } else if (isCbgPalava) {
      setAwards(starAwardsCbgPalava);
    } else if (isLsgPalava) {
      setAwards(starAwardsLsgPalava);
    } else if (isTaloja) {
      setAwards(starAwardsTaloja);
    } else if (!isIndividual) {
      setAwards(starAwardsMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  const scrollToBackTarget = () => {
    leftMenuRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const Schools = [
  //   {
  //     img: "/assets/thane.jpg",
  //     name: "Thane",
  //     established: "Established in 2008",
  //     class: "Nursery - Class 10",
  //     board: "Board: ICSE",
  //     cta: "Thane",
  //     ctalink: "https://www.lodhaworldschool.com/thane",
  //   },
  // ];

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleScroll = () => {
    const position = rightSectionRef.current?.getBoundingClientRect().x;
    setScrollPosition(position);
  };

  return (
    <>
      <Helmet>
        <title>{pageSettingsMain.awards.title}</title>
        {pageSettingsMain.awards.metaTags.map((tag) => {
          if (tag.name) {
            return (
              <meta name={tag.name} content={tag.content} key={tag.name} />
            );
          } else if (tag.property) {
            return (
              <meta
                property={tag.property}
                content={tag.content}
                key={tag.property}
              />
            );
          }
          return null;
        })}
        <link rel="canonical" href={pageSettingsMain.awards.canonical} />
      </Helmet>
      <Contact />
      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu breadCrumbTarget="Awards" />
            <Grid
              ref={rightSectionRef}
              item
              xs={12}
              md={9}
              className={classes.rightSection}
            >
              <Typography
                class="h2b"
                variant="h1"
                style={{ lineHeight: "normal", marginLeft: "7rem" }}
              >
                Explore Lodha World School's Remarkable Awards & Achievements
              </Typography>
              <Grid container className={classes.containerMain}>
                <>
                  {awards.map((e) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        width: "40vw",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Link href={e?.link} target="_blank" rel="noreferrer">
                        <img
                          src={e?.imageSource}
                          // onClick={handleClickOpen}
                          width="160px"
                          alt=""
                          class="responsiveImg"
                          className={classes.img}
                        />
                      </Link>
                      <Typography
                        class="h2"
                        variant="h4"
                        style={{ margin: 0, lineHeight: "normal" }}
                      >
                        {e?.name}
                      </Typography>
                      <Typography
                        style={{ fontSize: "14px", marginTop: "0.6rem" }}
                        class="h1"
                      >
                        {e?.name1}
                      </Typography>
                    </Grid>
                  ))}
                </>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* <Dialog
        PaperProps={{
          style: {
            backgroundColor: "#E3D5CA",
          },
        }}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography
            class="h2b"
            align="center"
            color="primary"
            gutterBottom
            variant="h1"
          >
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.primary.main,
              border: "1px solid #263657",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ mb: 3 }} spacing={2}>
            {Schools.map((school) => (
              <Grid item xs={12}>
                <Grid container style={{ textAlign: "left" }}>
                  <Grid item xs={12} md={6}>
                    <img
                      src={school.img}
                      alt=""
                      className={classes.fourSchoolImg}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      class="h2"
                      color="primary"
                      variant="h1"
                      gutterBottom
                    >
                      {school.name}
                    </Typography>
                    <Box sx={{ color: (theme) => theme.palette.black.main }}>
                      <b>
                        {school.established} <br />
                        {school.class} <br />
                        {school.board} <br />
                      </b>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog> */}
      {scrollPosition < 0 ? (
        <BackToStart goToTarget={scrollToBackTarget} />
      ) : null}
    </>
  );
}
