export const pageSettingsThane = {
  landingPage: {
    title:
      "Lodha World School - ICSE School In Thane | Building Leaders for Tomorrow",
    metaTags: [
      {
        name: "description",
        content:
          "Lodha World School, Thane is dedicated to nurturing empathetic leaders of tomorrow through academic excellence & an international curriculum. We are affiliated to ICSE board.",
      },
      {
        property: "og:title",
        content:
          "Lodha World School - ICSE School In Thane | Building Leaders for Tomorrow",
      },
      { property: "og:site_name", content: "LodhaWorldSchoolThane" },
      { property: "og:url", content: "https://www.lodhaworldschool.com/thane" },
      {
        property: "og:description",
        content:
          "Lodha World School, Thane is dedicated to nurturing empathetic leaders of tomorrow through academic excellence & an international curriculum. We are affiliated to ICSE board.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/chairpersonThane.jpg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/thane",
  },
  ourStory: {
    title: "Explore Lodha World School's Remarkable Journey since 2008",
    metaTags: [
      {
        name: "description",
        content:
          "Join us in celebrating our incredible journey since 2008 where Lodha World School has flourished into a vibrant community with 4 campuses & affiliations with ICSE, CBSE, SSC, and ISC boards.",
      },
      {
        property: "og:title",
        content: "Explore Lodha World School's Remarkable Journey since 2008",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      { property: "og:url", content: "https://www.lodhaworldschool.com/" },
      {
        property: "og:description",
        content:
          "Join us in celebrating our incredible journey since 2008 where Lodha World School has flourished into a vibrant community with 4 campuses & affiliations with ICSE, CBSE, SSC, and ISC boards.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/chairperson.jpg",
      },
    ],
  },
  vision: {
    title: "Lodha World School - Vision & Mission",
    metaTags: [
      {
        name: "description",
        content:
          "Discover the vision and mission of Lodha World School (LWS), dedicated to nurturing individuals in body, mind, and spirit, and empowering them to become the Leaders of Tomorrow. Join Us!",
      },
      {
        property: "og:title",
        content: "Lodha World School - Vision & Mission",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/inspireuscurriculum",
      },
      {
        property: "og:description",
        content:
          "Discover the vision and mission of Lodha World School (LWS), dedicated to nurturing individuals in body, mind, and spirit, and empowering them to become the Leaders of Tomorrow. Join Us! ",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  values: {
    title:
      "Lodha World School Core Values: Supporting Leaders with Eternal Virtues",
    metaTags: [
      {
        name: "description",
        content:
          "Discover the core values at Lodha World School and how they are instilled through our four houses, each rooted in Sanskrit with timeless significance. ",
      },
      {
        property: "og:title",
        content:
          "Lodha World School Core Values: Supporting Leaders with Eternal Virtues",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/values",
      },
      {
        property: "og:description",
        content:
          "Discover the core values at Lodha World School and how they are instilled through our four houses, each rooted in Sanskrit with timeless significance. ",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  people: {
    title: "Meet the Leaders Behind Lodha World School's Vision",
    metaTags: [
      {
        name: "description",
        content:
          "Explore the dedicated individuals shaping the future of Lodha World School. From our Chairperson to esteemed members of the School Managing Committee & principals across our campuses.",
      },
      {
        property: "og:title",
        content: "Meet the Leaders Behind Lodha World School's Vision",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/people",
      },
      {
        property: "og:description",
        content:
          "Explore the dedicated individuals shaping the future of Lodha World School. From our Chairperson to esteemed members of the School Managing Committee & principals across our campuses.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  inspireUsCurriculum: {
    title: "Lodha World School Custom Curriculum - Inspire Us",
    metaTags: [
      {
        name: "description",
        content:
          "Discover 'Inspire Us' by Lodha World School, a globally researched custom curriculum, specially designed to deliver the academic rigour and excellence we aspire to achieve.",
      },
      {
        property: "og:title",
        content: "Lodha World School Custom Curriculum - Inspire Us",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/vision",
      },
      {
        property: "og:description",
        content:
          "Discover 'Inspire Us' by Lodha World School, a globally researched custom curriculum is specially designed to deliver the academic rigour and excellence we aspire to achieve.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  results: {
    title: "Lodha World Schools - Results",
    metaTags: [
      {
        name: "description",
        content:
          "Explore the results of the schools of Lodha World School. Witness the fruits of collaborative efforts among our leaders, staff, parents, and students, as we share the remarkable journeys.",
      },
      {
        property: "og:title",
        content: "Lodha World Schools - Results",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/results",
      },
      {
        property: "og:description",
        content:
          "Explore the results of the schools of Lodha World School. Witness the fruits of collaborative efforts among our leaders, staff, parents, and students, as we share the remarkable journeys.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  skillDevelopment: {
    title: "Lodha World School - Skill Development Beyond the Classroom",
    metaTags: [
      {
        name: "description",
        content:
          "At Lodha World School, education goes beyond classroom boundaries. Discover how Skill Development play a pivotal role in nurturing all-round leaders.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Skill Development Beyond the Classroom",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/skilldevelopent",
      },
      {
        property: "og:description",
        content:
          "At Lodha World School, education goes beyond classroom boundaries. Discover how Skill Development play a pivotal role in nurturing all-round leaders. ",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  leadershipDevelopment: {
    title:
      "Lodha World Schools - Leadership Development | Empowering Tomorrow's Leaders",
    metaTags: [
      {
        name: "description",
        content:
          "At LWS, education extends beyond traditional classrooms. We aim to mould & nurture all-round leaders, facilitating their development through our range of co-curricular activities & opportunities.",
      },
      {
        property: "og:title",
        content:
          "Lodha World Schools - Leadership Development | Empowering Tomorrow's Leaders",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/leadershipdevelopment",
      },
      {
        property: "og:description",
        content:
          "At LWS, education extends beyond traditional classrooms. We aim to mould & nurture all-round leaders, facilitating their development through our range of co-curricular activities & opportunities.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  awards: {
    title: "Lodha World School - Awards and Achievements",
    metaTags: [
      {
        name: "description",
        content:
          "Lodha World School garnered recognition and awards that set them apart in the field of education. From prestigious Grand Jury Awards to Reliance Awards, explore their outstanding achievements.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Awards and Achievements",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/awards",
      },
      {
        property: "og:description",
        content:
          "Lodha World School garnered recognition and awards that set them apart in the field of education. From prestigious Grand Jury Awards to Reliance Awards, explore their outstanding achievements.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  testimonials: {
    title: "Lodha World School - Testimonials From Parents & Students",
    metaTags: [
      {
        name: "description",
        content:
          "Discover what parents & students has to say about the school's dedication to fostering well-rounded personalities through a balanced focus on academics and extracurricular activities.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Testimonials From Parents & Students",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/testimonials",
      },
      {
        property: "og:description",
        content:
          "Discover what parents & students has to say about the school's dedication to fostering well-rounded personalities through a balanced focus on academics and extracurricular activities.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  staff: {
    title:
      "Lodha World School - Career Opportunities & Benefits | Join Our Team",
    metaTags: [
      {
        name: "description",
        content:
          "At LWS, our staff members are lifelong learners, equipped with professional credentials and additional training from renowned institutions. Check LWS career opportunities here.",
      },
      {
        property: "og:title",
        content:
          "Lodha World School - Career Opportunities & Benefits | Join Our Team",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/career",
      },
      {
        property: "og:description",
        content:
          "At LWS, our staff members are lifelong learners, equipped with professional credentials and additional training from renowned institutions. Check LWS career opportunities here.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  alumni: {
    title: "Lodha World School - Alumni: Forever Part Of Our Community",
    metaTags: [
      {
        name: "description",
        content:
          "At Lodha World School, our alumni are a source of immense pride. Witness the transformation of our former students into the leaders they were destined to become.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Alumni: Forever Part Of Our Community",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/alumni",
      },
      {
        property: "og:description",
        content:
          "At Lodha World School, our alumni are a source of immense pride. Witness the transformation of our former students into the leaders they were destined to become.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  lwsMarkers: {
    title: "Lodha World School - Markers",
    metaTags: [
      {
        name: "description",
        content:
          "At Lodha World School, the pupil is at the heart of everything we do. We go the extra mile to mould these young leaders in revolutionary ways.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Markers",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/lwsmarkers",
      },
      {
        property: "og:description",
        content:
          "At Lodha World School, the pupil is at the heart of everything we do. We go the extra mile to mould these young leaders in revolutionary ways.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
};
export const lightBgWebThane = {
  imageSource: "/assets/thanebg1.gif",
};

export const sidebarThane = {
  fb: "https://www.facebook.com/lodhaworldschoolmumbai/", // thane link TODO: phone link to be added
  linkedin: "https://www.linkedin.com/company/lodha-world-school/",
  insta: "https://instagram.com/lodhaworldschool?igshid=YmMyMTA2M2Y=",
  email: "mailto:contact_thane@lodhaworldschool.com",
  admission: "mailto:admissions@lodhaworldschool.com",
  emailname: "contact_thane@lodhaworldschool.com",
  admissionname: "admissions@lodhaworldschool.com",
  phone: "tel:93728-61994",
  phonename: "+91 93728 61994",
};
export const sidebarlinkThane = [
  {
    logo: "/assets/board2.png",
    link: "https://cisce-gov.in/",
    width: "80px",
  },
];

export const websiteSchemaThane = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Lodha World School, Thane",
  url: "https://www.lodhaworldschool.com/thane/",
  logo: "https://www.lodhaworldschool.com/assets/logo.svg",
};
export const personSchemaThane = {
  "@context": "http://schema.org",
  "@type": "WebPage",
  accountablePerson: [
    {
      "@type": "Person",
      name: "Ms. Indrani Banerjee",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people4.jpg",
      jobTitle: "Principal",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, Thane",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Padma Sinha",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people4.jpg",
      jobTitle: "Vice-Principal",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, Thane",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Mansi Shah Deshpande",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people24.png",
      jobTitle: "Coordinator - Foundation Years",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, Thane",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Ushashi Dutta",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people23.png",
      jobTitle: "Coordinator - Primary School",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, Thane",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Anupama Jamnekar",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people23.png",
      jobTitle: "Coordinator - Middle Years",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, Thane",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Kavita Singh",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people25.png",
      jobTitle: "Coordinator - Secondary School",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, Thane",
      },
    },
  ],
};

export const lightBgMobileThane = {
  imageSource: "/assets/thanebg1mobile.gif",
};

export const landingThane = {
  imageSource: "/assets/thane.jpg",
  title1: "Lodha World School, Thane -",
  title2: "Building Empathetic Leaders Of Tomorrow",
};

export const inspireUsThane = {
  title1: "Foundation &",
  title2: "Middle Years",
  alt: "Foundation & Middle Years - Lodha World School, Thane",
  imgTitle: "Foundation & Middle Years at Lodha World School, Thane",
};

export const academicsThane = {
  imageSource: "/assets/academicsImg.png",
  alt: "Academic Excellence - Lodha World School, Thane",
  imgTitle: "Academic Excellence at Lodha World School, Thane",
  title: "Class of 2024 Results",
  name: "The Class of 2024 Results are a testament to our relentless drive towards pursuing academic excellence.",
  cta: "Class of 2024 Results",
};

export const joinLwsThane = {
  title: "Pursue Excellence at LWS Thane",
  imageSource: "/assets/jointhane.jpg",
};

export const valuesThane = [
  {
    id: "houses-1",
    title: "Atulya:",
    color: "#9C2020",
    subtitle: "the incomparable.",
    description:
      "A leader is incomparable, unequalled in their quest for excellence, and exhibits unmitigable zeal.",
    quote:
      "Inspiration: Shri JRD Tata - ethical and trailblazing business leadership & corporate social initiatives",
  },
  {
    id: "houses-2",
    title: "Advaita:",
    color: "#276440",
    subtitle: "the one and only one.",
    description: "A leader has an unchanging, singular vision of the goal.",
    quote:
      "Inspiration: Shri APJ Abdul Kalam - unique peerless vision for our country and society",
  },
  {
    id: "houses-3",
    title: "Ananta:",
    color: "#034667",
    subtitle: "the infinite.",
    description: "A leader embodies limitless possibilities and energy.",
    quote:
      "Inspiration: Mother Teresa - utmost compassion, extended to the most downtrodden",
  },
  {
    id: "houses-4",
    title: "Akshaya:",
    color: "#CF9832",
    subtitle: "the undiminishing, inexhaustible spirit.",
    description:
      "A leader exemplifies an undiminishing, inexhaustible spirit in all they set out to do.",
    quote:
      "Inspiration: Mahatma Gandhi - inexhaustible strength, tolerance, patience, determination to achieve freedom",
  },
];

export const chairpersonMsgThane = {
  imageSource: "/assets/chairpersonThane.jpg",
  name: "Ms. Indrani Banerjee",
  alt: "Lodha World School, Thane - Principal's Message",
  imgTitle: "Principal's Message about Lodha World School, Thane",
  title:
    "Our school is committed to every student’s academic, physical and mental well-being with the help of experienced and dedicated faculty and staff.",
  nameMain: "-Ms. Indrani Banerjee",
  cta: "Principal’s Message",
  dialogText1:
    "I welcome you to the Lodha World School, Thane, a caring community school for the much-awaited first step towards realizing your bright future. Our school is committed to every student’s academic, physical and mental well-being with the help of experienced and dedicated faculties and cooperative staff members.",
  dialogText2:
    "The primary emphasis of the school is on the holistic education of students, and to develop resilience, independence, and socially adept young children. Seminars, workshops, and extra-curricular activities are a regular feature of the school, which goes side by side with academics.",
  dialogText3:
    "We actively provide student leadership opportunities that encourage students to, accept roles of responsibility with integrity and honesty, foster a sense of vision and purpose, work collectively with, and lead, others and take on responsibilities, and use decision-making skills.",
  dialogText4:
    "In this globally competitive world that rewards hard work, innovation, perseverance, and cooperation, I am confident that if you join hands with Lodha World School to become a part of the transforming scenario of the modern education system, success will be yours.",
};

export const middleYearsThane = [
  {
    imageSource: "/assets/inspireUsImg.png",
    name: "Foundation & Middle Years",
    title:
      "At LWS, we redefine conventional learning. Our globally researched curriculum is crafted to deliver academic excellence.",
    cta: "Inspire Us Curriculum",
  },
];

export const seniorYearsThane = {
  imageSource: "/assets/academicsImgThane.png",
  alt: "Senior Years - Lodha World School, Thane",
  imgTitle: "Senior Years at Lodha World School, Thane",
  name: "Senior Years",
  title:
    "We are affiliated to the Council for the Indian School Certificate Examinations (ICSE) and train our young leaders in keeping with its rigorous standards.",
  cta: "ICSE Curriculum",
};

export const seniorpara1Thane = {
  para1:
    "The Council for the Indian School Certificate Examinations (CISCE) is committed to serving the nation’s children through high-quality educational endeavours. Through its practical-knowledge approach, it aims to empower learners to contribute towards a humane, just and pluralistic society and creates exciting learning opportunities with a commitment to excellence.",
  para2:
    "The CISCE follows a minimum-monitoring policy, giving freedom to experiment with new ideas and practices and allowing schools to evolve their own niche. It upholds a sense of ‘Indian Ethos’ - strong roots in the national psyche and sensitivity to national aspirations. ",
  title1: "Best practices followed at LWS Thane",
  title2: "Subjects offered - ICSE Curriculum:",
  title3: "",
};

export const seniorpara2Thane = [
  {
    name: "Gamut of interdisciplinary subjects offered to learners",
  },
  {
    name: "In-depth focus on English Literature and Language",
  },
  {
    name: "Community Service as prime focus of the school",
  },
  {
    name: "Regular career guidance sessions with professionals from different walks of life",
  },
  {
    name: "Inter-school sports and cultural events spearheaded by students",
  },
];

export const seniorpara3Thane = [
  {
    name: "English",
  },
  {
    name: "Hindi",
  },
  {
    name: "History",
  },
  {
    name: "Geography",
  },
  {
    name: "Physics",
  },
  {
    name: "Chemistry",
  },
  {
    name: "Biology",
  },
  {
    name: "Mathematics",
  },
  {
    name: "Environmental Science",
  },
  {
    name: "Commercial Studies",
  },
  {
    name: "Computer Application / Commercial Application / Economic Application / Environmental Application / Art",
  },
];

export const seniorpara4Thane = [];

export const ourstoryThane = [
  {
    name: "The Lodha World School journey began in the heart of Thane. In 2008, the first Lodha World School was established - a warm, close-knit community of 24 eager learners. The vision of our school is to facilitate the holistic development of the young learners who walk into our doors. We endeavour to give back to the community by shaping and nurturing these young minds to become strong-minded and spirited leaders of tomorrow. Today, we have a widespread community, comprising 1000+ members, all united in their commitment to the school’s vision.",
  },
];

export const visionThane = [
  {
    title:
      "To nurture individuals sound of body, mind and spirit, and equip them to emerge as Leaders of Tomorrow.",
  },
];

export const missionThane = [
  {
    id: "vslideThane-1",
    text: "To provide quality school education, progressive in challenging pupils to take advantage of the emerging global opportunities.",
  },
  {
    id: "vslideThane-2",
    text: "To nurture pupils to make them caring, competent and contributing members of society.",
  },
  {
    id: "vslideThane-3",
    text: "To establish pupil-centred environments for academics as well as extracurricular opportunities that nourish self-efficacy and self-esteem.",
  },
  {
    id: "vslideThane-4",
    text: "To stimulate critical thinking and commitment to excellence that gradually lead to success in higher education and career.",
  },
  {
    id: "vslideThane-5",
    text: "To create a school community that reflects and respects ethnic, racial, religious and national diversity.",
  },
];

export const peopleThane = [
  {
    imageSource: "/assets/people4.jpg",
    name: "Ms. Indrani Banerjee",
    title: "Principal",
  },
  {
    imageSource: "/assets/people9.jpg",
    name: "Ms. Padma Sinha",
    title: "Vice-Principal",
  },
  {
    imageSource: "/assets/people24.png",
    name: "Ms. Mansi Shah Deshpande",
    title: "Coordinator - Foundation Years",
  },
  {
    imageSource: "/assets/people23.png",
    name: "Ms. Ushashi Dutta",
    title: "Coordinator - Primary School",
  },
  {
    imageSource: "/assets/people10.jpg",
    name: "Ms. Anupama Jamnekar",
    title: "Coordinator - Middle Years",
  },
  {
    imageSource: "/assets/people25.png",
    name: "Ms. Kavita Singh",
    title: "Coordinator - Secondary School",
  },
];

export const testimonialsThane = [
  {
    name1: "Dipti Swapnil Deshpande",
    name2: "Parent of Harsh Deshpande",
    title:
      "We are thankful to Lodha World School for nurturing the kids to develop strong foundation with focus to build 'Leaders of Tomorrow'. Their focus to expose students to both academic and extracurricular activities like Dramatics, Interschool Competitions etc. makes them all-rounded personalities.",
  },
  {
    name1: "Dinesh Kumar and Dinesh Kumar and Deepti Mehendiratta",
    name2: "Parents of Dhruv and Diya Mehendiratta",
    title:
      "The school has always been influential in shaping our children’s path for education. It has recognized their true potential and motivated them to strive and excel in academics as well as in extracurricular activities. The School encouraged them to participate in numerous inter-house and inter-school competitions, workshops and seminars. We believe the staff of Lodha World School nurtured and guided our son Dhruv who is now pursuing his Masters in Management from Singapore Management University. Our daughter Diya has made us and the school proud by ranking 1st in the school in the Board Exams. Lodha World School has helped them in achieving their dreams. The teachers were approachable, friendly and they struck a great chord with the students. Constant guidance and encouragement provided by teachers at every step played a major role in developing their personality and infused them with courage and confidence to face the world. We wish Lodha World School all the very best for all its future endeavors. Thank you",
  },
  {
    name1: "Sudipto Dutta",
    name2: "Parent of Rishabh Dutta",
    title:
      "It has been wonderful to see my son evolve and grow, and the role which the school has played in his holistic development over the 10 years he has spent here. The pedagogy has been excellent which has helped him grasp his concepts very well. All through these years, the emphasis of Lodha World School has been on activity and application-based learning, which I feel would hold in great stead for all the students. My sincere thanks to the Principal and all the teachers of the school for shaping young minds like him and others, to be socially and environmentally conscious future citizens of the world.",
  },
  {
    name1: "Ketankumari Sisodia",
    name2: "Parent of Bhagyashri and Karnaveer Sisodia",
    title:
      "Lodha world school has always been as welcoming as a family. I am certain of the fact that both my children have had the best time in school with the assistance of so many humble and supportive teachers. The unique pedagogy and conducive learning environment of LWST has inevitably led to their all-round development. I am extremely pleased to be associated with the LWST family who truly upholds the motto of quality education and moulding ‘Leaders of Tomorrow’.",
  },
  {
    name1: "Bhagyashri Sisodia",
    name2: "Alum (Class of 2020)",
    title:
      "Lodha World School has played an integral part in profoundly shaping my student life. My 11-year-old journey in LWST was wholesome and incredible, to say the least! The valuable guidance of such wonderful teachers, thought-provoking class projects, educational trips and even my tenure as the House Captain favourably transformed me as a person and made me more adaptable and empathetic. Soon after the 10th, I opted for the commerce stream and completed my class 12th with 99.5%, securing the 2nd rank in India in the ISC Board Examinations 2021-22. I aspire to thrive in the business analytics, finance and accounting front and consider professional certifications like Chartered Financial Analyst, Certified Management Accountant or Master of Business Administration in the future. I am deeply grateful to the LWST family for their vital support and for such cherishable memories to look back on!",
  },
  {
    name1: "Chitrakshi Bhadkamkar",
    name2: "Alum (Class of 2019)",
    title:
      "It is accurate to say that my academic experience has been a mixed bag because of Lodha World School as the knowledge and variety of extracurricular and curricular activities available to me since the beginning of my school career have encouraged me to push myself and discover my potential. I am currently seeking a career in CA, which provides great flexibility, excellent security and stability, as well as the opportunity to pursue a global career. Since I appreciate the demanding nature, diversity, and quick pace of the finance profession, I'm looking forward to a future in either finance or consulting.",
  },
];

export const resultsThane = [
  {
    id: "piechart-3",
    title: "ICSE Class of 2023 - Thane",
  },
];

export const homeAwardsThane = {
  imageSource: "/assets/starawards.png",
  id: "awardsThane",
};
export const starAwardsThane = [
  {
    imageSource: "/assets/awards15.png",
    name: "#15 in Mumbai, Times School Survey 2023",
  },
  {
    imageSource: "/assets/awards10.png",
    name: "#1 in Co-Curricular Education, Maharashtra School Merit Awards 2023",
  },
  {
    imageSource: "/assets/awards03.png",
    name: "#3 in Thane, EducationWorld India Rankings 2023-24",
  },
  {
    imageSource: "/assets/awards11.png",
    name: "#18 in Maharashtra, EducationWorld India Rankings 2023-24",
  },
  {
    imageSource: "/assets/awards.png",
    name: "Excellence in Innovative Teaching Methods, Eldrok India K-12 Summit 2023",
  },
  {
    imageSource: "/assets/awards.png",
    name: "Outstanding Learning & Curriculum, Free Press Journal Survey 2023",
  },
];
export const skillachievementsThane = [
  {
    imageSource: "/assets/achieve1.png",
    name: "Football Tournament by DSO",
    title: "U/17 Boys",
    title1: "",
  },
  {
    imageSource: "/assets/achieve.png",
    name: "Rhythmic Gymnastics",
    title: "Dharmi Satra - Class X",
    title1: "[Regional Championships]",
  },
  {
    imageSource: "/assets/achieve1.png",
    name: "Individual Table Tennis Tournament",
    title: "Sara Sheth - Class VIII",
    title1: "[Girls’ Category, District-level]",
  },
  {
    imageSource: "/assets/achieve2.png",
    name: "Group Table Tennis Tournament",
    title: "Abhir Sitani, Sujay Das, Rian Maharana - Class V",
    title1: "[Boys’ Category, District-level]",
  },
  {
    imageSource: "/assets/achieve2.png",
    name: "AISM Drawing & Painting Competition",
    title: "Saee Prabhudesai - Class VIII",
    title1: "[Maharashtra & Goa]",
  },
  {
    imageSource: "/assets/achieve1.png",
    name: "Inter-Dojo Shotokan Karate",
    title: "Nabhaa Kulkarni - Class II",
    title1: "",
  },
];

export const leadershipachievementsThane = [
  {
    imageSource: "/assets/achieve7.png",
    name: "Sanjana Shetty, Mannat Jain",
    name1: "",
    title: "[Times of India]",
  },
  {
    imageSource: "/assets/achieve8.png",
    name: "Vedika Shetty - Deputy Secretary General, Organising Committe",
    name1:
      "Lawanya Khandelwal - Contribution to UNSC Committee, Delegate of Italy",
    title: "",
  },
  {
    imageSource: "/assets/achieve9.png",
    name: "7th Rank: Nabhaa Kulkarni - Class II,",
    name1: "13th Rank: Arni Datar - Class II",
    title: "[All-India level]",
  },
  {
    imageSource: "/assets/achieve0.png",
    name: "",
    name1: "1st Prize: Khushi Ghanacharya - Class VII",
    title: "[Inter-school]",
  },
  {
    imageSource: "/assets/achieve11.png",
    name: "Gold Medal",
    name1: "Abdul Khan - Class VI",
    title: "",
  },
  {
    imageSource: "/assets/achieve12.png",
    name: "Sia Risbud, Diya Mehendiratta,",
    name1: "Nisheeta Odedra, Cheryl Varghese",
    title: "",
  },
];

export const inspireThane = [
  {
    id: "inspireUsThane-1",
    title: "Learner-centric",
    description:
      "Following the Montessori Methodology, our curriculum presupposes that each learner has a unique individual promise and unique abilities, thus encouraging personalised learning.",
  },
  {
    id: "inspireUsThane-2",
    title: "Inclusive",
    description:
      "The instructional approach and content, learning objectives, and pace of learning are optimised according to each learner’s needs, ensuring no learner is left behind.",
  },
  {
    id: "inspireUsThane-3",
    title: "Holistic",
    description:
      "Learning activities are meaningful and relevant to both the academic and personal lives of learners, making learning holistic.",
  },
  {
    id: "inspireUsThane-4",
    title: "Skill-based",
    description:
      "The curriculum is designed to build artistic creativity, design thinking, and innovation. It is also deeply entrenched in our Four Markers, which helps train and empower our learners.",
  },
];

export const skillThane = [
  {
    id: "skillThane-1",
    title: "Sports",
  },
  {
    id: "skillThane-2",
    title: "Indoor games",
  },
  {
    id: "skillThane-3",
    title: "Music",
  },
  {
    id: "skillThane-4",
    title: "Dance",
  },
  {
    id: "skillThane-5",
    title: "Drama & Theatre",
  },
  {
    id: "skillThane-6",
    title: "Art",
  },
];
export const leadersThane = [
  {
    imageSource: "/assets/leadershipboard.png",
    section1: "Discipline Captains",
    section2: "Cleanliness Captains",
  },
];

export const captainThane = [
  {
    name: "Akshara Jain",
  },
  {
    name: "Gauresh Bhatia",
  },
  {
    name: "Tejas Gavade",
  },
  {
    name: "Chaitya Hegde",
  },
];

export const headsThane = [
  {
    name: "Saee Prabhudesai",
  },
  {
    name: "Swara Jalakanda",
  },
  {
    name: "Viraj Rawool",
  },
  {
    name: "Palakpreet Kaur",
  },
  {
    name: "Smit Kanse",
  },
  {
    name: "Anushka Bhosale",
  },
];

export const prefectThane = [
  {
    position: "Prefect-Girl: ",
    name: "Deshna Jain",
  },
  {
    position: "Prefect-Boy: ",
    name: "Veer Thakkar",
  },
  {
    position: "Sports Captain: ",
    name: "Reet Parmanick",
  },
  {
    position: "Teacher in-charge: ",
    name: "Ms. Kavita Singh",
  },
];

export const captainsThane = [
  {
    position: "Prefect-Girl: Deshna Jain",
  },
  {
    position: "Prefect-Boy: Veer Thakkar",
  },
  {
    position: "Sports Captain: Reet Parmanick",
  },
  {
    position: "Teacher in-charge: Ms. Kavita Singh",
  },
];

export const leadershipThane = [
  {
    id: "leadershipThane-1",
    title: "Community Outreach",
  },
  {
    id: "leadershipThane-2",
    title: "Student Council",
  },
  {
    id: "leadershipThane-3",
    title: "Competitions",
  },
];

export const schoolThane = [
  {
    img: "/assets/thane.jpg",
    name: "Thane",
    email: "careers_thane@lodhaworldschool.com",
    phone: "+91-93728-61994",
    cta: "Thane",
    ctalink: "https://www.lodhaworldschool.com/thane",
  },
];

export const eventsThane = [];

export const applytoThane = [
  {
    link: "#",
    name: "English Teacher - Senior Secondary | M.A. (English), B.A. (English), B.Ed",
  },
  {
    link: "#",
    name: "Accountant | B.Com | 3+ years’ experience in a school",
  },
  ];

export const storyThane = [
  {
    text: "The Lodha World School journey began in the heart of Thane.",
  },
  {
    text: "In 2008, the first Lodha World School was established - a warm, close-knit community of 24 eager learners.",
  },
  {
    text: "The vision of our school is to facilitate the holistic development of the young learners who walk into our doors. We endeavour to give back to the community by shaping and nurturing these young minds to become strong-minded and spirited leaders of tomorrow.",
  },
  {
    text: "Lodha World School, Thane is a widespread community today, comprising over 1000 members, all united in their commitment to the school’s vision.",
  },
  {
    text: "We are affiliated to the CISCE (Council for the Indian School Certificate Examinations) and offer the ICSE examination for our Class 10 learners.",
  },
];

export const markersThane = [
  {
    id: "symbioticThane-1",
  },
  {
    id: "symbioticThane-2",
  },
  {
    id: "symbioticThane-3",
  },
  {
    id: "symbioticThane-4",
  },
];

export const designThane = [
  {
    id: "designThane-1",
  },
  {
    id: "designThane-2",
  },
  {
    id: "designThane-3",
  },
];

export const environmentThane = [
  {
    id: "environmentThane-1",
  },
  {
    id: "environmentThane-2",
  },
  {
    id: "environmentThane-3",
  },
];

export const wellnessThane = [
  {
    id: "wellnessThane-1",
  },
  {
    id: "wellnessThane-2",
  },
  {
    id: "wellnessThane-3",
  },
];

export const infraThane = [
  {
    id: "infra-1",
    title: "Classroom",
  },
  {
    id: "infra-2",
    title: "Biology Lab",
  },
  {
    id: "infra-3",
    title: "Chemistry Lab",
  },
  {
    id: "infra-4",
    title: "Physics Lab",
  },
  {
    id: "infra-5",
    title: "Computer Lab",
  },
  {
    id: "infra-6",
    title: "Library",
  },
];

export const infraDataThane = {
  para1:
    "The environment at the school is designed to enable students to excel and flourish - not just in academics, but in activities and events beyond the classroom, too. Lodha World School, Thane is well-equipped with state-of-the-art infrastructure and facilities across all its learning spaces.",
};
