import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const usePathParameter = () => {
  const { pathname, search } = useLocation();
  search.length && localStorage.setItem("queryString", search);
  const [pathState, setPathState] = useState({});
  const individualSchoolWebsites = [
    "taloja",
    "lsgpalava",
    "cbgpalava",
    "thane",
  ];
  const isIndividualWebsite = individualSchoolWebsites.some((substring) =>
    pathname.includes(substring)
  );
  const isTalojaWebsite = pathname.includes("taloja");
  const isLsgPalavaWebsite = pathname.includes("lsgpalava");
  const isCbgPalavaWebsite = pathname.includes("cbgpalava");
  const isThaneWebsite = pathname.includes("thane");
  const pathUrl =
    pathname.split("/").length === 3
      ? pathname.substring(pathname.indexOf("/"), pathname.lastIndexOf("/"))
      : pathname.substring(pathname.indexOf("/"), pathname.length);

  useEffect(() => {
    setPathState({
      baseUrl: window.location.host,
      pathUrl: pathUrl,
      isIndividual: isIndividualWebsite,
      isTaloja: isTalojaWebsite,
      isLsgPalava: isLsgPalavaWebsite,
      isCbgPalava: isCbgPalavaWebsite,
      isThane: isThaneWebsite,
    });
  }, [
    pathUrl,
    isCbgPalavaWebsite,
    isIndividualWebsite,
    isLsgPalavaWebsite,
    isTalojaWebsite,
    isThaneWebsite,
    search,
  ]);

  return pathState;
};

export default usePathParameter;
